import React, { useEffect, useRef, useState } from "react";
import classes from "./DoucumentLogs.module.css";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import corssIcon from "../../../../Assets/Logo/crossred.svg";
import StudentServices from "../../../../Services/StudentServices";
import Loader from "../../Loader/Loader";
import SearchModal from "../../../Pages/Home/Search/SearchModal/SearchModal";
import documentView from "../../../../Assets/Logo/documetnView.svg";
const DocumentLogs = ({ onShow, onClose, documentData, applicationId}) => {
  const blobUrl= process.env.REACT_APP_URL_PATH
  const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
  const [isLoading, setIsLoading] = useState(false);
  const [logList, setLogList] = useState([]);

  const initial = useRef(null);

  const getLogList = () => {
    let data={
        DocumentTypeId: documentData?.DocumentTypeId,
        ParentId: applicationId,
        ParentType: 7
    }
    setIsLoading(true);
    StudentServices.documentLogs(data)
      .then((res) => {
        setIsLoading(false);
        setLogList(res);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const close=()=>{
    onClose()
  }

  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getLogList()
    }
  }, []);


  return (
    <>

<div className={classes.container}> 
      <SearchModal
        onShow={onShow}
        size="xl">  
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
            <div>
                <div className={classes['heading']}>Document Log</div>
            </div>
            <div>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={close}
            />
            </div>

          </div>
          <div className="container">
          <div className={classes["application-list-container"]}>
           <div className={classes["section-3"]}>
          <Table responsive className={classes.customTable}>
            <thead>
              <tr>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Attachment</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Document Status</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Upload by/ Date</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Verified by/ Date</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Verification tool remark</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Verification tool remark details</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Admission remarks</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Rejected by/ Date</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Rejection remarks</span>
                  </div>
                </th>
                <th>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>Approved by/ Date</span>
                  </div>
                </th>
                <th><span className={classes.columnText}>View File</span></th>
              </tr>
            </thead>
            {logList?.length > 0 && (
              <tbody>
                {logList.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row?.DocumentName}</td>
                    <td>{row?.DocumentStatus=== 2 ?'Approved':row?.DocumentStatus===3? 'Rejected':'' }</td>
                    <td>{row?.UploadbyandDate}</td>
                    <td>{row?.VerifiedbyandDate}</td>
                    <td>{row?.DocVerificationStatus}</td>
                    <td>{row?.DocVerificationStatusDetails}</td>
                    <td>{row?.AdmissionRemark}</td>
                    <td>{row?.RejectedbyDate}</td>
                    <td>{row?.ReviewRemark}</td>
                    <td>{row?.ApprovedbyandDate}</td>
                    <td><a href={blobUrl+ (dir ? (dir +'/') :'') +row?.DocPath} target="_blank"><img src={documentView} alt=''></img></a></td>
                  </tr>
                ))}
              </tbody>
            )}
                   {logList?.length === 0 && (
              <tbody>
                <tr>
                <td  colSpan={11}>
                <div className={classes['no-data']}>No Data Available</div> 
                </td>
                </tr>
            
              </tbody>
            )}
          </Table>
        </div>
      </div>
          </div>

        </div> 
      </SearchModal>
      {isLoading && (
        <Loader></Loader>
      )}
      
    </div>

    </>
  );
};

export default DocumentLogs;
