import React, { useEffect, useState ,useRef } from "react";
import classes from "./Common.module.css";
import ProfileCard from "./ProfileCard";
import ConnectionService from "../../../../../../Services/ConnectionService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import useDebounce from "../../../../../GlobleShared/CustomHooks/useDebounce";

const Invitations = ({searchInput, isSearch, setIsSearch, selectedSort, isSortingFlag, setSortingFlag}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [paginated, setPaginated] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [isLoading, setLsLoading] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const [confirmationText, setConfirmationText] = useState('')
  const [connectionId, setConnectionId] = useState();
  const [totalCount, setTotalCount] = useState();
  const initialized = useRef(false);
  const debouncedSearchInput = useDebounce(searchInput, 300);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getInvitationLIst(page);
    }
    
    if (debouncedSearchInput?.length >= 3) {
      refreshInvitationLIst(page, debouncedSearchInput)
    } 
    else if (debouncedSearchInput?.length <= 2 && isSearch) {
      refreshInvitationLIst(page, "");
      setIsSearch(false);
    }
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (isSortingFlag) {
      refreshInvitationLIst(page, debouncedSearchInput);
      setSortingFlag(false);
    }
  }, [isSortingFlag]);

  const getInvitationLIst = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      sortOrder: Number(selectedSort),
      searchTerm: "",
    };
    setLsLoading(true)
    ConnectionService.getInvitationList(data)
      .then((res) => {
        setProfiles([...profiles,...res.list]);
        setTotalCount(res?.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const refreshInvitationLIst = (pageNum, searchInput) => {
    let data = {
      limit: limit*pageNum,
      page: 1,
      paginated: paginated,
      sortOrder: Number(selectedSort),
      searchTerm: searchInput,
    };
    setLsLoading(true)
    ConnectionService.getInvitationList(data)
      .then((res) => {
        setProfiles([...res.list]);
        setTotalCount(res.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const updateStatus = (connectionIdValue, statusId) => {
    let successMsg;
    let data = {
      connectionId: connectionIdValue,
      connectionStatusId: statusId,
    };
    setLsLoading(true)
    ConnectionService.updateConnection(data)
      .then((res) => {
        refreshInvitationLIst(page);
        setLsLoading(false);
        if(res?.message && statusId === 2){
          successMsg = "You are now connected!";
        }
        if(res?.message && statusId === 3){
          successMsg = "Invitation rejected.";
        }
        toast.success(successMsg);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  };

  const handleAction = (connectionId, action) => {
    setConnectionId(connectionId);
    setModalAction(action);
    setConfirmationText(`Are you sure you want to ${action.toLowerCase()} the selected contact?`);
    setShowModal(true);
  };
  const handleConfirmation = () => {
    if (modalAction === "Accept") {
      updateStatus(connectionId, 2);
    } else if (modalAction === "Decline") {
      updateStatus(connectionId, 3);
    }
    setShowModal(false);
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setConfirmationText("");
    setModalAction("");
  }

  const viewMore = () => {
    getInvitationLIst(page +1);
    setPage(page +1);
  }

  return (
    <div>
      {profiles?.length > 0 && (
        <div className={classes.containers}>
          {profiles.map((profile) => (
            <ProfileCard
              key={profile.rowNum}
              connectionsProfileDetails={profile}
              buttonShow={true}
              handleAction={handleAction}
            />
          ))}
        </div>
      )}

      {(totalCount > profiles?.length && profiles?.length > 0 && !isSearch) && (
        <div className="text-center mt-2 mb-2">
          <button onClick={viewMore} className={classes['view-more']}>View more</button>
        </div>
      )}

      {profiles?.length === 0 && (
        <div className={classes["no-data"]}>
          <p>
            No Invitation Found
          </p>
        </div>
      )}

      {showModal && <ConfirmationModal
        confirmationText={confirmationText}
        onShow={handleAction}
        onClose={closeModalHandler}
        getConfirmation={handleConfirmation}
        btnText={modalAction}
      />}
      {isLoading && (
        <Loader></Loader>
      )}
    </div>
  );
};

export default Invitations;