import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./ProfileUpdateModal.module.css";
import ApplicationService from "../../../../Services/ApplicationServices";
import ProfileService from "../../../../Services/ProfileService"; // Import ProfileService
import { toast } from "react-toastify";
import useLocalStorage from "../../CustomHooks/useLocalStorage";
import { InputGroup, Form } from "react-bootstrap"; // Import Form
import Select from "react-select";
import { components } from "react-select"; // Import components from react-select

// Define CountryOption and SingleValue components
const CountryOption = ({ data, ...props }) => (
  <components.Option {...props}>
    <span className={`fi fi-${data.CShortName.toLowerCase()}`} style={{ marginRight: '8px' }}></span>
    {`+${data.CountryCode} - ${data.CountryName}`}
  </components.Option>
);

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <span className={`fi fi-${data.CShortName.toLowerCase()}`} style={{ marginRight: '8px' }}></span>
    {`+${data.CountryCode}`}
  </components.SingleValue>
);

function ProfileUpdateModal({ onClose, onUpdate, onShow, profileData }) { // Added profileData prop
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetail] = useLocalStorage('userDetail');

  const [updatedData, setUpdatedData] = useState({
    firstName: "",
    lastName: "",
    tagline: "",
    about: "",
    studentId: userDetail?.refId || "", // Set studentId from userDetail
    gender: "",
    maritalStatus: "",
    city: "",
    province: "",
    country: "",
    MailingCity: "",
    MailingProvince: "",
    MailingCountry: "",
    dateOfBirth: "",
    mobileNo: "",
    mobileNoCountryCode: "",
  });
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedSecLanguage, setSelectedSecLanguage] = useState("");

  useEffect(() => {
    if (profileData) {
      setUpdatedData({
        ...updatedData,
        firstName: profileData.firstName || "",
        lastName: profileData.lastName || "",
        tagline: profileData.tagline || "",
        about: profileData.about || "",
        studentId: profileData.studentId || userDetail?.refId || "", // Ensure studentId is set
        gender: profileData.gender || "",
        maritalStatus: profileData.maritalStatus || "",
        city: profileData.city || "",
        province: profileData.province || "",
        country: profileData.country || "",
        MailingCity: profileData.mailingCity || "",
        MailingProvince: profileData.mailingProvince || "",
        MailingCountry: profileData.mailingCountry || "",
        dateOfBirth: profileData.dateOfBirth || "",
        mobileNo: profileData.mobileNo || "",
        mobileNoCountryCode: profileData.mobileNoCountryCode || "",
      });
      setCountryCode(profileData.mobileNoCountryCode || "");
      setCountry(profileData.country || "");
      setDob(profileData.dateOfBirth || "");
      setPhoneNumber(profileData.mobileNo || "");
      setSelectedLanguage(profileData.language || "");
      setSelectedSecLanguage(profileData.secLanguage || "");
    }
  }, [profileData]);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    setIsLoading(true);
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false);
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

 const validateField = (name, value) => {
  let errorMsg = "";
  
  if (name === "countryCode" || name === 'country' || name === 'dob' || name === 'phoneNumber') {
    if (!value) errorMsg = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
  }
  if (name === "mobileNo") {
    if (!/^[0-9]+$/.test(value)) {
      errorMsg = "Phone number must contain only digits";
    }
  }
  if (name === "dob") {
    const today = new Date();
    const selectedDate = new Date(value);
    if (selectedDate > today) {
      errorMsg = "Date of Birth cannot be a future date";
    }
  }
  return errorMsg;
};
  const validateForm = (payloadData, editView) => {
    const errors = {};
    if (editView === "Overview") {
      errors.countryCode = validateField("countryCode", countryCode);
      errors.country = validateField("country", country);
      errors.dob = validateField("dob", dob);
      errors.phoneNumber = validateField("phoneNumber", phoneNumber);
    }
    return errors;
  };

  const isFormValid = () => {
    const errors = validateForm(updatedData, 'Overview');
    return !Object.values(errors).some(error => error);
  };

const handleUpdate = async (e) => {
  e.preventDefault();
  setIsLoading(true);
    const selectedCountry = countryList.find(country => country.CountryCode === countryCode);
  const payload = {
    "about": profileData.About || "",
    "city": profileData.City || "",
    "country": selectedCountry ? selectedCountry.CountryId : 0,
    "dateOfBirth": dob,
    "firstName": profileData.FirstName || "",
    "gender": profileData.Gender || "",
    "language": profileData.Language || "",
    "lastName": profileData.LastName || "",
    "mailingCity": profileData.MailingCity || "",
    "mailingCountry": profileData.MailingCountry || 0,
    "mailingProvince": profileData.MailingProvince || "",
    "maritalStatus": profileData.MaritialStatus || "",
    "mobileNo": phoneNumber,
    "mobileNoCountryCode": countryCode,
    "province": profileData.Province || 0,
    "secLanguage": profileData.SecLanguage || "",
    "studentId": updatedData.studentId || "", // Ensure studentId is included
    "tagline": profileData.TagLine || "",
  };

  ProfileService.updateAboutProfileInfo(payload).then((res) => {
    toast.success('Overview section details updated successfully!');
    onUpdate(true); 
    setIsLoading(false);
    onClose();
  }).catch((error) => {
    toast.error('Failed to update the Overview section. Please try again.');
    setIsLoading(false);
  });
}; 

const handleInputChange = (e) => {
  const { name, value } = e.target;
  if (name === "phoneNumber" && !/^[0-9]*$/.test(value)) {
    return; // Prevent non-numeric input
  }
  setUpdatedData({
    ...updatedData,
    [name]: value,
  });

  // Validate the field immediately and update formErrors error-text
  const error = validateField(name, value);
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [name]: error,
  }));
};

const handleBlur = (e) => {
  const { name, value } = e.target;
  const error = validateField(name, value);
  setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
};

return (
  <div>
      <Modal show={onShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
              <div className={classes["modal-body"]}>
                  <div className="row">
                      <div className="col-12">
                          <h5 className={classes["heading-text"]}>Please fill all the required fields</h5>
                      </div>
                      <div className="form-group col-md-4 mt-4">
  <label className={classes["forLabel"]} >Country Code</label>
  <InputGroup className={classes["contact-input-group"]}>
    <Select
      className={`${classes["country-code-dropdown"]} myflag country-flag-block`}
      options={countryList}
      getOptionLabel={(option) => `+${option.CountryCode} - ${option.CountryName}`}
      getOptionValue={(option) => option.CountryCode}
      onChange={(selectedOption) => {
        const selectedCountry = countryList.find(country => country.CountryCode === selectedOption.CountryCode);
        setCountryCode(selectedOption.CountryCode);
        setUpdatedData({ ...updatedData, mobileNoCountryCode: selectedOption.CountryCode });
        setCountry(selectedCountry ? selectedCountry.CountryName : "");

        // Validate the field immediately and update formErrors
        const error = validateField("countryCode", selectedOption.CountryCode);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          countryCode: error,
        }));
      }}
      components={{ Option: CountryOption, SingleValue }}
      placeholder="Select"
      value={countryList.find(country => country.CountryCode === countryCode)}
      styles={{
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: 'transparent', // Remove the background color of the separator
        }),
        control: (base) => ({
          ...base,
          borderColor: 'transparent', // Remove border color
          boxShadow: 'none', // Remove blue glow
          borderRadius: '0px', // No border radius
          minHeight: '40px',
          '&:hover': {
            borderColor: 'transparent', // Ensure border remains transparent on hover
          },
          '&:focus': {
            outline: 'none', // Remove outline on focus
            borderColor: 'transparent', // Ensure border remains transparent on focus
          },
        }),
        menu: (base) => ({
          ...base,
          width: '200%',
          zIndex: 100,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          padding: '0',
          paddingRight: '25px',
          height: '45px',
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: '0',
          color: "dark grey",
          width: '18px',
          height: '20px',
          lineHeight: '40px',
        }),
        singleValue: (base) => ({
          ...base,
          display: 'flex',
          alignItems: 'center', // Align center
          lineHeight: '40px', // Adjust line height to reduce spacing
        }),
      }}
    />
   <Form.Control
  className={classes["contact-number"]}
  placeholder="(XXX)-XXX-XXXX"
  name="phoneNumber"
  value={phoneNumber}
  onChange={(e) => {
    if (!/^[0-9]*$/.test(e.target.value)) {
      return; // Prevent non-numeric input
    }
    setPhoneNumber(e.target.value);
    setUpdatedData({ ...updatedData, mobileNo: e.target.value });

    // Validate the field immediately and update formErrors
    const error = validateField("phoneNumber", e.target.value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: error,
    }));
  }}
  onBlur={handleBlur}
/>
  </InputGroup>
  {formErrors.countryCode && (
    <div className={classes["errorText"]} style={{ color: '#e82529' }}>{formErrors.countryCode}</div>
  )}
  {formErrors.phoneNumber && (
    <div className={classes["errorText"]} style={{ color: '#e82529' }}>{formErrors.phoneNumber}</div>
  )}
</div>

                      <div className="form-group col-md-4 mt-4">
                          <label className={classes["forLabel"]}>Country</label>
                          <input
                              type="text"
                              className="form-control"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              disabled
                          />
                          {formErrors.country && (
                              <div className={classes["errorText"]} style={{ color: '#e82529' }}>{formErrors.country}</div>
                          )}
                      </div>

                      <div className="form-group col-md-4 mt-4">
                          <label className={classes["forLabel"]}>Date of Birth</label>
                          <input
                              type="date"
                              className="form-control"
                              value={dob}
                              onChange={(e) => {
                                  setDob(e.target.value);
                                  setUpdatedData({ ...updatedData, dateOfBirth: e.target.value });

                                  // Validate the field immediately and update formErrors
                                  const error = validateField("dob", e.target.value);
                                  setFormErrors((prevErrors) => ({
                                      ...prevErrors,
                                      dob: error,
                                  }));
                              }}
                              onBlur={handleBlur}
                              name="dob"
                          />
                          {formErrors.dob && (
                              <div className={classes["errorText"]} style={{ color: '#e82529' }}>{formErrors.dob}</div>
                          )}
                      </div>
                  </div>
                   <div className={classes['btn-position']}>
                      <button className="primary-button m-4" onClick={(e) => {
                          handleUpdate(e);
                          setFormErrors(validateForm(updatedData, 'Overview'));
                      }} disabled={isLoading || !isFormValid()}>
                          Update
                      </button>
                  </div>
              </div>
          </Modal.Body>
      </Modal>
  </div>
);
}

export default ProfileUpdateModal;