import axiosInstance from "./Interceptor";
const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
const GroupService = {

    addGroup: async (data) => {
        try {
          const response = await axiosInstance.post('/group/add', data);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },
      updateGroup: async (data) => {
        try {
          const response = await axiosInstance.put('/group/update', data);
          return response.data;
        } catch (error) {
          throw error.response.data
        }
      },

      groupFileService: async (file) => {
        const formData = new FormData();
        formData.append('dir', dir);
        formData.append('file', file);
     
        try {
          const response = await axiosInstance.post('/common/upload-file/v1', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },
      
    getUserGroupList: async () => {
        try {
        const response = await axiosInstance.post('/group/list');
        return response.data;
        } catch (error) {
        throw error.response.data
        }
    },
    getUserGroupDiscussion: async (GroupID) => {
      try {
      const response = await axiosInstance.get(`group/get-discussion?GroupID=${GroupID}`);
      return response.data;
      } catch (error) {
      throw error.response.data
      }
  },
  getUserGroupMember: async (GroupID) => {
    try {
    const response = await axiosInstance.get(`group/get-member?GroupID=${GroupID}`);
    return response.data;
    } catch (error) {
    throw error.response.data
    }
},
  getDiscussionComments: async (DiscussionID) => {
    try {
    const response = await axiosInstance.get(`group/get-discussion-comments?DiscussionID=${DiscussionID}`);
    return response.data;
    } catch (error) {
    throw error.response.data
    }
  },
  addDiscussionComments: async (data) => {
    try {
      const response = await axiosInstance.post('/group/add-discussion-comments', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  addDiscussion: async (data) => {
    try {
      const response = await axiosInstance.post('/group/add-discussion', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  addCreateGroupRequestForPrivate: async (data) => {
    try {
      const response = await axiosInstance.post("/group/add-group-request", data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  addCreateGroupRequestForPublic: async (data) => {
    try {
      const response = await axiosInstance.post("/group/add-member", data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getInvitations: async () => {
    try {
    const response = await axiosInstance.get('/group/Invite-Get');
    return response.data;
    } catch (error) {
    throw error.response.data
    }
},
getRequests: async () => {
  try {
  const response = await axiosInstance.get('/group/sentInvite-Get');
  return response.data;
  } catch (error) {
  throw error.response.data
  }
},
updateGroups: async (data) => {
  try {
    const response = await axiosInstance.put('/group/status', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
updateComment: async (data) => {
  try {
    const response = await axiosInstance.put('/group/discussion-comment-status', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
joinGroup: async (data) => {
  try {
    const response = await axiosInstance.post('/group/add-request-group-member', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},

acceptGroup: async (data) => {
  try {
    const response = await axiosInstance.post('/group/accept-group-join-request', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
withdrawGroup: async (data) => {
  try {
    const response = await axiosInstance.post('/group/withdraw-request-group-member', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
getSuggestionGroup: async () => {
  try {
    const response = await axiosInstance.get('/group/suggestion/list');
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
joinSuggestionGroup: async (data) => {
  try {
    const response = await axiosInstance.post('/group/suggestion/join', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},
getConnectionList: async (data) => {
  try {
    const response = await axiosInstance.post('/user/list', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},


getGroupsInvite: async (data, id) => {
  try {
    const response = await axiosInstance.post('/group/user-invite-list/{groupID}?GroupID='+id, data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},


getPendingGroups: async (id) => {
  try {
    const response = await axiosInstance.get('group/request/list?GroupID='+ id);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},

reportPost: async (data) => {
  try {
    const response = await axiosInstance.post('/group/insert-report-reason', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},

getModeratorList: async (id) => {
  try {
    const response = await axiosInstance.get('group/moderator/list?GroupID='+ id);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},

reportAction: async (data) => {
  try {
    const response = await axiosInstance.post('/group/report-action', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},


}

export default GroupService;