import React, { useState, useRef, useEffect } from 'react';
import classes from "./StudentDocumentsSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';
import deleteIcon from "../../../../../../Assets/Logo/documentDeleteICon.svg";
import logEditICon from "../../../../../../Assets/Logo/logEditApplication.svg";
import actionIcon from "../../../../../../Assets/Logo/table-action.svg";
import documentView from "../../../../../../Assets/Logo/documetnView.svg";
import documentDownloadArrow from "../../../../../../Assets/Logo/uploadBlueIcon.svg";
import ConfirmationModal from '../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';
import ProfileService from '../../../../../../Services/ProfileService';
import Loader from '../../../../../GlobleShared/Loader/Loader';
import UploadApplicationDoucment from '../../../../../GlobleShared/UploadDocument/UploadApplicationDocument/UplaodApplicationDocuemnt';
import DocumentLogs from '../../../../../GlobleShared/UploadDocument/DocumentLogs/DocumentLogs';

const StudentDocumentsSection = ({  rowData, getDocumentUpdate, applicationId}) => { 
  const [popUpView, setPopUpView] = useState(null);
  const dropdownRef = useRef(null);
  const blobUrl=process.env.REACT_APP_URL_PATH
  const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
  const tableColumns = ["Attachment", "Remark", "Date Uploaded", "Uploaded By"];
  const [confrimModalShow, seConfrimModalShow]=useState(false)
  const [confirmationText, setConfirmationText]=useState('')
  const [documentId, setDocumentId]=useState(0)
  const [isLoading, setIsLoading]=useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const[parentType, setparentType]=useState(7)
  const[selectedData,setSelectedData]=useState('')
  const [openLogs,setOpenLogs]=useState(false)
  

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    if (isNaN(date)) {
      return 'Invalid Date'; 
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  };

  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPopUpView(null);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);

  const getConfirmation=()=>{
    setIsLoading(true)
    ProfileService.deleteDocument(documentId).then(res=>{
        setIsLoading(false)
        getDocumentUpdate()
        seConfrimModalShow(false)
    }).catch(error=>{
        setIsLoading(false)
        
    })

}

const openConfirmationModal=(id)=>{
  setDocumentId(id)
  seConfrimModalShow(true)
  setConfirmationText('Are you sure, you want to delete the selected document?')

}

  const onClose=()=>{
    seConfrimModalShow(false)
  }

  const openModel=(data)=>{
    setModalOpen(true)
    setSelectedData(data)

}
const close=()=>{
    setModalOpen(false)

}


const getUploadConfirmation=(value)=>{

    const data = {
        DocPath: value,
        DocumentId: 0,
        DocumentName: selectedData?.DocumentName,
        DocumentTypeId: Number(selectedData?.DocumentTypeId),
        DueDate: "",
        ExpiryDate: "",
        ParentId: applicationId,
        ParentType: parentType
    }
    setIsLoading(true)
    ProfileService.addStudentDocument(data).then(res=>{
        setIsLoading(false)
        getDocumentUpdate()
        setModalOpen(false)
    }).catch(error=>{
        setIsLoading(false)
        
    })

}
const onCloseLogs=()=>{
  setOpenLogs(false)
}
const openLogsModal=(data)=>{
  setOpenLogs(true)
  setSelectedData(data)
}

  return (
    <>

       {modalOpen && (
         <UploadApplicationDoucment onShow={modalOpen} onClose={close} getConfirmation={getUploadConfirmation} data={selectedData}></UploadApplicationDoucment>
        )}

    { confrimModalShow && (
     <ConfirmationModal onShow={confrimModalShow} onClose={onClose}  btnText={"Yes"} 
     confirmationText={confirmationText} getConfirmation={getConfirmation}></ConfirmationModal>
    )}

   {openLogs && (
     <DocumentLogs onShow={openLogs} onClose={onCloseLogs} applicationId={applicationId} documentData={selectedData}></DocumentLogs>
    )}

 
   
    <div className={classes.container}>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableColumns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>View File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {rowData && rowData?.length> 0 ? 
          (rowData.map((document) => (
            <tr key={document.DocumentId}>
              <td>{document.DocumentName}</td>
              <td>{document.ReviewRemark || 'N/A'}</td>
              <td>{formatDate(document.AddStamp)}</td>
              <td>{document.UploadBy}</td>
              <td>
                <span
                  className={classes.actionBtn}
                  // onClick={() => handleViewRequest(document.DocumentId)}
                  >
                  <a href={blobUrl + (dir ? (dir +'/') :'')+document.DocPath} target="_blank" rel="noopener noreferrer">
                  <img title='View' src={documentView} className={classes.actionICon} alt="View" />
                </a>
                </span>
              </td>
              <td>
                <img
                  src={actionIcon}
                  alt="Action"
                  className={classes.icon}
                  onClick={() => setPopUpView(document.DocumentId)}
                />
                {popUpView === document.DocumentId && (
                  <div className={classes.positionRelative} ref={dropdownRef}>
                    <div className={classes.popContainer}>
                      <div className={classes.IConAndText}
                        onClick={()=>{openModel(document)}}>
                        <div>
                          <img className={classes.popUpICon} src={documentDownloadArrow} alt="Upload" />
                        </div>
                        <div>
                          <span  className={classes.btnText}>Upload</span>
                        </div>
                      </div>
                      <div
                        className={classes.IConAndText}
                        onClick={()=>{openLogsModal(document)}}>
                        <div>
                          <img className={classes.popUpICon} src={logEditICon} alt="Log" />
                        </div>
                        <div>
                          <span className={classes.btnText}>Log</span>
                        </div>
                      </div>
                      <div  onClick={()=>{openConfirmationModal(document.DocumentId)}} className={classes.IConAndText}>
                        <div>
                          <img className={classes.popUpICon} src={deleteIcon} alt="Delete" />
                        </div>
                        <div>
                          <span  className={classes.btnText}>Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          )))
          : 
         ( <tr>
            <td colSpan={6}> <div className={classes.noData}>No Data Available</div> </td>
          </tr>) }
        </tbody>
      </Table>
    </div>
    {isLoading && (
      <Loader></Loader>
    )}
    </>
  );
};

export default StudentDocumentsSection;
