import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import classes from "./Notifications.module.css";
import messageIcon from '../../../../../Assets/Logo/message-icon.svg';
import profilePic1 from '../../../../../Assets/Images/profile_avatar.png';
import appIC from "../../../../../Assets/Logo/ic-application.svg";
import nodata from "../../../../../Assets/Logo/no-data1.svg";
import corssIcon from '../../../../../Assets/Logo/crossred.svg';
import NotificationService from "../../../../../Services/Notification";
import Loader from "../../../../GlobleShared/Loader/Loader";
import SearchModal from '../../Search/SearchModal/SearchModal';
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';


const Notifications = () => {

  const initialized = useRef(false)
  const [showModal, setShowModal] = useState(false);
  const [showDescription, setShowDescription] = useState('');
  const [tab, setTab] = useState("All");
  const [filteredNotificationsData, setFilteredNotificationsData] = useState([]); 
  const [notificationsData, setNotificationsData] = useState([]);

  const [notificationsCount, setNotificationsCount] = useState([]);
  const { setNotifyCount } = useBehaviorSubject();
  const navigate = useNavigate();


  const [isLoading, setLsLoading] = useState(false);

  // const handleClose = () => {
  //   setShowModal(false);
  // };



  const openUrl = (url)=>{
    // setShowModal(true);
    // setShowDescription(desc);
    if (url) {
      // navigate(url);
      console.log(url);
  }
  }


  const getNotifications =() => {
      setLsLoading(true)
      NotificationService.getNotificationlist().then(res => {
        setNotificationsData(res?.data);
        setFilteredNotificationsData(res?.data);
        setLsLoading(false);
      }).catch(error => {
        setLsLoading(false);
      })
  };

  const getNotificationsCount =() => {
      setLsLoading(true)
      NotificationService.getNotificationCount().then(res => {
        setNotificationsCount(res);
        setNotifyCount(res);
        setLsLoading(false);
      }).catch(error => {
        setLsLoading(false);
      })
  };


  const notificationMarkReadUpdate  = (id, type) => {
    let data = {
      NotificationType: type === "Social" ? 2 : 0,
      TransactionId: id
    }
      setLsLoading(true)
      NotificationService.notificationreadUpdate(data).then(res => {

        if(res?.response === "OK"){

        const updatedNotificationsData = notificationsData?.map((item) =>
            item?.TransactionId === id ? { ...item, ReadFlag: 1 } : item
          );
          setNotificationsData(updatedNotificationsData);
          getNotificationsCount();
        }
   
        setLsLoading(false);
      }).catch(error => {
        setLsLoading(false);
      })
  };


  const formatPostTime = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    
    if (diffInSeconds < 60) {
      return 'Now';
    } else if (diffInSeconds < 120) {
      return '2 minutes ago';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 7200) {
      return '1 hour ago';
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 172800) {
      return 'Yesterday';
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 2419200) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 29030400) {
      const months = Math.floor(diffInSeconds / 2419200);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(diffInSeconds / 29030400);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  };
  


  useEffect(() => {
    if (!initialized.current) {
        initialized.current = true;
        getNotifications();
        getNotificationsCount();
    }
  }, []);


  const filteredNotifications = () => {
    // debugger
    let filtered = notificationsData;
    if (tab === "Social") {
      filtered = notificationsData.filter((item) => item?.['Notification Type'] === "Social");
    } else if (tab === "Application") {
      filtered = notificationsData.filter((item) => item?.['Notification Type'] === "Application");
    }
    setFilteredNotificationsData(filtered);
  };



  useEffect(() => {
    filteredNotifications();
  }, [tab, notificationsData]);


  return (
    <div className={classes["notification-container"]}>

      <div className={classes["notification-section1"]}>
        <div className={classes["user_navigation"]}>
          <button
            className={tab === "All" ? classes["active_button"] : ""}
            onClick={() => setTab("All")}
          >
            All
          </button>
          <button
            className={tab === "Social" ? classes["active_button"] : ""}
            onClick={() => setTab("Social")}
          >
            Social
          </button>
          <button
            className={tab === "Application" ? classes["active_button"] : ""}
            onClick={() => setTab("Application")}
          >
            Application
          </button>
        </div>
      </div>



      <div className={classes["notification-section2"]}>

      {filteredNotificationsData?.length > 0 ?(
        <div className={classes["notification-section2-content"]}>


           { filteredNotificationsData?.map((item) => (
            <div
              key={item?.id}
              className={`${classes["notification-Item"]} ${ !item?.ReadFlag ? classes["unread"] : ""}`}
              onClick={() => { if (!item?.ReadFlag){ notificationMarkReadUpdate(item?.TransactionId, item?.['Notification Type'])}}}
               >

               {  item?.['Notification Type'] ==="Social" ? (
              <div className={classes["notification-image-part"]}>
                <img
                  src={profilePic1}
                  alt="notification"
                  className={classes["notification-image"]}
                />
              </div>) : (

              <div className={classes["notification-icon-part"]}>
                <img
                  src={appIC}
                  alt="notification"
                  className={classes["notification-icon"]}
                />
              </div>
              )}

              <div  title={item?.['Query Details'] ? item?.['Query Details'].replace(/<[^>]*>?/gm, ' ') : ''}   className={classes["notification-text"]} onClick={()=>{openUrl(item?.Url)}}>
               <span dangerouslySetInnerHTML={{ __html: item?.['Query Details'] }} />
              </div>
              <div className={classes["notification-date"]}>
                {/* {new Date(item?.['Add Stamp']).toLocaleDateString()} */}
                {formatPostTime(item?.['Add Stamp'])}
             </div>
            </div>
          
          ))}

        

        </div>) : (

            <div className={classes['no-data']}>
            <img src={nodata} alt=''></img>
            <p className="mt-2"><span className={classes['red-span']}>No notification </span> <span className={classes['backSpan']}>found at the moment.</span></p>
            </div>
        )}


      </div>

      {/* {showModal && (
      <SearchModal
        onShow={showModal}
        size="lg"
       >  
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
          <div className={classes.title}>Details</div>
          <img
              title="Close"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}
            />
          </div>
          <div  title={showDescription ? showDescription.replace(/<[^>]*>?/gm, '') : ''}  className={classes.detailscontainer}>
          <span dangerouslySetInnerHTML={{ __html: showDescription }} />
          </div>
        </div> 
      </SearchModal>
    )} */}

      {isLoading && (
        <Loader></Loader>
      )}

    </div>
  );
};

export default Notifications;
