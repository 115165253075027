import axiosInstance from "./Interceptor";

const ChatMessagesService = {

   getChatId: async (data) => {
        try {
          const response = await axiosInstance.post('/chat/insert-details', data);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },

  getChatMessages: async (data) => {

    try {
      const response = await axiosInstance.post('/chat/message-get', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

   chatMessageInsert: async (data) => {
    try {
      const response = await axiosInstance.post('/chat/message-insert', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },


   chatMessageUpdate: async (data) => {
    try {
      const response = await axiosInstance.put('/chat/message-update', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

   chatMessageDelete: async (data) => {
    try {
      const response = await axiosInstance.post('/chat/message-delete', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

   getConnections: async (data) => {
    try {
      const response = await axiosInstance.post('/chat/user-connection', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  updateUnreadChat: async (chatId) => {
    try {
      const response = await axiosInstance.put('/chat/update-unread-message', chatId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
    getUnreadCount: async (data) => {
    try {
      const response = await axiosInstance.get('/chat/total-unread-count-get', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
 
};

export default ChatMessagesService;


