import React, { useEffect, useRef, useState } from "react";
import classes from "./SubConnection.module.css";
import ConnectionProfileCard from "./ConnectionProfileCard";
import ChatWindow from "../../../../GlobleShared/Chat/ChatWindow";
import dropdown from "../../../../../Assets/Logo/reddropdown.svg";
import ConnectionService from "../../../../../Services/ConnectionService";
import Loader from "../../../../GlobleShared/Loader/Loader";
import useDebounce from "../../../../GlobleShared/CustomHooks/useDebounce";
import { Dropdown } from "react-bootstrap";

const SubConnections = ({searchInput, isSearch, setIsSearch}) => {
  const [profiles, setProfiles] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const [chatWindows, setChatWindows] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false);
  const debouncedSearchInput = useDebounce(searchInput, 300);
  const [selectedOption, setSelectedOption] = useState("1");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpenChat = (profile) => {
    setChatWindows([{ ...profile, isMinimized: false }]);
  };
  
  const handleCloseChat = () => {
    setChatWindows([]);
  };
  
  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) =>
      prevChats.map((chat) =>
        chat?.userConnectionId === id
          ? { ...chat, isMinimized: !chat.isMinimized }
          : chat
      )
    );
  };
  

  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };

  const handleHidePopup = () => {
    refreshConnection(page,limit)
    
  };
  const hidePopup = () => {
    setActivePopup(null);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getConnection(page);
    }

    if ((debouncedSearchInput !== null) && debouncedSearchInput.length >= 3) {
      refreshConnection(page, limit, debouncedSearchInput,selectedOption)
    } 
    else if ((debouncedSearchInput !== null) && (debouncedSearchInput.length <= 2 && isSearch)) {
      refreshConnection(page, limit, "",selectedOption);
      setIsSearch(false);
    }
  }, [debouncedSearchInput]);

  const getConnection=(pageValue)=>{
    let data={
      limit: limit,
      page: pageValue,
      paginated: paginated,
      sortOrder: Number(selectedOption),
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...profiles, ...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  const refreshConnection=(pageNum,limit,searchInput,sortingValue)=>{
    let data={
      limit: pageNum*limit,
      page: 1,
      paginated: paginated,
      sortOrder: Number(sortingValue),
      searchTerm: searchInput
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  const getdataReload=()=>{
    //getConnection(page);
    refreshConnection(page,limit)
  }
  const viewMore=()=>{
    getConnection(page+1)
    setPage(page+1)
  }

  const handleSelect = (eventKey) => {
    if(eventKey && selectedOption !== eventKey){
      setSelectedOption(eventKey);
      refreshConnection(page, limit, debouncedSearchInput, eventKey);
    }
  };

  return (
    <div className={classes.commonSection}>
      <div className={classes.container}>
        <div className={classes.displayMange}>
          <div className={classes.firstDisplay}>{totalCount} Connections</div>
          <div className={classes["sorting-drop-down-block"]}>
            <div className={classes["sorting-text"]}>Sort by:</div>
            <Dropdown
              onSelect={handleSelect}
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
              className="drop-down-icon"
            >
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className={`${classes["drop-down-toggle"]}`}
              >
                {(selectedOption && selectedOption === "1") ? 'Recently Added' : (selectedOption && selectedOption === "2") ? 'A to Z' : 'Z to A'}
                {isDropdownOpen ? (
                  <img src={dropdown} alt=""/>
                ) : (
                  <img src={dropdown} alt=""/>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className={classes["drp-down-items"]}>
                <Dropdown.Item eventKey="1">Recently Added</Dropdown.Item>
                <Dropdown.Item eventKey="2">A to Z</Dropdown.Item>
                <Dropdown.Item eventKey="3">Z to A</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {profiles?.length > 0 && (
          <div>
            {profiles.map((profile, index) => (
              <ConnectionProfileCard
                key={profile?.userConnectionId}
                connectionId={profile?.userConnectionId}
                backgroundImage={profile?.profileBGImage}
                imgSrc={profile?.profileImage}
                name={profile?.displayName}
                description={profile?.aboutUs}
                connections={profile?.connections}
                isPopupVisible={activePopup === profile?.userConnectionId}
                onTogglePopup={() => handleTogglePopup(profile?.userConnectionId)}
                onHidePopup={handleHidePopup}
                onOpenChat={() => handleOpenChat(profile)}
                disconnectIsTrue={false}
                index={index}
                totalCards={profiles?.length}
                getConfirmationValue={getdataReload}
                hidePopupBox={hidePopup}
                receiverUserId={profile?.receiverUserId}
                senderUserId={profile?.senderUserId}
                studentId={profile?.studentId}
              />
            ))}
            {(totalCount !== profiles?.length && profiles?.length > 0) && (
              <div className="text-center mb-2">
                <button onClick={viewMore} className={classes['view-more']}>View more</button>
              </div>
            )}

          </div>
        )}
        {profiles?.length === 0 && (
          <div className="text-center">
            <p>
              No Connection Found
            </p>
          </div>
        )}

        <div className={classes.chatContainer}>
          <div className={`${classes.forChatScreen} row`}>
            <div className="col-md-6"></div>
            {chatWindows.slice(0, 2).map((chat, index) => (
              <div
                key={chat?.userConnectionId}
                className={`col-md-4`}>
                <ChatWindow
                  profileData={chat}
                  onClose={() => handleCloseChat(chat?.userConnectionId)}
                  onMinimize={() => handleMinimizeChat(chat?.userConnectionId)}
                  isMinimized={chat.isMinimized}
                />
              </div>
            ))}
            <div className="col-md-2">
              
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <Loader></Loader>
      )}
    </div>

  );
};

export default SubConnections;
