import React,{useState} from 'react';
import classes from './Programs.module.css';
import { useParams } from "react-router-dom";
import calendar from "../../../Assets/Logo/calender-icon.svg";
import { useNavigate } from "react-router-dom";
import { useBehaviorSubject } from "../../GlobleShared/BehaviorSubject/BehaviorSubject";

import sideClose from "../../../Assets/Logo/sideclose-2.svg";
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";
import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";

import newUniversityLogo from "../../../Assets/Logo/university-icon.svg";
import GuestUserModal from '../../GlobleShared/Modal/GuestUserModal/GuestUserModal';

const bloburl = process.env.REACT_APP_LOGO_PATH;

function Programs({ program , institutesDetail}) {
  const {institutionId} = useParams();
  const {selectedCountryId} = useParams();
  const { isLoginUser } = useBehaviorSubject();
  const [guestuserFlag, setGuestuserFlag] = useState(false);

  const [userDetail] = useLocalStorage('userDetail');
  const studentId=userDetail?.refId
  const navigate = useNavigate();

  function stripHtmlTags(str) {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const limitCharacters = (text, limit = 20) => {
    if (!text) return 'N/A';
    return text.length > limit ? text.substring(0, limit) + '...' : text;
};
const programId = program?.ProgramId;
const selectedProgramTypeIds = program?.ProgramTypeId;
  // Define the image source
  const imageSrc = program?.Institution?.[0].InstLogoPath
    ? bloburl + program.Institution?.[0].InstLogoPath
    : bloburl+institutesDetail?.InstLogoPath;
    const handleProgramClick = (programId) => {
      const programDetailUrl = `/member/programs/detailPage/${programId}`;
      window.open(programDetailUrl, '_blank');
    };
    const handleApplyClick = (data) => {
      if (isLoginUser.isGuest) {
        setGuestuserFlag(true);
        return;
    }
      const programTypeId = selectedProgramTypeIds === "1" ? 1 : 2;
      navigate("/member/application/add/" + programTypeId + "?studentId=" + studentId + '&programId=' + data?.ProgramId);
  };

  const hideGuestModalhandler = () => {
    setGuestuserFlag(false);
  }
  return (
    <>
     {guestuserFlag && (
        <GuestUserModal onClose={hideGuestModalhandler} onShow={guestuserFlag} />
      )}
    <div>
       <div className={classes["program-cards"]}>
      <div className={classes["card-section"]}>
        <div className={classes["section-1"]}>
          <div className={classes["card-header-block-1"]}>
            {/* <div className={classes["card-rank"]}>
              <span className={classes["rank-text"]}>
                {program?.GlobalRank ? `Ranked: #${program.GlobalRank}` : "Ranked: N/A"}
              </span>
            </div> */}
            <div className={classes["card-header"]}>
              <div className={classes["university-icon"]}>
                <img  style={{ height: '50px', width: '110px' }} src={imageSrc} alt="Profile" />
              </div>
              <div className={classes["university-detail"]}>
                {institutesDetail ? (
                    <>
                        <div className={classes["university-name"]}>
                            {institutesDetail?.InstName || "N/A"}
                        </div>
                        <div className={classes["university-address"]}>
                            {`${institutesDetail?.InstAddress1 || "N/A"}, ${institutesDetail?.InstAddress2 || "N/A"} ${institutesDetail?.InstCountryName || "N/A"}`}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes["university-name"]}>
                            {program?.Institution?.[0].InstName || "N/A"}
                        </div>
                        <div className={classes["university-address"]}>
                            {`${program?.Institution?.[0].InstCity || "N/A"}, ${program?.Institution?.[0].InstProvince || "N/A"}, ${program?.Institution?.[0].InstCountry || "N/A"}`}
                        </div>
                    </>
                )}
            </div>

            </div>
          </div>
          <div className={classes["card-header-block-2"]}>
          <div className={classes["detail_UI"]}>{program?.ProgramLevel || 'N/A'}{' '}{program?.Duration || 'N/A'}</div>
          <div className={`row justify-content-between ${classes["detail"]}`}>
            <div title={program?.ProgramName} className={` col-8 ${classes["program-name"]} col-8-to-7`}>
              {program?.ProgramName || "N/A"}
                </div>
              <div className={` col-3 ${classes["left"]} ${classes["calender-data"]}`}  style={{ padding: 0 }}>
                <img src={calendar} alt="calendar"  style={{marginRight: '7px',height: '20px', width: '20px'}}/>
                  {program?.Duration}
                </div>
            </div>
            <div className={`row justify-content-between ${classes["detail"]}`}>
{/*               
            <div className={classes["program-detail-text"]}>
              {program?.Description || "No description available."}
            </div> */}
    <div className={`col-6 ${classes["program-detail-text"]} col-8-to-7`}  title={stripHtmlTags(program?.Description) || 'N/A'}>
                                                <p dangerouslySetInnerHTML={{ __html:   limitCharacters(stripHtmlTags(program?.Description), 200) }}></p>

                                                {/* {program?.Description || 'N/A'} */}
                                            </div>
            <div className={`col-3  ${classes["left"]} ${classes["Intake"]}`}  style={{ padding: 0 }}>
              <div className={classes["intake-header"]}>Intake</div>
              {program?.Intakes && program.Intakes.length > 0 ? (
                program.Intakes.slice(0, 2).map((intake, index) => (
                  <div key={index} className={classes["intake-detail"]}>
                    {intake.IntakeName || "N/A"}
                  </div>
                ))
              ) : (
                <div className={classes["detail"]}>N/A</div>
              )}
            </div>
              </div>
          </div>
        </div>
        <div className={classes["section-2"]}>
          <div className={classes["program-detail-section-1"]}>
            {/* <div className={classes["detail"]}>
              {program?.Duration || "N/A"}
            </div>
            <div className={classes["detail"]}>
              {program?.ProgramLevel || "N/A"}
            </div> */}
           <div className={`${classes["fee-detail"]}`}>
                                            {/* <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail_UI"]}>{program?.ApplicationFee || 'N/A'}</div> 
                                            </div> */}
                                             <div className=''>
                                            <div className={classes["header"]}>Application Fee</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.ApplicationFee || 'N/A'}
                                                </div>
                                            </div>
                                            <div className=''>

                                            <div className={classes["header"]}>Tuition Fees</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.TuitionFee || 'N/A'}
                                                </div>
                                                            <div className={classes["new-fee"]}>
                                                                <span className={classes['other-fee']} data-tooltip="Other Fee">Other Fee</span>{'  '}
                                                                <img src={sideClose} alt='sideclose'></img>
                                                            </div>
                                            </div>
                                            <div className=''>

                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.CostOfLiving || 'N/A'}
                                                </div>
                                            </div>
                                            {/* <div className={classes["vertical-control"]}> */}
                                            {/* </div> */}
                                            {/* <div className={classes["vertical-control"]}>
                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}>{program?.Costofliving || 'N/A'}</div>
                                            </div> */}
                                            </div>
                                            <div className={`d-flex mt-2 bd-highlight  ${classes["subInst"]}`}>
                                            <div className={`pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={`text-56 ${classes["InstSubTitle"]}`}> {program?.ScholarShipAmtType !== null ? 'Scholarship' :'No Scholarship'}</p>
                                            {program?.ScholarShipAmtType === "0" && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ScholarShipMinAmt} to {program?.ScholarShipMaxAmt}% Off
                                                </p>
                                            )} 
                                            {program?.ScholarShipAmtType === "1" && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ProgramCurrency} {program?.ScholarShipMinAmt} to {program?.ProgramCurrency} {program?.ScholarShipMaxAmt}
                                                </p>
                                            )}
                                            </div>
                                        </div>
                                    {program?.WaiverPer && (
                                        <div className={`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        <div className={` pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={classes["InstSubTitle"]}>
                                            Application Fee Waivers
                                            </p>
                                            <span className={`${classes["strike-center"]} ${classes["text-gray"]}  ${classes["font-weight-bold"]} mx-1`}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFee?.FeeAmount}`}
                                            </span>
                                            <span className={`${classes["text-green"]}  ${classes["font-weight-bold"]} mx-1`}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFeeAfterWaiver}`}
                                            </span>
                                            <span className={` font-weight-bold mx-1 ${classes["waiverPercentage"]}`}>
                                            {`${program?.WaiverPer}% Off`}
                                            </span>
                                        </div>
                                        </div>
                                    )}
            <div className={classes["parent-button"]}>
                                                <div>
                                                <button className={classes["know-btn"]}  onClick={() => handleProgramClick(program?.ProgramId)}>Know More</button>
                                                </div>
                                                <div>
                                                <button className={classes["create-btn"]} onClick={() => handleApplyClick(program)}>Create Application</button>
                                                </div>
                                            </div>
          </div>
          <div className={classes["program-detail-section-2"]}>
            {/* <div>
              <div className={classes["header"]}>Application Fee</div>
              <div className={classes["detail"]}>
                {program?.ProgramCurrency} {program?.ApplicationFee || "N/A"}
              </div>
            </div>
            <div>
              <div className={classes["header"]}>People like You</div>
              <div className={classes["detail"]}>
                {program?.PeopleLikeYou || "Data Not Available"}
              </div>
            </div>
            <div>
              <div className={classes["header"]}>Likelihood of Graduation</div>
              <div className={classes["detail"]}>
                {program?.GraduateLikelihood || "N/A"}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
}

export default Programs;