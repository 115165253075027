import React, { useEffect, useRef, useState } from 'react';
import styles from './AICounsellor.module.css'; // Import the CSS module
import arrow from '../../../../../../Assets/Logo/send-arrow.svg'; // Assume you have this icon file in the same directory
import fullScreen from '../../../../../../Assets/Logo/fullScreen.svg'; // Fullscreen icon
import resize from '../../../../../../Assets/Logo/resize.svg'; // Resize icon
import croseWhite from '../../../../../../Assets/Logo/crose-white.svg'; // White cross icon
import AIServise from '../../../../../../Services/AiService';

import aichaticon from '../../../../../../Assets/Logo/aichat-icon.svg'
import useLocalStorage from '../../../../../GlobleShared/CustomHooks/useLocalStorage';
import bummyImg from '../../../../../../Assets/Logo/dummy-image-icon.svg'
import redAI from '../../../../../../Assets/Logo/ai-chat-cion.svg'
import refreshIcon from '../../../../../../Assets/Logo/ai-refresh.svg'
import chatArrow  from '../../../../../../Assets/Logo/chat-arrow-icon.svg'
import crossIcon from '../../../../../../Assets/Logo/crossred.svg'


import loader from '../../../../../../Assets/Logo/fade-loader.svg'
import { useBehaviorSubject } from '../../../../../GlobleShared/BehaviorSubject/BehaviorSubject';
import GuestUserModal from '../../../../../GlobleShared/Modal/GuestUserModal/GuestUserModal';
// const dataContent=['Best Courses', 'University Applications', 'Available Scholarships', 'Career Guidance',
//   'Admission Requirements', 'Application Status', 'USA VISA Process'];
 const dataContent=['Available Scholarships', 'Career Guidance',
  'Admission Requirements', 'Application Status'];

const AICounsellor = () => {
  const [isOpenChat, setIsOpenChat]=useState(false)
  const blobURL= process.env.REACT_APP_URL_PATH
  const dir=process.env.REACT_APP_BLOB_DIR_USER
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [inputMessage, setInputMessage] = useState(''); 
  const [messages, setMessages] = useState([]); 
  const [currentIndex, setCurrentIndex] =useState(-1)
  const [isLoading, setIsLoading]=useState(false)
  const [disabledTab, setDisabledTab]=useState(false)
  const divRef = useRef(null);
  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail?.refId;
  const initial=useRef(null)
  const userInfo= JSON.parse(localStorage.getItem('userInfo'))
  const userImage= userInfo?.ProfileImgPath

  const [ontimeSesion, setOntimeSesion]=useState(false);
  const { isLoginUser } = useBehaviorSubject();
  const [guestuserFlag, setGuestuserFlag] = useState(false);


  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleResize = () => {
    setIsFullscreen(false);
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage(e.target.value);
    }
  };

   const createSession=()=>{
    const formData = {
      sessionId: studentId.toString()
    };
    AIServise.createSession(formData).then(res=>{
      setOntimeSesion(true)

    })

   }

   const refreshChat=()=>{
    const formData = {
      sessionId: studentId.toString()
    }
    setOntimeSesion(false)
    setDisabledTab(false)
    setMessages([])
    setInputMessage('')
    AIServise.refreshSession(formData).then(res=>{
      setOntimeSesion(true)
    }).catch(error=>{

    })

   }


  const handleSendMessage = (value) => {
    if (value.trim()) {
      scrollToBottom()
      setMessages([...messages, { sender: "user", text: value, time: new Date().toLocaleTimeString()}]);
      setCurrentIndex( messages?.length -0);
      const formData = new FormData();
      formData.append("question", value);
      formData.append("sessionId", studentId.toString());
      setIsLoading(true)
      AIServise.Aichat(formData).then(res=>{
        scrollToBottom()
      setIsLoading(false)
      setMessages([...messages, { sender: 'user', text: value , time: new Date().toLocaleTimeString(), aiResponse:'Bot', aiChat:res?.answer}]);
     }).catch(error=>{
      scrollToBottom()
      setIsLoading(false)
      setMessages([...messages, { sender: 'user', text: value , time: new Date().toLocaleTimeString(),aiResponse:'Bot', aiChat:error?.message}]);
     })
     setInputMessage('');
     
    }
  };
  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }


  const openChat=(value)=>{
    if (isLoginUser.isGuest) {
      setGuestuserFlag(true);
      return;
    }
    if(value && !ontimeSesion){
      createSession()
    }
    setIsOpenChat(value)
  }

  const hideGuestModalhandler = () => {
    setGuestuserFlag(false);
  }

  return (
    <>
      {guestuserFlag && (
        <GuestUserModal onClose={hideGuestModalhandler} onShow={guestuserFlag} />
      )}
      {isOpenChat && (
        <div className={styles.impcontainer}>
          <div className={styles.counsellorContainer}>
            {/* <div className={styles['crossicon']}>
              <img title='Close' onClick={() => { openChat(false) }} src={crossIcon} alt=''></img>
            </div> */}
            <div className={styles['chat-header']}>
              <div className='d-flex'>
                <div className={styles['header-icon']}>
                  <img alt='' src={redAI}></img>
                </div>
                <div>
                  <p className={styles['chat-heading']}>MSM Buddy</p>
                  <p className={styles['chat-sub-heading']}>AI Student Counselor</p>
                </div>
              </div>
              <div className={styles['chat-heading-icons']}>
                <img title='Refresh' onClick={refreshChat} className={styles['refresh-icon']} src={refreshIcon} alt=''></img>
                <img title='Close' onClick={() => { openChat(false) }} src={croseWhite} className={styles['cross-icon']} alt=''></img>
              </div>
            </div>
            {/* Chat Container */}
            <div className={styles['chat-scroll']} ref={divRef}>
              {!ontimeSesion && (
                <div className='d-flex'>
                  <div className={styles['ai-icon']}><img alt='' src={aichaticon}></img></div>
                  <div className={styles['ai-caht-div']}>
                    <img className={styles['loader-height']} src={loader} alt=''></img>
                  </div>
                </div>
              )}

              {ontimeSesion && (
                <div className='d-flex'>
                  <div className={styles['ai-icon']}><img alt='' src={aichaticon}></img></div>
                  <div>
                    <div className={styles['ai-caht-div']}>
                      <p>Hey!👋 Curious about your study options or career opportunities? Let's talk about it!</p>
                    </div>
                    {dataContent.map(item => (
                      <button disabled={disabledTab} onClick={() => { setDisabledTab(true); setInputMessage(item); handleSendMessage(item) }} className={styles['tab-btn']}>{item}</button>
                    ))}
                  </div>
                </div>
              )}

              <div className={styles.chatContainer}>
                {messages.map((msg, index) => (
                  <>
                    {msg.sender === "user" && (
                      <div key={index} className={msg.sender === "user" ? styles.fullscreenMessageDiv : styles.fullscreenMessageUser}>
                        <div className='d-flex justify-content-end'>
                          <div className={msg.sender === "user" ? styles.chatMessage : styles.chatMessageOpposite}>
                            {msg.text}
                          </div>
                          <div>
                            <div className={styles['user-icon']}><img cl alt='' src={userImage ? blobURL +(dir ? (dir +'/') :'') + userImage : bummyImg}></img></div>
                          </div>
                        </div>

                        <span className={msg.sender === "user" ? styles.chatUpdateTimeUser : styles.chatUpdateTimeOpposite}>{msg.time}</span>

                      </div>
                    )}

                    {msg.aiResponse === 'Bot' && (
                      <>
                        <div className='d-flex'>
                          <div className={styles['ai-icon']}><img alt='' src={aichaticon}></img></div>
                          <div className={styles['ai-caht-div']} dangerouslySetInnerHTML={{ __html: msg?.aiChat }}>
                          </div>
                        </div>
                        <span className={styles['timer-text']}>{msg.time}</span>

                      </>
                    )}
                    {(isLoading && index === currentIndex) && (
                      <div className='d-flex'>
                        <div className={styles['ai-icon']}><img alt='' src={aichaticon}></img></div>
                        <div className={styles['ai-caht-div']}>
                          <img className={styles['loader-height']} src={loader} alt=''></img>
                        </div>
                      </div>
                    )}


                  </>
                ))}

              </div>
              <div className={styles['hidden']}></div>
            </div>


            {/* Input Area */}
            <div className={styles.inputArea}>
              <textarea
                disabled={isLoading}
                placeholder="Type Here..."
                className={styles.inputField}
                value={inputMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress} />
              <button disabled={isLoading} className={styles.sendButton} onClick={() => { handleSendMessage(inputMessage) }}>
                <img src={arrow} alt="Send Icon" className={styles.iconsend} />
              </button>
            </div>
            <div className={styles['hint-ai']}><p>*AI can make mistakes</p></div>
          </div>
        </div>
      )}


      <div className={styles['red-circle']}>
        {isOpenChat && (
          <img className={styles['bot-icon']} src={chatArrow} alt='' onClick={() => { openChat(false) }}></img>
        )}
        {!isOpenChat && (
          <img className={styles['bot-icon']} src={redAI} onClick={() => { openChat(true) }} alt=''></img>
        )}

      </div>
    </>
  );
};

export default AICounsellor;