import { Form, Modal } from "react-bootstrap";
import classes from "./ApplicationAuthFrom.module.css";
import corssIcon from "../../../../../Assets/Logo/crose-white.svg";
import msmAuthImg from "../../../../../Assets/Images/MSM-Auth-Form.png";
import { useState } from "react";

const ApplicationAuthFrom = (props) => {
    const currentdate=new Date().toISOString().split('T')[0]
    const [checkTersm, setCheckTersm]=useState(false)


    const getSelected=(e)=>{
      setCheckTersm(e.target.checked)
    }


  return (
    <>
      <Modal
        show={props.onShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className={classes["header-position"]}>
            <div>
              <p className={classes["heading-text"]}>MSM Authorization Form</p>
            </div>
            <img onClick={props.onClose} className={classes["cross-icon"]} src={corssIcon} alt=""></img>
          </div>
          <div className={`${classes['over-flow-form']} mt-2`}>
            <div>
              <div className={classes['header-img']}>
                <img
                  className={classes["banner-img"]}
                  src={msmAuthImg}
                  alt=""
                ></img>
              </div>

              <div className="text-center">
                <h4><u className={classes['title-style']}>MSM Data Collection and Privacy Authorization</u></h4>
              </div>

                <div className="mt-3">
                    <p className={classes['spaceCustom']}><b>MSM Application ID: {props?.applicationId}</b></p>
                    <p className={classes['spaceCustom']}><b>Student Name (the "Student"): {props?.fullName}</b></p>
                </div>
                <div className="mt-3">
                    <p className={classes['font-size-div']}>
                        I understand that M Square Business Solutions Inc., dba MSM (“MSM”) is an authorized recruitment service provider to the post-secondary educational institution which is being applied to. I understand MSM is required to comply with the rules of personal information handling and other requirements of the laws of the country(ies) relevant to the application(s), including maintaining the data in a secure condition. The information provided on the application(s) will be treated as personal information in accordance with the applicable legislation. The personal information will only be made available to institutions and MSM staff involved in processing the concerned application(s). Having said that, it is understood that MSM reserves the right at any time to disclose any personal information that is deemed necessary or required to be disclosed by applicable law, legal process, or the requirements of a government or regulatory authority. Accordingly, I hereby authorize MSM to do the following: 
                    </p>
                </div>
                <div className={classes['font-size-div']}>
                    <ul>
                        <li>
                            Collect my personal information (including name, address, DOB, place of birth, nationality, residency, email address, phone number, educational records, payment information, physical or learning disability, gender, passport details and any other information required) on a confidential basis to process and check the Students’ application for submission to the educational institution(s) I am applying for admission to, and for no other purposes. 
                        </li>
                        <li>
                            Store that information outside of my home country, in case MSM’s data storage files are located internationally.
                        </li>
                        <li>
                            Release that information as required to the educational institution(s) I am applying for admission to
                        </li>
                        <li>
                            Share my personal information with the educational institution(s) that I am applying for admission to, as required to process the application, and to facilitate payment of any fees owing to MSM.
                        </li>
                        <li>
                            Maintain a record of my file for as long as MSM is entitled to earn fees and/or for as long as MSM may contractually be obligated to maintain the file in accordance with the specific recruitment service contract MSM has entered into with the educational institution. 
                        </li>
                        <li>
                            Share personal data with third parties for the purposes of research, to perform analytics and marketing, but only in a manner that would not disclose any specifically identifiable personal information, such as my name, address, email ID, telephone number, etc., or in any other manner as that information may lead to my identification. 
                            </li>
                        <li>
                            Any profit or analysis done with the use of this Personal Information is part of MSM’s intellectual property rights.
                        </li>
                    </ul>
                </div>
                <p className={classes['font-size-div']}>
                    <b>Note:</b> If I am a student under 18 years old, I understand the importance of informing my parents and hence, they shall provide their consent (on my behalf) to all the aforementioned conditions.
                </p>
              </div>
              <div className="d-flex">
              <Form.Check onChange={(e)=>getSelected(e)} type="checkbox"></Form.Check>
              <div className={classes[['blod-text']]}>
                 I hereby agree to the Terms and Conditions specified in the MSM Data Authorization and Privacy Form and shall abide by the same.
              </div>
              </div>


              {checkTersm && (
              <div className="mt-3">
              <p className={classes['spaceCustom']}><b>Student Name: {props?.fullName}</b></p>
              <p className={classes['spaceCustom']}><b>Date: {props?.isSubmitted? props?.subDate :currentdate}</b></p>
              </div>
              )}


              <div className={`${classes['buttonSpace']} text-center mt-4 mb-3`}>
              <button onClick={props?.getConfirmation} className={classes['yes-btn']}  disabled={!checkTersm}>Continue</button>
              <button onClick={props.onClose} className={classes['cancel-btn']}>Cancel</button>
              
            </div>



            <footer className="text-center">
            <div className={`${classes['footer-div']} text-center p-3`}>
                <div>
                    <b>Global and In-Country Offices | Market Intelligence | Edtech | Business Solutions</b>
                </div>
                <div>1631 Dickson Avenue, Suite 1100, Kelowna BC V1Y 0B5 | +1 250 2626686</div>
                <div>www.msquaremedia.com</div>
            </div> 
        </footer>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ApplicationAuthFrom;
