import React, { useRef, useState, useEffect } from "react";
import sideclose from "../../../../Assets/Logo/sideclose-2.svg";
import classes from "./InstituteDetailsPart2.module.css"; 
import program_details_image from "../../../../Assets/Images/program_details_image.png";
import heartIcon from "../../../../Assets/Logo/heartIcon.svg";
import educationIcon from "../../../../Assets/Logo/graduation-cap-icon.svg";
import searchIcon from "../../../../Assets/Logo/search-icon3.svg";
import { useParams } from "react-router-dom";
import InstituteService from "../../../../Services/InstituteService";
import downArrow from "../../../../Assets/Logo/down.arrow.svg";
import ProgramSearchService from "../../../../Services/ProgramSearchServices";
import Loader from "../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";
import location from "../../../../Assets/Logo/location-icon.svg";
import Programs from "../../../Common/Programs/Programs";

function InstituteDetailsPart2() {
    const [isExpanded, setIsExpanded] = useState(false);
    const initial = useRef(null);
    const { institutionId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [institutesDetail, setInstitutesDetail] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [programsData, setProgramsData] = useState([]);
    const [visiblePrograms, setVisiblePrograms] = useState([]);
    const [loadMoreCount, setLoadMoreCount] = useState(3);

    const getInstitutionData = (id) => {
        setIsLoading(true);
        InstituteService.getInstitutionData(id)
            .then((res) => {
                setInstitutesDetail(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const getProgramListByInstAndEduLevel = (id) => {
        let data = {
            EduLevel: "",
            InstitutionId: id
        };
        setIsLoading(true);
        ProgramSearchService.getProgramListByInstAndEduLevel(data)
            .then((res) => {
                setProgramsData(res);
                setVisiblePrograms(res.slice(0, loadMoreCount)); // Initialize visible programs
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const loadMore = () => {
        setLoadMoreCount(prevCount => prevCount + 3);
        setVisiblePrograms(programsData.slice(0, loadMoreCount + 3));
    };

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            getInstitutionData(institutionId);
            getProgramListByInstAndEduLevel(institutionId);
        }
    }, [institutionId]);

    const filteredPrograms = programsData.filter((program) =>
        program?.ProgramName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={classes["main-container"]}>
            <div className={classes["header"]}>
                <span className={classes["title"]}>
                    {institutesDetail?.InstName}
                </span>
                <div className={classes["description"]}>
                    {institutesDetail?.PartnerTypeName}
                </div>
            </div>
            <hr />
            <div className={classes["content"]}>
                <div className='row'>
                    <div className='col-lg-8'>
                        <label className={classes["instution-detail1"]}>About the institution</label>
                        <div>
                            <div className={classes["para-description"]}>
                                <div className={classes["program_description"]}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: isExpanded
                                                ? institutesDetail?.InstAbout
                                                : `${institutesDetail?.InstAbout?.substring(0, 300)}...`,
                                        }}
                                    />
                                </div>
                                <a className={classes["read-more"]} onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className={classes["card-container"]}>
                            <div className=" card m-0">
                                <div className={`pb-0 ${classes["card-header"]}`}>
                                    <img className={classes["card-img-top"]}
                                        src={institutesDetail?.InstLogoPath ? bloburl + institutesDetail?.InstLogoPath : program_details_image}
                                        alt=""
                                    />
                                </div>
                                <hr />
                                <div className={`px-3 py-0 ${classes["card-body"]}`}>
                                    <div className="  mb-2">
                                        <div className={`p-2 ${classes["icon-text"]}`}>
                                            <div>
                                                <img src={location} alt="" width="15" className={`mr-3 ${classes["icon-size"]}`} />
                                            </div>
                                            <div>
                                                {institutesDetail?.InstType}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="  my-2">
                                        <div className={`p-2 ${classes["icon-text"]}`}>
                                            <div>
                                                <img src={location} alt="" width="15" className={`mr-3 ${classes["icon-size"]}`} />
                                            </div>
                                            <div>
                                                {institutesDetail?.InstFounded}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="  my-2">
                                        <div className={`p-2 ${classes["icon-text"]}`}>
                                            <div>
                                                <img src={location} alt="" width="15" className={`mr-3 ${classes["icon-size"]}`} />
                                            </div>
                                            <div>
                                                {institutesDetail?.InstAddress1}, {institutesDetail?.InstProvince} {institutesDetail?.InstCountryName}
                                            </div>
                                            <iframe
                                                style={{
                                                    boxShadow: '0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f',
                                                    width: '50%',
                                                    height: '80px'
                                                }}
                                                allowFullScreen
                                                src={`https://maps.google.com/maps?q=${institutesDetail?.InstLocationGEOcode}&ll=center=&near=&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="  my-2">
                                        <div className={`p-2 ${classes["icon-text"]}`}>
                                            <div>
                                                <img src={educationIcon} alt="" width="15" className={`mr-3 ${classes["icon-size"]}`} />
                                            </div>
                                            <div>
                                                Total programs - {institutesDetail?.TotalProgramCount}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="  my-2">
                                        <div className={`p-2 ${classes["icon-text"]}`}>
                                            <div>
                                                <img src={educationIcon} alt="" width="15" className={`mr-3 ${classes["icon-size"]}`} />
                                            </div>
                                            <div>
                                                Open programs - {institutesDetail?.OpenProgramCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mb-1">
                        <p className={`mt-5 pt-4 mb-3 ${classes["program-heading"]}`}></p>
                        <div className={classes["instituteDetails_section6"]}>
                            <div className={classes["instituteDetails_Programs_content"]}>
                                <div className={classes["program_title"]}>Programs Offered</div>
                                <div className={classes["program-cards"]}>
                                    {visiblePrograms.length > 0 ? (
                                        visiblePrograms.map((program) => (
                                            <Programs
                                                key={program.ProgramId}
                                                program={program}
                                                institutesDetail={institutesDetail}
                                            />
                                        ))
                                    ) : (
                                        <p>No programs found.</p>
                                    )}
                                </div>
                                {visiblePrograms.length < programsData.length && (
                                    <div className="text-center mt-4">
                                        <button    className={`${classes["loadmore"]}`} onClick={loadMore}>
                                            Load More
                                        <img className="px-2 mx-2" src={downArrow} alt="" width="40" height="17" />

                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="col-12 footer-fixed">
                <footer className={classes.footer}>
                    <div className="container-fluid justify-content-md-between d-m w-100 mb-3">
                        <div className="col-12 col-md-6 support-email px-0">
                            <span>Support Email: <a href="mailto:support@msmunify.com" target="_blank" className={classes["email-text"]}>support@msmunify.com</a></span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default InstituteDetailsPart2;