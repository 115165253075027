import axiosInstance from "./Interceptor";

const NotificationService = {

    getNotificationlist: async () => {
    try {
      const response = await axiosInstance.get('/UserNotification/List');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getNotificationCount: async () => {
    try {
      const response = await axiosInstance.get('UserNotification/Count');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  notificationreadUpdate: async (data) => {
    try {
      const response = await axiosInstance.post('UserNotification/ReadUpdate', data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

 
};

export default NotificationService;


