import { useRef, useState } from "react";
import { useEffect } from "react";
import FavoritesProgramServices from "../../../../Services/FavoritesProgramServices";
import classes from "./FavoritesProgram.module.css";
import Loader from "../../../GlobleShared/Loader/Loader";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import short from '../../../../Assets/Logo/sort1.svg'
import universityLogo from "../../../../Assets/Logo/dougl-icon.svg";
import calendar from '../../../../Assets/Logo/calender-icon.svg';
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../GlobleShared/CustomHooks/useLocalStorage";
import deleteIcon from "../../../../Assets/Logo/delete-icon-red.svg"
import { toast } from "react-toastify";
import noDataIcon from "../../../../Assets/Logo/no-data1.svg"
import Paginations from "../../../Common/Pagination/Paginations";
import infoIcon from "../../../../Assets/Logo/info-icon.svg"

const sortOptions = [
    { id: 2, field: 'CreatedDate', order: 'desc', label: 'Added Date (Latest to Earliest)' },
    { id: 1, field: 'CreatedDate', order: 'asc', label: 'Added Date (Earliest to Latest)' },
    { id: 3, field: 'ProgramName', order: 'asc', label: 'Program (A - Z)' },
    { id: 4, field: 'ProgramName', order: 'desc', label: 'Program (Z - A)' },
  ];

const FevoritesPrograms = () => {
const [totalPage, setTotalPage]=useState(0)
const [end, setEnd]=useState(12)
const [start, setStart]=useState(0)
const [page, setPage]=useState(1)
const [totalData,setTotalData]=useState(0)
  const initail = useRef(null);
  const [programTypeId, setProgramTypeId] = useState(0);
  const [programTypeList, setProgramTypeList] = useState([]);
  const bloburl = process.env.REACT_APP_LOGO_PATH;
  const navigate=useNavigate()
  const [userDetail] = useLocalStorage('userDetail');
  const studentId=userDetail?.refId
  const [sortId, setSortId]=useState(0)
 
  const [favoriteList, setFavoriteList] = useState([]);
const [tempFav, setTempFav]=useState(favoriteList)
  const [isLoading, setIsLoading] = useState(false);

  const getProgramType = () => {
    setIsLoading(true);
    FavoritesProgramServices.getProgramType()
      .then((res) => {
        setIsLoading(false);
        setProgramTypeList(res)
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getFavoritePrograms = (id, start, end) => {
    setSortId(0)
    setIsLoading(true);
    FavoritesProgramServices.favoriteList(id)
      .then((res) => {
        if(res){
            setTotalData(res?.length)
            setTempFav(res)
            setTotalPage(Math.ceil((res?.length)/12))
            setFavoriteList(res?.slice(start, end));
            setIsLoading(false);
        }
 
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getProgramTypeId = (value) => {
    setProgramTypeId(value);
    getFavoritePrograms(value, 0, 12);
  };

  const handleProgramClick = (programId) => {
    const programDetailUrl = `/member/programs/detailPage/${programId}`;
    window.open(programDetailUrl, '_blank');
  };

  const limitCharacters = (text, limit = 20) => {
    if (!text) return 'N/A';
    return text.length > limit ? text.substring(0, limit) + '...' : text;
};

function stripHtmlTags(str) {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handleApplyClick = (data) => {
    const programTypeId = data?.ProgramType==='On-Campus' ? 1 : 2
    navigate("/member/application/add/" + programTypeId + "?studentId=" + studentId + '&programId=' + data?.ProgramId);
};

const getApplicationFee=(value)=>{
    let fee='00.00';
    if(value?.FeeDetail.includes()!=='Application Fee'){
     return  fee
    }

}


const removeFav=(data)=>{
    let formdata={
            ProgramId:data?.ProgramId,
            SourceType: 2,
            StudentId: studentId
    }
    setIsLoading(true)
    FavoritesProgramServices.addRevove(formdata).then(res=>{
      
        getFavoritePrograms(programTypeId, start, end)
        setIsLoading(true)
        toast.success(res?.response);

    }).catch(error=>{
        setIsLoading(true)
        toast.error(error?.message)
    })

}

const getFeeDetail = (feeDetail, feeType, field) => {
    const fee = feeDetail?.find((detail) => detail.FeeType === feeType);
    return fee ? fee[field] : "00.00";
  };


  const sortArray = (array, sortBy, order) => {
    const sortedArray = [...array];
  
    return sortedArray.sort((a, b) => {
      if (sortBy === "WaiverPer") {
        return order === 'asc' ? a.WaiverPer - b.WaiverPer : b.WaiverPer - a.WaiverPer;
      } else {
        const itemA = a[sortBy]?.toString().toLowerCase() || '';
        const itemB = b[sortBy]?.toString().toLowerCase() || '';
  
        if (itemA < itemB) {
          return order === 'asc' ? -1 : 1;
        } else if (itemA > itemB) {
          return order === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }
   
  
  const searchBySort=(sort, type, order)=> {
    setPage(1)
    setStart(0)
    setEnd(12)
    setFavoriteList([])
    setSortId(sort)
    setFavoriteList(sortArray(tempFav, type, order).slice(0,12))
 
  }

  const onPageChange=(newPage)=>{
    setPage(newPage);
    const sliceData=tempFav
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if(newPage===1){
    setFavoriteList(sliceData.slice(0, 12))
    }else{
        setFavoriteList(sliceData.slice(((newPage-1) * 12), (newPage * 12)))
        setStart((newPage-1) * 12)
        setEnd(newPage * 12)
    } 
}

const getOtherFee=(fee)=>{
  let data=[]
  if(data){
    data = fee.filter(fee => fee.FeeType !== "Tuition Fee" && fee.FeeType !== "Application Fee");
    return data
  }else{
    return data
  }
 
}


  useEffect(() => {
    if (!initail.current) {
      initail.current = true;
      getFavoritePrograms(programTypeId, start, end)
      getProgramType()
    }
  });

  return (
    <>
      <div>
        <div>
          <p className={classes["heading"]}>Favorites</p>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <select
              className="form-control"
              onChange={(e) => {
                getProgramTypeId(e.target.value);
              }}
              value={programTypeId}
            >
                 <option value={0}>All</option>
                {programTypeList.map(item=>(
                    <option value={item?.Id}>{item?.Type}</option>
                ))}

            </select>
          </div>
          <div className="mt-2">
            {favoriteList?.length > 0 && <p className={classes['data-text']}>{totalData} items found</p>}
          </div>
        </div>
        {favoriteList?.length > 0 && (
            <>
        <div className={classes['icon-position']}>
        <div>
          <Dropdown>
            <Dropdown.Toggle className={`${classes['icon-hover']} drop-menu`}
              id="dropdown-basic">
              <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6667 10H32M18.6667 3.6H32M18.6667 16.4H32M7 2V18M7 18L2 13.2M7 18L12 13.2" stroke="#363636" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {sortOptions.map(item=>(
                 <Dropdown.Item className={item.id===sortId? classes['highlight']: ''} onClick={()=>{searchBySort(item.id, item.field, item.order)}}>{item?.label}</Dropdown.Item>
                ))}

            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/* <div>
          <button className={classes['delete-text']}>
          Delete All
          </button>
          
        </div> */}
       </div>

        <div>
            <div className="row mt-3">
                {favoriteList.map((program)=>(
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                        <div className={classes['program-card']}>
                        <div title="Remove" className={classes['delete-icon']}>
                                <Dropdown>
                                <Dropdown.Toggle className={`${classes['icon-hover']} drop-menu`}
                                id="dropdown-basic">
                                  <img alt='' src={deleteIcon}></img>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                    <div className="text-center"><p className={classes['confirm-text']}>Are you sure, you want remove program?</p></div>
                                    
                                    <div>
                                    <Dropdown.Item>
                                        <div className="text-center">
                                        <button title={''} className={classes['cancel']}>Cancel</button> 
                                        <button title={''} className={classes['yes']} onClick={()=>{removeFav(program)}}>Yes, Remove</button>
                                        </div>
                                    </Dropdown.Item>
                                        
                                    </div>
                                   
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                           
                            <div className={classes['img-header']}>
                                <div>
                                    <img className={classes['header-img']} alt='' src={program?.InstLogoPath ? bloburl + program?.InstLogoPath :universityLogo}></img>
                                </div>
                                <div>
                                    <p title={program?.Instname} className={classes['inst-name']}>{program?.Instname}</p>
                                    <p className={classes['inst-country']}><span>{program?.InstCountryName}</span></p>
                                </div>

                            </div>
                              <div className="d-flex justify-content-end">
                                {program?.WaiverPer>0 && (
                                    <div className={classes['applicationWav']}>
                                    <p className={classes['fee-wav']}>{program?.WaiverPer}% off on Application fee</p>
                                    </div>
                                )}
                           
                              
                              {(program?.ScholarShipMaxAmt && program?.ScholarShipMinAmt) && (
                              <div className={classes['schoreShip']}>
                              <p className={classes['fee-wav']}>Scholarships offer - {program?.ScholarShipMinAmt} - {program?.ScholarShipMaxAmt} %</p>
                              </div>
                              )}
                              </div>
    
                               
                            <div className={classes['divider-line']}>
                            </div>

                            <div className={classes['content-padding']}>
                            <div>
                                <p className={classes['duration-name']}>{program?.ProgramLevel}</p>
                                
                            </div>
                            </div>
                            <div>
                                <div className="row">
                                <div className="col-md-8 col-sm-12">
                                  <p className={classes['program-name']} title={program?.ProgramName}  onClick={() => handleProgramClick(program?.ProgramId)}> {program?.ProgramName}</p>
                                  <p className={classes['decription']} title={program?.Description} dangerouslySetInnerHTML={{ __html:   limitCharacters(stripHtmlTags(program?.Description), 200) }}></p>
                                 </div>
                                 <div className="col-md-4 col-sm-12">
                                    <div>
                                        <p><img className={classes['calender-icon']} src={calendar} alt=""></img>
                                        <span className={classes['duration-time']}>{program?.DurationTime}</span>
                                        </p>
                                        <div>
                                        <p className={classes['intake-text']}>Intake 
                                          <OverlayTrigger
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="tooltip-top">
                                              <div>
                                                {program['Intakes'].slice(0,5).map((item, index) => (
                                                  <div key={index}>
                                                    <p className={classes['amount-text']}>{item?.IntakeName}</p>
                                                  </div>
                                                ))}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <span> <img className={classes['info-icon']} alt='' src={infoIcon}></img></span>
                                          </OverlayTrigger>
                                        </p>
                                        {program?.Intakes?.length>0 && (
                                            <>
                                            {program?.Intakes.slice(0,2).map(item=>(
                                            <div className={classes['intake-name']}>{item?.IntakeName}</div>
                                         ))}
                                            </>
                                        )}
                                        {program?.Intakes?.length===0 && (
                                            <div className={classes['intake-name']}>N/A</div>
                                        )}

                                         </div>
                                        
                                        
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-3">
                                <div className="row">
                                <div className="col-md-4 col-sm-12">
                                <p className={classes['fee-heading']}>Application Fee</p>
                                        <div>
                                            <p><span className={classes['currency-name']}>{program?.ProgramCurrency}</span> <span className={classes['amount-text']}>{getFeeDetail(program?.FeeDetail,"Application Fee", "FeeAmount")}</span></p>
                                        </div>
       
                                </div>
                                <div className="col-md-4 col-sm-12">
                                <p className={classes['fee-heading']}>Tuition Fees</p>
                                <div>
                                            <p><span className={classes['currency-name']}>{program?.ProgramCurrency}</span> <span className={classes['amount-text']}>{getFeeDetail(program?.FeeDetail,"Tuition Fee", "FeeAmount")}</span></p>
                                        </div>
 
                                </div>
                                <div className="col-md-4 col-sm-12">
                                <p className={classes['fee-heading']}>Cost of Living</p>
                                    {program?.InstCostofLiving_Year &&  (
                                        <div>
                                            <p><span className={classes['currency-name']}>{program?.ProgramCurrency}</span> <span className={classes['amount-text']}>{program?.InstCostofLiving_Year}</span></p>
                                        </div>
                                    )}
                                    {!program?.InstCostofLiving_Year &&  (
                                        <div>
                                            <p><span className={classes['currency-name']}>{program?.ProgramCurrency}</span> <span className={classes['amount-text']}>00.00</span></p>
                                        </div>
                                    )}
                                    
                                </div>
                                <div className={classes['position-other']}>
                                 <>
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="tooltip-top" >
                                            <div>
                                              {program['FeeDetail'].map((item, index) => (
                                                <div key={index}>
                                                  <p className={`${classes['amount-text']} mb-0`}>{item?.FeeType}</p>
                                                  <p>
                                                    <span className={classes['currency-name']}>
                                                      {program?.ProgramCurrency}
                                                    </span>{' '}
                                                    <span className={classes['amount-text']}>{item?.FeeAmount}</span>
                                                  </p>
                                                </div>
                                              ))}
                                            </div>
                                          </Tooltip>
                                        }
                                      >

                                          <p className={classes['other-fee']}>Other Fees</p>
                                      </OverlayTrigger>
                          
                                  </>
                                </div>

                                </div>
                                
                                </div>
                                <div>
                                <div className={classes["parent-button"]}>
                                                <div> 
                                                <button className={` mr-1 ${classes["know-btn"]}`}  onClick={() => handleProgramClick(program?.ProgramId)}>Know More</button>
                                                </div>
                                                <div>
                                                <button className={` ml-1 ${classes["create-btn"]}`} onClick={() => handleApplyClick(program)}>Create Application</button>
                                                </div>
                                            </div>
                                </div>

                            </div>

                        </div>
                    </div>

                ))}
                <div>

                </div>

            </div>

        </div>
        </>
        )}
          
          {favoriteList?.length === 0 && (
      <div className={classes['no-data']}>
        <img src={noDataIcon} alt=''></img>
        <p className="mt-2"><span className={classes['red-span']}>No data </span> <span className={classes['backSpan']}>found at the moment.</span></p>
      </div>
          )}
      </div>

      {favoriteList?.length>0 &&(
                    <Paginations currentPage={page} totalPages={totalPage} onPageChange={onPageChange} />
                )}

      {isLoading && <Loader></Loader>}
    </>
  );
};

export default FevoritesPrograms;
