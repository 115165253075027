import React, { useEffect, useState } from 'react';
import classes from './ComposePost.module.css';
import media from "../../../../../Assets/Logo/media-icon.svg";
import photo from "../../../../../Assets/Logo/photo.svg";
import link from "../../../../../Assets/Logo/iconLink.svg";
import vedio from "../../../../../Assets/Logo/image-vedio-icon.svg";
import camera from "../../../../../Assets/Logo/camera.svg";
import CommonPost from '../CommonPost/CommonPost'; // Import CommonPost
import CommonServices from "../../../../../Services/CommonServices";
import { Button } from 'react-bootstrap';
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import GuestUserModal from '../../../../GlobleShared/Modal/GuestUserModal/GuestUserModal';

function ComposePost({groupId, isGroupData, groupAccessType}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMediaSelected, setIsMediaSelected] = useState(false);
  const [guestuserFlag, setGuestuserFlag] = useState(false);

  const [tagList, setTagList] = useState([]);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
    const { isLoginUser, setPostUpdated } = useBehaviorSubject();
  
  const getTagList=()=>{
    CommonServices.tagList().then(res=>{
      const response=res
      response.map(item=>{
        item['value']=item['TagId']
        item['label']=item['TagName']
      })
      setTagList(response.sort((a, b) => {
        if (a.TagName < b.TagName) return -1;
        if (a.TagName > b.TagName) return 1;
        return 0})
      )
  })
  }

  // Function to handle the input click
  const handleInputClick = () => {
    const restrictedTabs = ["ComposePost"];
    if (restrictedTabs.includes("ComposePost") && isLoginUser.isGuest) {
        setGuestuserFlag(true);
        return;
    }
    setIsModalOpen(true); // Open the modal
    setIsMediaSelected(false);
    getTagList();
  };
  useEffect(()=>{
    setSelectedType(null);
    setSelectedMediaType(null);
  },[]);
   // Function to handle media button click
   const handleMediaClick = (value,mediaType) => {
    const restrictedTabs = ["ComposePost"];
    if (restrictedTabs.includes("ComposePost") && isLoginUser.isGuest) {
        setGuestuserFlag(true);
        return;
    }
    setIsModalOpen(true); // Open the modal
    setIsMediaSelected(true); // Set media selected state
    setSelectedType(value);
    getTagList();
    setSelectedMediaType(mediaType);
    console.log(isMediaSelected);
  };


  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setIsMediaSelected(false);
    setPostUpdated(true); 
  };

  const hideGuestModalhandler = () => {
    setGuestuserFlag(false);
}

  return (
    <>
       {guestuserFlag && (
                <GuestUserModal onClose={hideGuestModalhandler} onShow={guestuserFlag} />
            )}
    <div className={classes["composePost_main_container"]}>
      <div className={classes["composePost_block"]}>
        <input
          type="text"
          placeholder="Start a Post"
          className={classes["post_input"]}
          onClick={handleInputClick}
        />
        <div className={classes["button_container"]}>
          {/* <button onClick={()=>handleMediaClick('image',1)} className={classes["post_button"]}>
            <img src={photo} alt="Photo icon" />
            <div>Photo</div>
          </button>
          <button onClick={()=>handleMediaClick('video',2) } className={classes["post_button"]}>
            <img src={video} alt="Video icon" />
            <div>Video</div>
          </button> */}
          <div className={classes["media-btn-block"]}>
            <img src={media}   style = {{height: '24px' , width: '24px'}} onClick={()=>handleMediaClick('image', 1) }alt="Photo" />
            <Button className={classes["media-button"]} onClick={() => handleMediaClick('image', 1)} variant="link">Add Photo</Button>
          </div>
          <div className={classes["media-btn-block"]}>
            <img src={vedio} style = {{height: '24px' , width: '24px'}} onClick={()=>handleMediaClick('video', 2) }alt="Video" />
            <Button className={classes["media-button"]} onClick={()=>handleMediaClick('video', 2) } variant="link">Add Video</Button>
          </div>
          {/* <button onClick={handleInputClick} className={classes["post_button"]}>
            <img src={camera} alt="Camera icon" />
            <div>Camera</div>
          </button>
          <button onClick={handleInputClick} className={classes["post_button"]}>
            <img src={link} alt="Link icon" />
            <div>Link</div>
          </button> */}
        </div>
      </div>

      {/* Render CommonPost */}
      <CommonPost isGroupData={isGroupData} isModalOpen={isModalOpen} closeModal={closeModal} isMediaSelected={isMediaSelected} setIsMediaSelected={setIsMediaSelected}
      tagList={tagList} ChildMediaTypeId={selectedMediaType} childValue={selectedType} groupId={groupId} groupAccessType={groupAccessType}/>
    </div>
    </>

  );
}

export default ComposePost;