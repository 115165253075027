import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../CustomHooks/useLocalStorage";

const BehaviorSubjectContext = createContext();

export const BehaviorSubjectProvider = ({ children }) => {
    // const [isLoginUser, setisLoginUser, logout] = useLocalStorage('user', false);
    const [isLoginUser, setisLoginUser, logout] = useLocalStorage('user', { isUser: false, isGuest: false });
    const [postUpdated, setPostUpdated] = useState(false);
    const [validationFlag, setValidationFlag ] = useState(false)
    const navigate = useNavigate();
    const [creditCardUpdate, setCreditCardUpdate]=useState(true)
    const [updateMember, setUpdateMember]=useState(true)
    const [groupType, setGroupType]=useState(false)
    const [modaratorPostFlag, setModaratorPostFlag]=useState(false)
    const [pauseFlag, setPauseFlag]=useState(false)
    const [updatePostDatata, setUpdatePostDatata]=useState(false)
    const [groupIdGlobal, setGroupIdGlobal]=useState('')
    const [memberCount, setMemberCount]=useState('')
    const [memberCountTrue, setMemberCountTrue]=useState(false)
    const [showNotification, setShowNotification]=useState(false);
    const [showMassagess, setShowMassagess]=useState(false);
     const [notifyCount, setNotifyCount]=useState(0);
     const [messageCount, setMessageCount]=useState(0);

     const [scrollToTop, setScrollToTop]=useState(false);
    
  


    // const navigate = useNavigate();

    return (
        <BehaviorSubjectContext.Provider value={{ isLoginUser, setisLoginUser, logout, postUpdated, setPostUpdated, validationFlag, setValidationFlag, setCreditCardUpdate,  creditCardUpdate, 
            updateMember, setUpdateMember, groupType, setGroupType, modaratorPostFlag, setModaratorPostFlag, pauseFlag, setPauseFlag, updatePostDatata, setUpdatePostDatata,
             groupIdGlobal, setGroupIdGlobal, memberCount, setMemberCount, setMemberCountTrue, memberCountTrue, setShowNotification, showNotification, notifyCount,setNotifyCount,
             messageCount, setMessageCount, showMassagess, setShowMassagess, scrollToTop, setScrollToTop }}>
            {children}
        </BehaviorSubjectContext.Provider>
    );
};

export const useBehaviorSubject = () => useContext(BehaviorSubjectContext);