import React from "react";
import { Route } from 'react-router-dom';
import AuthGuard from "../../GlobleShared/Guards/AuthGuard";
import Dashboard from "../Home/Dashboard/Dashboard";
import UserAccount from "../Home/User_Account/User_Account";
import DashboardPage from "../Home/Dashboard/DashboardPage/DashboardPage";
import ConnectionsComponents from "../Home/Connections/ConnectionsComponents";
import GroupsPage from "../Home/Groups/GroupsPage";
import Messages from "../Home/Messages/Messages";
import ProgramDetails from "../Home/ProgramDetails/ProgramDetails";
import ProgramDetailPage from "../Home/ProgramDetailPage/ProgramDetailPage";
import InstituteDetails from "../Home/InstituteDetails/InstituteDetails";
import InstituteDetailsPart2 from "../Home/InstituteDetails/InstituteDetailsPart2";
import SearchPage from "../Home/Search/SearchPage/SerachPage";
import CurrencyConverter from "../Home/Tools/CurrencyConverter/CurrencyConverter";
import LivingCostCalculator from "../Home/Tools/LivingCostCalculator/LivingCostCalculator";
import EducationCalculator from "../Home/Tools/EducationCalculator/EducationCalculator";
import EnglishScoreCalculator from "../Home/Tools/EnglishScoreCalculator/EnglishScoreCalculator";
import ApplicationList from "../Home/Applications/ApplicationList/ApplicationList";
import NewApplication from "../Home/Applications/NewApplication/NewApplication";
import ApplicationDetails from "../Home/Applications/ViewApplicationDetails/ApplicationDetails";
import Applications from "../Home/Applications/Applications/Applications";
import DeferralRequests from "../Home/Applications/DeferralRequest/DeferralRequest";
import RetriveDeleteApplication from "../Home/Applications/RetriveDeleteApplications/RetriveDeleteApplications";
import Refunds from "../Home/Applications/Refunds/Refunds";
import ToolsComponent from "../Home/Tools/ToolsComponent/ToolsComponent";
import ProfileSection from "../../Common/ProfileSection/ProfileSection"
import ApplicationSubmit from "../Home/Applications/ApplicationSubmit/ApplicationSubmit";
import FevoritesPrograms from "../Home/FavoritesProgram/FavoritesProgram";

// const AuthRoutes = [
//       // <Route  path="/member" element={<AuthGuard component={<Dashboard />} />}>
//       <Route  path="/member" element={<AuthGuard allowGuests={true} component={<Dashboard />} />}>
//       <Route  path="dashboard" element={<DashboardPage />} />
//       <Route  path="message" element={<Messages />} />
//       <Route  path="profile" element={<UserAccount allowGuests={false} />} />
//       <Route  path="connections" element={<ConnectionsComponents />} />
//       <Route  path="groups" element={<GroupsPage />} />
//       <Route  path="program-details" element={<ProgramDetails/>} />
//       <Route  path="profileView" element={<ProfileSection />} />

//       <Route path="institute-details/:institutionId" element={<InstituteDetailsPart2 />} />
//       {/* <Route path="institute-details/:institutionId" element={<InstituteDetails />} /> */}
//       {/* <Route path="programs/details-program/:programId" element={<ProgramDetails />} /> */}
//     <Route path="programs/details-program/:programId/:selectedCountryId/:institutionId/:selectedProgramTypeIds" element={<ProgramDetails />} />
//     <Route path="programs/detailPage/:programId" element={<ProgramDetailPage />} />
    
//       <Route path="search" element={<SearchPage />} />

//       <Route path="application"  element={<AuthGuard component={<Applications />} />}> 
//             <Route path="list" element={<ApplicationList/>}></Route>
//             <Route path="refund" element={<Refunds />}></Route>
//             <Route path="retrive-application" element={<RetriveDeleteApplication/>}></Route>
//             <Route path="deferral-requests" element={<DeferralRequests/>}></Route>
//       </Route>
//       <Route path="application/add/:programTypeId" element={<NewApplication/>}></Route>
//       <Route path="application/details/:applicationId" element={<ApplicationDetails/>}></Route>
//       <Route path="application/applicationstatus/view/:applicationId" element={<ApplicationSubmit/>}></Route>

//       <Route path="tools"  element={<AuthGuard component={<ToolsComponent />} />}> 
//           <Route path="education-calculator" element={<EducationCalculator/>}></Route>
//           <Route path="living-cost-calculator" element={<LivingCostCalculator/>}></Route>
//           <Route path="currency-converter" element={<CurrencyConverter/>}></Route>
//           <Route path="english-score-calculator" element={<EnglishScoreCalculator/>}></Route>
//       </Route>

//       <Route  path="fevorites-programs" element={<FevoritesPrograms />} />

//     </Route>

// ]
const AuthRoutes = [
  <Route
    path="/member"
    element={<AuthGuard allowGuests={true} component={<Dashboard />} />}
  >
    <Route path="dashboard" element={<DashboardPage />} />
    <Route path="search" element={<SearchPage />} />

    <Route
      path="profile"
      element={<AuthGuard allowGuests={false} component={<UserAccount />} />}
    />
    <Route
      path="connections"
      element={<AuthGuard allowGuests={false} component={<ConnectionsComponents />} />}
    />
    <Route
      path="groups"
      element={<AuthGuard allowGuests={false} component={<GroupsPage />} />}
    />
    {/* <Route
      path="program-details"
      element={<AuthGuard allowGuests={false} component={<ProgramDetails />} />}
    /> */}

    <Route
      path="application"
      element={<AuthGuard allowGuests={false} component={<Applications />} />}
    >
      <Route path="list" element={<ApplicationList />} />
      <Route path="refund" element={<Refunds />} />
      <Route path="retrive-application" element={<RetriveDeleteApplication />} />
      <Route path="deferral-requests" element={<DeferralRequests />} />
    </Route>
    <Route
      path="application/add/:programTypeId"
      element={<AuthGuard allowGuests={false} component={<NewApplication />} />}
    />
    <Route
      path="application/details/:applicationId"
      element={<AuthGuard allowGuests={false} component={<ApplicationDetails />} />}
    />
    <Route
      path="application/applicationstatus/view/:applicationId"
      element={<AuthGuard allowGuests={false} component={<ApplicationSubmit />} />}
    />

    <Route
      path="tools"
      element={<AuthGuard allowGuests={false} component={<ToolsComponent />} />}
    >
      <Route path="education-calculator" element={<EducationCalculator />} />
      <Route path="living-cost-calculator" element={<LivingCostCalculator />} />
      <Route path="currency-converter" element={<CurrencyConverter />} />
      <Route path="english-score-calculator" element={<EnglishScoreCalculator />} />
    </Route>

    <Route
      path="fevorites-programs"
      element={<AuthGuard allowGuests={false} component={<FevoritesPrograms />} />}
    />
    <Route
      path="message"
      element={<AuthGuard allowGuests={false} component={<Messages />} />}
    />
    <Route
      path="profileView"
      element={<AuthGuard allowGuests={false} component={<ProfileSection />} />}
    />
    <Route
      path="institute-details/:institutionId"
      element={<AuthGuard allowGuests={true} component={<InstituteDetailsPart2 />} />}
    />
    {/* <Route
      path="programs/details-program/:programId/:selectedCountryId/:institutionId/:selectedProgramTypeIds"
      element={<AuthGuard allowGuests={false} component={<ProgramDetails />} />}
    /> */}
    <Route
      path="programs/detailPage/:programId"
      element={<AuthGuard allowGuests={true} component={<ProgramDetailPage />} />}
    />
  </Route>,
];

export default AuthRoutes;