import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBehaviorSubject } from '../BehaviorSubject/BehaviorSubject';

// const AuthGuard = ({ component }) => {
const AuthGuard = ({ component, allowGuests = false }) => {
    const [status, setStatus] = useState(false);
    const { isLoginUser } = useBehaviorSubject();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();
    }, [component]);

    // const checkToken = async () => {
    //     try {
    //         let user = await isLoginUser;
    //         if (!user) {
    //             navigate(`/sign-in`);
    //         }
    //         setStatus(true);
    //         return;
    //     } catch (error) {
    //         navigate(`/sign-in`);
    //     }
    // }

    const checkToken = async () => {
        try {
            const user = await isLoginUser;
            if (user?.isUser || (user?.isGuest && allowGuests)) {
                setStatus(true);
            } else {
                navigate('/sign-in');
            }
        } catch (error) {
            navigate('/sign-in');
        }
    };

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard;