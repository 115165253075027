import React, { useEffect, useState } from "react";
import GroupsHeader from "../GroupsHeader/GroupsHeader";
import GroupsTabs from "../GroupsTabs_Info_panel/GroupsTabs_Info_Panel";
import Posts from "../../../../Common/Posts/Posts";
import ComposePost from "../ComposePost/ComposePost";
import About from "../About/About";
import classes from "./GroupsDashboard.module.css";
import backgroundImage from "../../../../../Assets/Images/groups_main_background_image.png";
import GroupMembers from "../GroupsMembers/GroupMembers";
import GroupService from "../../../../../Services/GroupService";
import { toast } from "react-toastify";
import GroupDiscussions from "../GroupDiscussions/GroupDiscussions";
import Loader from "../../../../GlobleShared/Loader/Loader";
import groups_image from "../../.../../../../../Assets/Images/groups_image.png";
import InviteModal from "../../../../GlobleShared/Modal/InviteModal/InviteModal";
import PendingGroups from "../GroupsMembers/PendingGroups/PendingGroups";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

function GroupsDashboard({postDashboardData,handleComponentToggle}) {
  const [tab, setTab] = useState("Post");
  const [members, setMembers] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupPostFlag, setGroupPostFlag] = useState(true);
  const [invitationModal, setInvitationModal] = useState(false);
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
  const {groupType, setGroupType, modaratorPostFlag, setModaratorPostFlag, pauseFlag, groupIdGlobal}=useBehaviorSubject();
  const [moderatorPostList, setModeratorPostList] = useState([]);
  const [moderatorFlag, setModeratorFlag] = useState(false);
  const [inviteFlag, setInviteFlag] = useState(true);
  

  //here we are using temperary data but we use data comes from api.
  const groupDetails = {
    groupAccess: "Private",
    image: { backgroundImage },
    membersCount: 14,
  };
  const fetchDiscussions = () => {
    setIsLoading(true);
   GroupService.getUserGroupDiscussion(postDashboardData?.GroupID)
     .then((res) => {
       setDiscussions(res)
        setIsLoading(false);
        toast.success(res?.message);
     })
     .catch((error) => {
        setIsLoading(false);
       toast.error('Failed to get Discussion. Please try again.');
     });
 };
      useEffect(()=>{
          setTab("Post")
      },[handleComponentToggle]);
//  const fetchMembers = () => {
//   setIsLoading(true);
//  GroupService.getUserGroupMember(postDashboardData?.GroupID)
//    .then((res) => {
//      setMembers(res)
//      console.log()
//       setIsLoading(false);
//       toast.success(res?.message);
//    })
//    .catch((error) => {
//       setIsLoading(false);
//      toast.error('Failed to get Member. Please try again.');
//      console.log(error);
//    });
// };
  useEffect(() => {
    if (postDashboardData?.GroupID) {
      // if(tab === "Members"){
      // fetchMembers();}
      if (tab === "Discussion") {
        fetchDiscussions();
      }

      if (tab === "Moderator") {
        setModeratorFlag(true);
        getModeratorData(postDashboardData?.GroupID);
      }
    }
  }, [postDashboardData?.GroupID, tab]);

  const handleInvite = () =>{
    setInvitationModal(true)
  }
 
  const handleInviteClose = () =>{
    setInvitationModal(false)
  }

  const getModeratorData = (groupId) => {
    setIsLoading(true);
    GroupService.getModeratorList(groupId)
      .then((res) => {
        setModeratorPostList(res.ReportedPost);
        setDiscussions(res?.ReportedDiscussion)
        console.log(res.ReportedPost);
        setIsLoading(false);
        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  useEffect(()=>{
    if(modaratorPostFlag || groupIdGlobal){
      setModaratorPostFlag(false)
      getModeratorData(postDashboardData?.GroupID)
    }

  }, [modaratorPostFlag, groupIdGlobal])
   return (
    <>{postDashboardData?.GroupID?(
    <div>
      {invitationModal && 
      <InviteModal
        rowData={postDashboardData}
        onShow={invitationModal}
        onClose={handleInviteClose}
        inviteFlag={inviteFlag}
      />
      }
      <div className={`${classes["groups_dashboard_sections"]} `}>
        <GroupsHeader groupData={postDashboardData} image={ postDashboardData?.GroupImage?blobUrl+(dir ? (dir +'/') :'')+ postDashboardData?.GroupImage:groups_image} toggleComponent={handleComponentToggle}/>
        <GroupsTabs
          tab={tab}
          setTab={setTab}
          name={postDashboardData?.GroupName}
          access={postDashboardData?.GroupType==="1"?"Public":postDashboardData?.GroupType==="2"?"Private":"Exclusive"}
          toggleComponent={handleComponentToggle}
          members={postDashboardData?.MembersCount}
          groupData={postDashboardData}
          handleInvite={handleInvite}
        />
        <div className={classes["compose-post-container"]}>
          <div>


          {tab === "Post" &&  (postDashboardData?.UserGroupStatusId !== '3') && (postDashboardData?.UserGroupStatusId !== '12')   && (
            <div className={classes["compose-posts"]}>
              {!groupType &&!pauseFlag && (
                <ComposePost isGroupData={true}  groupId={groupIdGlobal} 
                groupAccessType={postDashboardData?.GroupType === "1" ? 1: 3}
                />
              )}
             
              <Posts groupData={postDashboardData}  groupId={postDashboardData?.GroupID} groupPostFlag={groupPostFlag}/>
            </div>
          )}


          {(tab === "Discussion") &&  (postDashboardData?.UserGroupStatusId !== '3')  && (postDashboardData?.UserGroupStatusId !== '12')  && (
            <GroupDiscussions discussions={discussions} postDashboardData={postDashboardData}  onAddDiscussion={fetchDiscussions}/>)}


          {tab === "Members" &&  (postDashboardData?.UserGroupStatusId !== '3')  && (postDashboardData?.UserGroupStatusId !== '12')  && (
            <div className="row">
              <div  className=" col-md-12 col-lg-12 p-0">
                {(!groupType && !pauseFlag && postDashboardData?.GroupID && (postDashboardData?.UserGroupRole==="1" || postDashboardData?.UserGroupRole==="2")) && (
                <PendingGroups groupId={postDashboardData?.GroupID}></PendingGroups>
                )}
               
                <GroupMembers groupData={postDashboardData} members={postDashboardData?.GroupID} />
              </div>
              <div className="col-md-12 col-lg-5 p-0"></div>
            </div>
          )}

               {tab === "Moderator" && (
                 <div className={classes["compose-posts"]}>
                   <Posts moderatorPostdata={moderatorPostList} moderatorPostFlag={moderatorFlag}  groupPostFlag={groupPostFlag}/>
                   {discussions && discussions?.length>0 && (
                   <GroupDiscussions moderatorPostFlag={moderatorFlag} discussions={discussions} postDashboardData={postDashboardData}  onAddDiscussion={fetchDiscussions}/>
                   )}
                  
                 </div>

               )}
          <div>
       {isLoading && (
          <Loader></Loader>
        )}
    </div>
          </div>

          <div className={classes["about-container"]}>
          <About groupData={postDashboardData} description={postDashboardData?.GroupDesc} toggleComponent={handleComponentToggle}/>
        </div>
        </div>
      </div>
    </div>
    ):(  <div className={`${classes["groups_dashboard_sections"]} ${classes.noData}`}>
      <p className="text-center"><svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="36" cy="36" r="36" fill="white"/>
<circle cx="36" cy="36" r="35" stroke="#363636" stroke-opacity="0.5" stroke-width="2"/>
<path d="M41.3498 16.6667L40.1675 44.0381H31.5961L30.4138 16.6667H41.3498ZM36.0296 58.6667C34.2562 58.6667 32.798 58.1714 31.6552 57.1809C30.5517 56.1524 30 54.8952 30 53.4095C30 51.8857 30.5517 50.6095 31.6552 49.5809C32.798 48.5524 34.2562 48.0381 36.0296 48.0381C37.7635 48.0381 39.1823 48.5524 40.2857 49.5809C41.4286 50.6095 42 51.8857 42 53.4095C42 54.8952 41.4286 56.1524 40.2857 57.1809C39.1823 58.1714 37.7635 58.6667 36.0296 58.6667Z" fill="#E4151B"/>
</svg>
<p>No Data Found</p>
</p>

    </div>)}
    </>
  );
}

export default GroupsDashboard;
