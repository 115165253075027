import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import classes from "./PostImagesModal.module.css";
import corssIcon from "../../../../Assets/Logo/red-cross-icon.svg";
import ViewMediaModal from "../ViewMediaModal/ViewMediaModal"; // Adjust the import path as needed

function PostImagesModal({ onShow, onClose, data }) {
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
  const [mediaTypeId, setMediaTypeId] = useState(null);
  const blobURL=process.env.REACT_APP_URL_PATH
  const dir=process.env.REACT_APP_BLOB_DIR_STUDENT

  const handleMediaClick = (url, typeId) => {
    setMediaUrl(url);
    setMediaTypeId(typeId);
    setShowMediaModal(true);
  };

  return (
    <div>
      <Modal
        show={onShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={classes['modal-custom']} // Apply the custom class here
      >
        <Modal.Body>
          <div className={classes["cross-icon-position"]}>
            <img
              title="Close"
              className={classes["corss-icon"]}
              src={corssIcon}
              alt=""
              onClick={onClose}
            ></img>
          </div>
          <div className={classes["modal-body-data"]}>
            <div className={classes["post-image"]}>
              <div className={classes["image-card"]}>
                {data.map((item) => (
                  <div key={item.postMediaId}>
                    {item.mediaTypeId === 1 && (
                      <img
                        src={blobURL+(dir ? (dir +'/') :'')+item.mediaUrl}
                        alt=""
                        className={classes["image"]}
                        onClick={() => handleMediaClick(blobURL+(dir ? (dir +'/') :'')+item.mediaUrl, item.mediaTypeId)}
                      />
                    )}
                    {item.mediaTypeId === 2 && (
                      <video
                        controls
                        width="100%"
                        src={blobURL+(dir ? (dir +'/') :'')+item.mediaUrl}
                        className={classes["image"]}
                        onClick={() => handleMediaClick(blobURL+(dir ? (dir +'/') :'')+item.mediaUrl, item.mediaTypeId)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Render ViewMediaModal */}
      <ViewMediaModal
        show={showMediaModal}
        handleClose={() => setShowMediaModal(false)}
        mediaUrl={mediaUrl}
        mediaTypeId={mediaTypeId}
      />
    </div>
  );
}

export default PostImagesModal;