import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import classes from "./AddEducationModal.module.css";
import SearchModal from "../../../../Search/SearchModal/SearchModal";
import corssIcon from "../../../../../../../Assets/Logo/cross-icon.svg";
import corssIconRed from "../../../../../../../Assets/Logo/crossred.svg";
import deleteIcon from "../../../../../../../Assets/Logo/delete-icon-red.svg";
import eduEdit from "../../../../../../../Assets/Logo/eduEdit.svg"
import eduDelete from "../../../../../../../Assets/Logo/eduDelete.svg"
import ConfirmationModal from "../../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";

import ApplicationService from "../../../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get, set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import Loader from "../../../../../../GlobleShared/Loader/Loader";

function AddEducationModal({addMoreEducationPopup, updateFlag, handleClose, selectedSchoolData, refreshSchoolList}) {
  const initial = useRef(null);
  const [provinceList, setProvinceList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [streamList, setStreamList] = useState([]);

  const [degreeList, setDegreeList] = useState([]);

  const [educationLevelListbyApllication, setEducationLevelListbyApllication] =
    useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolData,setSchoolData]=useState()
  const [isLoading, setIsLoading] = useState(false);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const [grdSchemeData, setGrdSchemeData] = useState([]);
  
  const [nextId, setNextId] = useState(1);





  const [gradeSchemeDDL, setGradeSchemeDDL] = useState([]);
//   const [addMoreEducationPopup, setAddMoreEducationPopup] = useState(false);
  const [dropDownGradingSchemeSelected, setDropDownGradingSchemeSelected] = useState(false);
  const [boardOfEducation, setBoardOfEducation] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const GradeDropdownValuesArray = [1,2,14,19,21,22,25];
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false);
  const [schoolId, setSchoolId]=useState(0);


  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      addEducationDetail();
      if(updateFlag){
        getSchoolToUpdate(selectedSchoolData);
      }
    }
  }, [updateFlag]);


  const addEducationDetail = () => {
    getCountryList();
    // getEduLevelList(); need to implemen
    getEduLevelForDDLByApplication(studentId);
    getSubjectList();
    getLanguageList();
    getDegreeList();
    getStreamList();
  };

  const getCountryList = () => {
    setIsLoading(true);
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false);
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getProvinceList = (value) => {
    setIsLoading(true);
    ApplicationService.getProvince(value)
      .then((res) => {
        setIsLoading(false);
        setProvinceList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getCityList = (value) => {
    setIsLoading(false);
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false);
        setCityList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getLanguageList = () => {
    setIsLoading(true);
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false);
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getSubjectList = () => {
    setIsLoading(true);
    ApplicationService.getSubjectList()
      .then((res) => {
        setIsLoading(false);
        setSubjectList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getDegreeList = () => {
    setIsLoading(true);
    ApplicationService.getDegreeList()
      .then((res) => {
        setIsLoading(false);
        setDegreeList(res);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getStreamList = () => {
    setIsLoading(true);
    ApplicationService.getStreamList()
      .then((res) => {
        setIsLoading(false);
        setStreamList(res);
        const tepList=res;
        if(selectedSchoolData?.StreamName){
    
          tepList.filter(item=>{
            if(item.StreamName===selectedSchoolData?.StreamName){
              setEducationDetails((pre=>({...pre, StreamId: item.StreamId})))
            }
          })
        }


      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };
  const getEduLevelForDDLByApplication = (studentId) => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
      SchoolId: selectedSchoolData.SchoolId ? selectedSchoolData.SchoolId : 0,
    };
    setIsLoading(true);
    ApplicationService.getEduLevelForDDLByApplication(data)
      .then((res) => {
        setIsLoading(false);
        setEducationLevelListbyApllication(res);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };



  const onEducationCountryChange = (value) => {
    if (value != null) {
    setGrdSchemeData([
    ]) 
    }// remove this and 
    ApplicationService.getGradingSchemeAsync(value).then((res)=>{
        setGrdSchemeData(res);
      }).catch((error)=>{
       
      })
}

const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setSchoolList(res);
      })
      .catch((error) => {
      });
  };
  
  const getSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.getSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        setSchoolData(res);
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };

  const deleteSchoolDetail = (SchoolId) => {
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        toast.success(res?.message);
        getSchoolDetailList();
      })
      .catch((error) => {
      });
  };

  // const addSchoolDetail = (data) => {
  //   setIsLoading(true)
  //   ApplicationService.addSchoolDetail(data)
  //     .then((res) => {
  //       setIsLoading(false)
  //       // toast.success(res?.message);
  //       toast.success("School Added successfully!");
  //       refreshSchoolList();
  //     })
  //     .catch((error) => {
  //       setIsLoading(false)
  //       console.log(error);
  //     });
  // };


  // const updateSchoolDetail = (data) => {
  //   setIsLoading(true)
  //   ApplicationService.updateSchoolDetail(data)
  //     .then((res) => {
  //       setIsLoading(false)
  //       // toast.success(res?.message);
  //       toast.success("School updated successfully!");
  //       refreshSchoolList();
  //     })
  //     .catch((error) => {
  //       setIsLoading(false)
  //       console.log(error);
  //     });
  // };



 



  const onGradeChange = (gradingSchemeID) => {
    // eduInfo.GradeAverage = "";
    // Reset GradeAverage correctly
    if (GradeDropdownValuesArray.includes(Number(gradingSchemeID))) {
      setDropDownGradingSchemeSelected(true);
    }
    else{
      setDropDownGradingSchemeSelected(false);
      return;
    }
    const selectedGrade = grdSchemeData.find((d) => d.GrdSchemeId === Number(gradingSchemeID)) || null;

    if (selectedGrade) {
      setGradingScheme(selectedGrade);
    }

    
  // Clear MinMarks and MaxMarks errors if GradeId is not None
  if (gradingSchemeID !== "0") {
    setFormSubjectErrors((prevErrors) => ({
      ...prevErrors,
      MinMarks: '',
      MaxMarks: '',
    }));
  }
  };

  const setGradingScheme = (selectedGrade) => {
    if (selectedGrade) {
      setDropDownGradingSchemeSelected(true);
      // const hints = selectedGrade.InputHint ? selectedGrade.InputHint.replaceAll(" ", "").split(",") : [];
        const hints = selectedGrade['InputHint'].replaceAll(" ","").split(",");
      setGradeSchemeDDL(hints);
    } else {
      setDropDownGradingSchemeSelected(false);
    }
  };



  const handleEducationCountryChange = (e) => {
    // eduInfo.GradingScheme = "";
    // eduInfo.GradeAverage = "";
    onEducationCountryChange(e.target.value);
  };

  //********************** */




// *******************************************************
  // for popup add more education detail

  const addMoreEducation = () => {
    // addMoreEducationDetail();  // for update add data we called multiple apis
    // setAddMoreEducationPopup(true);
    setUpdateShow(false);
  };

  const [formErrors, setFormErrors] = useState({
    LevelOfEducation: null,
    CountryOfInstitution: null,
    Province: null,
    Marks: null,
  });
  

  //to store and save data while adding more educatoin detail
  const [educationDetails, setEducationDetails] = useState({
    SchoolId: 0,
    ParentId: studentId, // set according to your parentId
    ParentType: 6, // set according to your parentType
    LevelOfEducation: '',
    LevelOfEducationName: '',
    CountryOfInstitution: '',
    CountryOfInstitutionName: '',
    NameOfInstitution: '',
    Language: '',
    AttendendFrom: '',
    AttendendTo: '',
    Degree: '',
    DegreeAwardedOn: '',
    Addres: '',
    City: '',
    Province: '',
    Pincode: '',
    Marks: [],
    StreamId: 0,
    institutionId: '',
    ExamDocUrl: '',
  });


//   const formatDate = (dateString) => {
//     const object = new Date(dateString); // Convert to Date or return null if empty
// };

  const handleInfoAddEducationChange = (e) => {
    const { name, value } = e.target;
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
     // Validate the field
  const errorMsg = validateEducationField(name, value);
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [name]: errorMsg,
  }));
  };
  const calculateMinDegreeAwardedDate = () => {
  if(educationDetails.AttendendTo){
     const endDate = new Date(educationDetails.AttendendTo);
    endDate.setDate(endDate.getDate() + 2);
    return endDate.toISOString().split('T')[0]; }
  };
  const validateEducationField = (name, value) => {
    let errorMsg = null;
  
    switch (name) {
      case "LevelOfEducation":
        if (!value) {
          errorMsg = "Please select a level of education";
        }
        break;
  
      case "CountryOfInstitution":
        if (!value) {
          errorMsg = "Please select a country of the institution";
        }
        break;
  
      case "Province":
        if (!value) {
          errorMsg = "Province select a province";
        }
        break;

      case "Marks":
      if (!value || value.length === 0) {
        errorMsg = "Please add at least one subject";
      }
      break;
      case "AttendendFrom":
        if (educationDetails.AttendendTo && new Date(value) > new Date(educationDetails.AttendendTo)) {
          errorMsg = "Enter valid date";
        }
        break;
  
      case "AttendendTo":
        if (educationDetails.AttendendFrom && new Date(value) < new Date(educationDetails.AttendendFrom)) {
          errorMsg = "Enter valid date";
        }
        break;
      default:
        break;
    }
  
    return errorMsg;
  };

  const validateEducationForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const errorMsg = validateEducationField(key, form[key]);
      if (errorMsg) errorObj[key] = errorMsg;
    });
    return errorObj;
  };
  
  

// on change of  country of institution
  const handleCountryInstitutionChange = (e) => {
    educationDetails.Province="";
    educationDetails.City="";
    formData.Subject="";
    formData.GradeId="";
    getProvinceList(e.target.value);
    onEducationCountryChange(e.target.value);
    // setCountryInstitution(e.target.value);
  };
  const handleProvinceChange = (e) => {
    educationDetails.City="";
    getCityList(e.target.value);
    // setCountryInstitution(e.target.value);
  };

  const getEduLevelName = (eduLevelId) => {

    if (educationLevelListbyApllication && educationLevelListbyApllication.length > 0) {
      const selectedLevel = educationLevelListbyApllication.find(c => c.EduLevelId === Number(eduLevelId));
      
      if (selectedLevel) {
        const levelName = selectedLevel.EduLevelName;
        setEducationDetails((prevDetails) => ({
          ...prevDetails,
          LevelOfEducationName: levelName,
          Addres:''
        }));

        // Check if LevelOfEducationName includes 'grade' or 'diploma'
        const isBoardOfEducation = levelName.toLowerCase().includes('grade') ||
                                   levelName.toLowerCase().includes('diploma');
        setBoardOfEducation(isBoardOfEducation);
      }
    }
  };

  // *****************//

  // ****************************************************************************************
  const [formSubjectErrors, setFormSubjectErrors] = useState({
    SubjectId: null,
    GradeId: null,
    MaxMarks: null,
    MinMarks: null,
    ObtainMarks: null,
  });
  
  const validateSubjectField = (name, value) => {
    let errorMsg = null;

    if (formData.GradeId > 0 && (name === 'MinMarks' || name === 'MaxMarks')) {
      return errorMsg; // Return null, meaning no error
    }

    const selectedGrade = grdSchemeData.find((d) => d.GrdSchemeId === Number(formData.GradeId)) || null;
  
    switch (name) {
      case "SubjectId":
        if (!value) {
          errorMsg = "Please select a subject";
        }
        break;
  
      case "GradeId":
        if (!value) {
          errorMsg = "Please select a grade";
        }
        break;
  
       case "MaxMarks":
      if (!value) {
        errorMsg = "Please select Maximum marks";
      } else if (Number(value) <= 0) {
        errorMsg = "Maximum marks must be greater than 0";
      } else if (formData.MinMarks && Number(value) < Number(formData.MinMarks)) {
        errorMsg = "Maximum marks must be greater than or equal to minimum marks";
      }
      break;

    case "MinMarks":
      if (!value) {
        errorMsg = "Please select Minimum marks";
      } else if (Number(value) <= 0) {
        errorMsg = "Minimum marks must be greater than 0";
      } else if (formData.MaxMarks && Number(value) > Number(formData.MaxMarks)) {
        errorMsg = "Minimum marks cannot be greater than maximum marks";
      }
      break;

    case "ObtainMarks":
      if (!value) {
        errorMsg = "Please select Obtained marks";
      } else if (Number(value) < 0) {
        errorMsg = "Obtained marks cannot be negative";
      } else if (formData.MaxMarks && Number(value) > Number(formData.MaxMarks)) {
        errorMsg = "Obtained marks cannot exceed maximum marks";
      } else if (formData.MinMarks && Number(value) < Number(formData.MinMarks)) {
        errorMsg = "Obtained marks cannot be less than minimum marks";
      }
      else if (selectedGrade && selectedGrade.InputPattern) {
        const inputPattern = new RegExp(selectedGrade.InputPattern);
        if (!inputPattern.test(value)) {
          errorMsg = `Obtained marks must follow the pattern: ${selectedGrade.InputHint}`;
        }
      }
      break;
  
      default:
        break;
    }
  
    return errorMsg;
  };

  const validateSubjectForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const errorMsg = validateSubjectField(key, form[key]);
      if (errorMsg) errorObj[key] = errorMsg;
    });
    return errorObj;
  };
  
  


  const [formData, setFormData] = useState({
    SubjectId: 0, // You might want to set this based on your logic
    SubjectName: "",
    MaxMarks: "",
    MinMarks: "",
    ObtainMarks: "",
    GradeId: 0,
    GradeName: "",
  });
  
  const [rows, setRows] = useState([]);
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    // set each field error
    const errorMsg = validateSubjectField(name, value);
    setFormSubjectErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));

    if (name === 'SubjectId') {
        if (subjectList && subjectList.length > 0) {
            const selectedSubject = subjectList.find(item => item.SbjId === Number(value));
            
            if (selectedSubject) {
                const SubjectName = selectedSubject.SbjName;
                
                // Update formData with the selected SubjectName
                setFormData(prevData => ({
                    ...prevData,
                    SubjectName: SubjectName,  // Add or update the SubjectName
                }));
            }
        }
    }

    if (name === 'GradeId') {
      formData.MaxMarks="";
      formData.MinMarks="";
      formData.ObtainMarks="";
        onGradeChange(value); 
        
            // Clear MinMarks and MaxMarks errors if GradeId is not None
    if (value !== "0") {
      setFormSubjectErrors((prevErrors) => ({
        ...prevErrors,
        MinMarks: '',
        MaxMarks: '',
      }));
    }
  
    }

    if (formData.GradeId > 0 ) {
      setFormSubjectErrors((prevErrors) => ({
        ...prevErrors,
        MinMarks: '',
        MaxMarks: '',
      }));
    }
};
  
  const handleAddRow = () => {

    const subjectErrors = validateSubjectForm(formData);
     setFormSubjectErrors(subjectErrors);

     if (Object.keys(subjectErrors).length > 0) {
      return;
    }
  

    if (formData.SubjectId && formData.GradeId) {
      const updatedRows = rows.filter(row => row.SubjectId !== formData.SubjectId);
      setRows(updatedRows);
    
      setEducationDetails((prevDetails) => ({
        ...prevDetails,
        Marks: prevDetails.Marks.filter(mark => mark.SubjectId !== formData.SubjectId),
      }));
      const newMark = {
        SubjectId: formData.SubjectId,
        SubjectName: formData.SubjectName,
        MaxMarks: formData.MaxMarks,
        MinMarks: formData.MinMarks,
        ObtainMarks: formData.ObtainMarks,
        GradeId: formData.GradeId,
        GradeName: grdSchemeData.find((d) => d.GrdSchemeId === Number(formData.GradeId))?.GrdSchemeName || "",
      };
  
      // Update rows state
      setRows([...updatedRows, newMark]);
  
      // Update educationDetails state to include new mark
      setEducationDetails((prevDetails) => ({
        ...prevDetails,
        Marks: [...(prevDetails.Marks || []), newMark],
      }));
  
      // Reset formData state
      setFormData({
        SubjectId: 0,
        SubjectName: "",
        MaxMarks: "",
        MinMarks: "",
        ObtainMarks: "",
        GradeId: "",
        GradeName: "",
      });
    }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Marks: "", // Clear the error for Marks
      }));

    setFormSubjectErrors({});
    // setNextId((prevId) => prevId + 1);
  };
  
  const handleRemoveRow = (subjectId) => {
    // Remove the row based on SubjectId
    const updatedRows = rows.filter(row => row.SubjectId !== subjectId);
    setRows(updatedRows);
  
    // Remove the mark from educationDetails based on SubjectId
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      Marks: prevDetails.Marks.filter(mark => mark.SubjectId !== subjectId),
    }));
  };



  
  

  
  // update and get school and add school
  // *******************************************************
  

 //add school for submitting data
 const addSchoolData=(e)=>{
  e.preventDefault();
  
  // Validate entire form
  const errors = validateEducationForm(educationDetails);
  setFormErrors(errors);
  
  // Proceed if no errors
  if (Object.keys(errors).length > 0) {
    return;
  }

    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentId, // Use current or default value
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || '',
      LevelOfEducationName: educationDetails.LevelOfEducationName || '',
      CountryOfInstitution: educationDetails.CountryOfInstitution || '',
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || '',
      NameOfInstitution: educationDetails.NameOfInstitution || '',
      Language: educationDetails.Language || '',
      AttendendFrom: educationDetails.AttendendFrom?educationDetails.AttendendFrom: '',
      AttendendTo: educationDetails.AttendendTo?educationDetails.AttendendTo : '',
      Degree: educationDetails.Degree || '',
      DegreeAwardedOn: educationDetails.DegreeAwardedOn?educationDetails.DegreeAwardedOn : '',
      Addres: educationDetails.Addres || '',
      City: educationDetails.City || '',
      Province: educationDetails.Province || '',
      Pincode: educationDetails.Pincode || '',
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [], // Use existing marks or empty array
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || '',
      ExamDocUrl:educationDetails.ExamDocUrl||'',
      
    };
   
  
      setIsLoading(true)
      ApplicationService.addSchoolDetail(data)
        .then((res) => {
          setIsLoading(false)
          // toast.success(res?.message);
          toast.success("Education details have been added successfully!");
          refreshSchoolList();
          setEducationDetails("");
          setRows([]);
          // setAddMoreEducationPopup(false);
          handleClose();
        })
        .catch((error) => {
          toast.error("Failed to add education details. Please try again.");
          setIsLoading(false)
        });
   }
  
  
  
  
  
   //****************************get school to update data and open modal
   const getSchoolToUpdate = (data) => {
    // getSchoolDetail(schoolId); 

    //addEducationDetail(); // for update school data we called multiple apis
    getProvinceList(data.CountryOfInstitution);
    getCityList(data.Province);
    onEducationCountryChange(data.CountryOfInstitution);
    const isBoardOfEducation = data?.LevelOfEducationName.toLowerCase().includes('grade') ||
     data?.LevelOfEducationName.toLowerCase().includes('diploma');
     setBoardOfEducation(isBoardOfEducation);
  
    setEducationDetails(prevDetails => ({
      ...prevDetails, // Keep existing values
      SchoolId: data.SchoolId,
      LevelOfEducation: data.LevelOfEducation,
      LevelOfEducationName: data.LevelOfEducationName,
      CountryOfInstitution: data.CountryOfInstitution,
      CountryOfInstitutionName: data.CountryOfInstitutionName,
      NameOfInstitution: data.NameOfInstitution,
      Language: data.Language,
      AttendendFrom: data.AttendendFrom? new Date(data.AttendendFrom).toISOString().split('T')[0] : '', // 
      AttendendTo: data.AttendendTo ? new Date(data.AttendendTo).toISOString().split('T')[0]:'', // 
      Degree: data.Degree,
      DegreeAwardedOn: data.DegreeAwardedOn ? new Date(data.DegreeAwardedOn).toISOString().split('T')[0]:'', // Convert if needed
      Addres: data.Addres,
      City: data.City,
      Province: data.Province,
      Pincode: data.Pincode,
      Marks: data.Marks, 
      StreamId: data.StreamName, 
      institutionId: prevDetails.institutionId, 
      ExamDocUrl:data.ExamDocUrl,
  }));

    const marksForRows = data.Marks.map(mark => ({
      SubjectId: mark.SubjectId,
      SubjectName: mark.SubjectName,
      MaxMarks: mark.MaxMarks,
      MinMarks: mark.MinMarks,
      ObtainMarks: mark.ObtainMarks,
      GradeId: mark.GradeId,
      GradeName: mark.GradeName,
    }));
  
    setRows(marksForRows);
    // setAddMoreEducationPopup(true);
    setUpdateShow(true);
  }
  
  
  
  //******************** */ implement this update school data must pass data
  const updateSchoolData= (e)=>{
    e.preventDefault();
  
    // Validate entire form
    const errors = validateEducationForm(educationDetails);
    setFormErrors(errors);
    
    // Proceed if no errors
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentId, // Use current or default value
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || '',
      LevelOfEducationName: educationDetails.LevelOfEducationName || '',
      CountryOfInstitution: educationDetails.CountryOfInstitution || '',
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || '',
      NameOfInstitution: educationDetails.NameOfInstitution || '',
      Language: educationDetails.Language || '',
      AttendendFrom: educationDetails.AttendendFrom ? educationDetails.AttendendFrom: '',
      AttendendTo: educationDetails.AttendendTo ? educationDetails.AttendendTo : '',
      Degree: educationDetails.Degree || '',
      DegreeAwardedOn: educationDetails.DegreeAwardedOn ? educationDetails.DegreeAwardedOn: '',
      Addres: educationDetails.Addres || '',
      City: educationDetails.City || '',
      Province: educationDetails.Province || '',
      Pincode: educationDetails.Pincode || '',
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [], // Use existing marks or empty array
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || '',
      ExamDocUrl:educationDetails.ExamDocUrl||'',
    };

      setIsLoading(true)
      ApplicationService.updateSchoolDetail(data)
        .then((res) => {
          setIsLoading(false)
          // toast.success(res?.message);
          toast.success("Education details have been updated successfully!");
          refreshSchoolList();
          setEducationDetails("");
          setRows([]);
          // setAddMoreEducationPopup(false);
          handleClose();
        })
        .catch((error) => {
          toast.error("Failed to update education details. Please try again.");
          setIsLoading(false)
        });
  }
  
   const showConfirmModalhandler = (id) => {
    setSchoolId(id);
    setConfirmationRepostModal(true);
  };
  const hideConfirmModalhandler = () => {
    setConfirmationRepostModal(false);
  };
  
  


  const handleClosePopup = () => {
    handleClose();
    // setAddMoreEducationPopup(false);
    setRows([]);
    setEducationDetails("")
    // setCountryInstitution("");
    // setEducationLevel("");
  };

  
  return (
    <>
  <div>
    <SearchModal onShow={addMoreEducationPopup} onHide={handleClose} size="xl">
          <div className={classes.ModelContainer}>
            <div className={`${classes.headerContainer}`}>
              <div className={classes.title}>
                <span>{updateShow ? 'Edit Education Details': 'Add Education Details'}</span>
              </div>
              <div>
                <img
                  title="Remove"
                  className={classes.corssIcon}
                  src={corssIconRed}
                  alt=""
                  onClick={handleClosePopup}
                />
              </div>
            </div>
          </div>

          <div className={classes.mainContainer}>
            <div className="row">
              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="educationLevel">
                  <label className={`${classes["forLabel"]} m-2`}>Level of Education
                    {/* <span className={classes["mandotory"]}>*</span> */}
                    </label>
                  <Form.Select
  name="LevelOfEducation"
  aria-label="Level of Education"
  value={educationDetails.LevelOfEducation}
  onChange={(e) => {
    handleInfoAddEducationChange(e);
    getEduLevelName(e.target.value);
  }}
  onBlur={handleInfoAddEducationChange}
  className={`${classes["form-select"]} ${classes["select-truncate"]}`}
  style={{ paddingRight: "20px" }} // Inline CSS for padding-right
>
  <option className={classes["option-default"]} value="">Select Education Level</option>
  {educationLevelListbyApllication.map((level) => (
    <option key={level.EduLevelId} value={level.EduLevelId}>
      {level.EduLevelName}
    </option>
  ))}
</Form.Select>
              {formErrors.LevelOfEducation && <div className={classes["errorText"]}>{formErrors.LevelOfEducation}</div>}
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="countryInstitution">
                  <label className={`${classes["forLabel"]} m-2`}>Country of Institution
                    {/* <span className={classes["mandotory"]}>*</span> */}
                    </label>
                  <Form.Select
                    name="CountryOfInstitution"
                    aria-label="Country of Institution"
                    value={educationDetails.CountryOfInstitution}
                    onChange={(e) => {
                      handleCountryInstitutionChange(e);
                      handleInfoAddEducationChange(e);
                    }}
                    onBlur={handleInfoAddEducationChange}
                  >
                    <option className={classes["option-default"]} value="">Country of Institution</option>
                    {countryList.map((country) => (
                      <option key={country.CountryId} value={country.CountryId}>
                        {country.CountryName}
                      </option>
                    ))}
                  </Form.Select>
              {formErrors.CountryOfInstitution && <div className={classes["errorText"]}>{formErrors.CountryOfInstitution}</div>}
                </Form.Group>
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="institutionName">
                  <label className={`${classes["forLabel"]} m-2`}>Name of Institution
                    {/* <span className={classes["mandotory"]}>*</span> */}
                    </label>
                  <Form.Control
                    type="text"
                    name="NameOfInstitution"
                    placeholder="Institution Name"
                    value={educationDetails.NameOfInstitution}
                    onChange={handleInfoAddEducationChange}
                  />
                </Form.Group>
              {/* {formErrors.NameOfInstitution && <div className={classes["errorText"]}>{formErrors.NameOfInstitution}</div>} */}
              </div>

              {/* Additional fields based on education level */}
              {boardOfEducation && (
                <div className="col-md-12 form-group">
                  <Form.Group className="mb-3" controlId="boardOfEducation">
                    <label className={`${classes["forLabel"]} m-2`}>Board of Education</label>
                    <Form.Control
                      type="text"
                      name="Addres"
                      placeholder="Board of Education"
                      value={educationDetails.Addres}
                      onChange={handleInfoAddEducationChange}
                    />
                  </Form.Group>
                  {/* {formErrors.Addres && <div className={classes["errorText"]}>{formErrors.Addres}</div>} */}
                </div>
              )}

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="province">
                  <label className={`${classes["forLabel"]} m-2`}>Province
                    {/* <span className={classes["mandotory"]}>*</span> */}
                  </label>
                  <Form.Select
                    name="Province"
                    aria-label="Province/State"
                    value={educationDetails.Province}
                    onChange={(e) => {
                      handleProvinceChange(e);
                      handleInfoAddEducationChange(e);
                    }}
                    onBlur={handleInfoAddEducationChange}
                    required
                  >
                    <option className={classes["option-default"]} value="">Select a province/state</option>
                    {provinceList.map((province) => (
                      <option
                        key={province.ProvinceId}
                        value={province.ProvinceId}
                      >
                        {province.ProvinceName}
                      </option>
                    ))}
                  </Form.Select>
                    {formErrors.Province && <div className={classes["errorText"]}>{formErrors.Province}</div>}
                </Form.Group>
       

              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="city">
                  <label className={`${classes["forLabel"]} m-2`}>City</label>
                  <Form.Select
                    name="City"
                    aria-label="City/Town"
                    className="form-control"
                    value={educationDetails.City}
                    onChange={handleInfoAddEducationChange}
                    required
                  >
                    <option className={classes["option-default"]} value="">Select a city/town</option>
                    {cityList.map((city) => (
                      <option key={city.CityName} value={city.CityName}>
                        {city.CityName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              
            {/* {formErrors.City && <div className={classes["errorText"]}>{formErrors.City}</div>} */}

              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="primaryLanguage">
                  <label className={`${classes["forLabel"]} m-2`}>Primary Language of Instruction</label>
                  <Form.Select
                    name="Language"
                    aria-label="Language"
                    value={educationDetails.Language}
                    onChange={handleInfoAddEducationChange}
                  >
                    <option className={classes["option-default"]} value="">Select Language</option>
                    {languageList.map((language) => (
                      <option
                        key={language.LanguageId}
                        value={language.LanguageName}
                      >
                        {language.LanguageName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* {formErrors.Language && <div className={classes["errorText"]}>{formErrors.Language}</div>} */}
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="startDate">
                  <label className={`${classes["forLabel"]} m-2`}>Start Date (dd/mm/yyyy)</label>
                  <Form.Control
                    type="date"
                    name="AttendendFrom"
                    value={educationDetails.AttendendFrom}
                    onChange={handleInfoAddEducationChange}
                  />
                </Form.Group>
                {formErrors.AttendendFrom && <div className={classes["errorText"]}>{formErrors.AttendendFrom}</div>}
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="endDate">
                  <label className={`${classes["forLabel"]} m-2`}>End Date (dd/mm/yyyy)</label>
                  <Form.Control
                    type="date"
                    name="AttendendTo"
                    value={educationDetails.AttendendTo}
                    onChange={handleInfoAddEducationChange}
                    min={educationDetails.AttendendFrom}
                  />
                </Form.Group>
                {formErrors.AttendendTo && <div className={classes["errorText"]}>{formErrors.AttendendTo}</div>}
              </div>

              <div className="col-md-4 form-group">
                <Form.Group className="mb-3" controlId="degreeAwardedDate">
                  <label className={`${classes["forLabel"]} m-2`}>Degree Awarded Date</label>
                  <Form.Control
                    type="date"
                    name="DegreeAwardedOn"
                    value={educationDetails.DegreeAwardedOn}
                    onChange={handleInfoAddEducationChange}
                    min={calculateMinDegreeAwardedDate()}
                  />
                </Form.Group>
                {/* {formErrors.DegreeAwardedOn && <div className={classes["errorText"]}>{formErrors.DegreeAwardedOn}</div>} */}
              </div>

              <div className="col-md-12 form-group">
                <Form.Group className="mb-3" controlId="degree">
                  <label className={`${classes["forLabel"]} m-2`}>Degree Awarded / Program Name</label>
                  <Form.Select
                    name="Degree"
                    aria-label="degree/program"
                    value={educationDetails.Degree}
                    onChange={handleInfoAddEducationChange}
                  >
                    <option className={classes["option-default"]} value="">Select a degree/program</option>
                    {degreeList.map((degree) => (
                      <option key={degree.DegreeId} value={degree.DegreeName}>
                        {degree.DegreeName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* {formErrors.Degree && <div className={classes["errorText"]}>{formErrors.Degree}</div>} */}

              </div>

              {Number(educationDetails.LevelOfEducation) === 1108 && (
                <div className="col-md-4 form-group">
                  <Form.Group className="mb-3" controlId="Stream">
                    <label className={`${classes["forLabel"]} m-2`}>Stream</label>
                    <Form.Select
                      name="StreamId"
                      aria-label="Stream"
                      value={educationDetails.StreamId}
                      onChange={handleInfoAddEducationChange}
                      required
                    >
                      <option className={classes["option-default"]} value="">Select Stream</option>
                      {/* Populate with your stream options */}
                      {streamList.map((stream) => (
                        <option key={stream.StreamId} value={stream.StreamId}>
                          {stream.StreamName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              )}


              {/* for marks */}
              {educationDetails.CountryOfInstitution && (
                <>
                  {/* Form Row */}
                  <div className="row">
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="subjectName">
                        <label className={`${classes["forLabel"]} m-2`}>Subject
                          {/* <span className={classes["mandotory"]}>*</span> */}
                          </label>
                        <Form.Select
                          aria-label="Name of Subject"
                          name="SubjectId"
                          value={formData.SubjectId}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          className={`${classes["form-select"]} ${classes["select-truncate"]}`}
                          style={{ paddingRight: "20px" }}
                        >
                          <option className={classes["option-default"]} value="">Select Subject</option>
                          {subjectList.map((subject) => (
                            <option key={subject.SbjId} value={subject.SbjId}>
                              {subject.SbjName}
                            </option>
                          ))}
                        </Form.Select>
                        {formSubjectErrors.SubjectId && (
                                  <div className={classes["errorText"]}>{formSubjectErrors.SubjectId}</div>
                                )}
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="grade">
                        <label className={`${classes["forLabel"]} m-2`}>Grade
                          {/* <span className={classes["mandotory"]}>*</span> */}
                        </label>
                        <Form.Select
                          aria-label="Grade"
                          name="GradeId"
                          value={formData.GradeId}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          className={`${classes["form-select"]} ${classes["select-truncate"]}`}
                          style={{ paddingRight: "20px" }}
                        >
                          <option className={classes["option-default"]} value="">Select Grade</option>
                          <option value="0">None</option>
                          {grdSchemeData.map((scheme) => (
                            <option
                              key={scheme.GrdSchemeId}
                              value={scheme.GrdSchemeId}
                            >
                              {scheme.GrdSchemeName}
                            </option>
                          ))}
                        </Form.Select>
                        {formSubjectErrors.GradeId && (
                                  <div className={classes["errorText"]}>{formSubjectErrors.GradeId}</div>
                                )}
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="MinMarks">
                        <label className={`${classes["forLabel"]} m-2`}>Min
                          {/* <span className={classes["mandotory"]}>*</span> */}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="MinMarks"
                          value={formData.MinMarks}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          disabled={formData.GradeId > 0}
                          min={0}
                        />
                         {formSubjectErrors.MinMarks && (
                              <div className={classes["errorText"]}>{formSubjectErrors.MinMarks}</div>
                            )}
                      </Form.Group>
                    </div>
                    <div className="col-md-2 form-group">
                      <Form.Group className="mb-3" controlId="MaxMarks">
                        <label className={`${classes["forLabel"]} m-2`}>Max
                          {/* <span className={classes["mandotory"]}>*</span> */}
                          </label>
                        <input
                          type="number"
                          className="form-control"
                          name="MaxMarks"
                          value={formData.MaxMarks}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          disabled={formData.GradeId > 0}
                          min={0}
                        />
                         {formSubjectErrors.MaxMarks && (
                                <div className={classes["errorText"]}>{formSubjectErrors.MaxMarks}</div>
                              )}
                      </Form.Group>
                    </div>

                    <div className="col-md-2 form-group">
                      {dropDownGradingSchemeSelected ? (
                        <Form.Group
                          className="mb-3"
                          controlId="ObtainMarksDropdown"
                        >
                          <label className={`${classes["forLabel"]} m-2`}>Score
                            {/* <span className={classes["mandotory"]}>*</span> */}
                            </label>
                          <Form.Select
                            name="ObtainMarks"
                            value={formData.ObtainMarks}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                            required
                          >
                            <option
                              className={classes["option-default"]}
                              value=""
                            >
                              Select Score
                            </option>
                            {gradeSchemeDDL.map((grade, index) => (
                              <option key={index} value={grade}>
                                {grade}
                              </option>
                            ))}
                          </Form.Select>
                          {formSubjectErrors.ObtainMarks && (
                              <div className={classes["errorText"]}>{formSubjectErrors.ObtainMarks}</div>
                            )}
                        </Form.Group>
                      ) : (
                        <Form.Group
                          className="mb-3"
                          controlId="ObtainMarksInput"
                        >
                          <label className={`${classes["forLabel"]} m-2`}>Score
                            {/* <span className={classes["mandotory"]}>*</span> */}
                            </label>
                          <input
                            type="number"
                            className="form-control"
                            name="ObtainMarks"
                            value={formData.ObtainMarks}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                            required
                            min={0}
                          />
                           {formSubjectErrors.ObtainMarks && (
                                    <div className={classes["errorText"]}>{formSubjectErrors.ObtainMarks}</div>
                                  )}
                        </Form.Group>
                      )}
                    </div>

                    <div className={`col-md-2 ${classes.addBtnAlgin}`}>
                      <Button variant="secondary" onClick={handleAddRow}>
                        Add
                      </Button>
                    </div>
                  </div>

                  {formErrors.Marks && <div className={classes["errorText"]}>{formErrors.Marks}</div>}

                  {/* Display Rows in a Table */}

                  {rows.length > 0 && (
                    <Table responsive className={classes.customTable}>
                      <thead>
                        <tr>
                          {/* <th>Sr No.</th> */}
                          <th>Subject</th>
                          <th>Grade</th>
                          <th>Min</th>
                          <th>Max</th>
                          <th>Score</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            {/* <td>{index + 1}</td> */}
                            <td>{row.SubjectName}</td>
                            <td>{row.GradeName}</td>
                            <td>{row.MinMarks}</td>
                            <td>{row.MaxMarks}</td>
                            <td>{row.ObtainMarks}</td>
                            <td>
                              <img
                                className={classes.iconSize}
                                src={eduDelete}
                                onClick={() => handleRemoveRow(row.SubjectId)}
                                alt="Delete"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </>
              )}
            </div>
            <div className={` ${classes.submit_btn}`}>
              {!updateShow && (
              <button
              className={`primary-button`}
                variant="primary"
                onClick={addSchoolData}
              >
                Save Details
              </button>
            )}

              {updateShow && (
              <button
              className={`primary-button`}
                variant="primary"
                onClick={(e)=>{updateSchoolData(e)}}
              >
                 Save Details
              </button>
              )}
            </div>
          </div>
        </SearchModal>
  </div>
  {isLoading && (
        <Loader></Loader>
      )}
  </>
  )
}

export default AddEducationModal;
