import React, { useState, useEffect, useRef } from 'react';
import classes from './Scholarship.module.css';
import Accordion from 'react-bootstrap/Accordion';
import downarrow from "../../../../../Assets/Logo/ic-down-arrow 3.svg";
import uparrow from '../../../../../Assets/Logo/ic-top-arrow.svg';
import nodata from "../../../../../Assets/Logo/no-data1.svg";
import { Form } from 'react-bootstrap';
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';
import GuestUserModal from '../../../../GlobleShared/Modal/GuestUserModal/GuestUserModal';

const Scholarship = ({ isFlag, scholarships = [], resetFlag, updateScholarFilters, selectedScholarEdulevels, selectedProgramTypeId, removeEduLevel, eduLevels, setCountryId, fetchScholarshipData }) => {

    const [selectedAmounts, setSelectedAmount] = useState([]);
    const [selectedInstitutes, setSelectedInstitutes] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [tagFlag, setTagFlag] = useState(false);
    const [searchInput, setSearchInput] = useState(""); // State for search input
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [shouldUpdateFilters, setShouldUpdateFilters] = useState(false);
    const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'
    const initial = useRef(null);
    const [activeKey, setActiveKey] = useState(['0', '1', '2', '3']); // Initial Open States set

    const institutionId = scholarships?.Institution?.InstitutionId;
    const [scholarData, setScholarData] = useState([]);
    const { isLoginUser } = useBehaviorSubject();
    const [guestuserFlag, setGuestuserFlag] = useState(false);

    const handleAccordionSelect = (eventKey) => {
        setActiveKey(prevActiveKey => {
            if (prevActiveKey.includes(eventKey)) {
                return prevActiveKey.filter(key => key !== eventKey);
            } else {
                return [...prevActiveKey, eventKey];
            }
        });
    };

    function stripHtmlTags(str) {
        if (!str) return '';
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }

    // const filtersData = institutes?.filters?.[0]|| [];
    const clearFilters = () => {
        setSelectedAmount([]);
        setSelectedInstitutes([]);
        setSelectedPrograms([]);
        setFilterAction('clear'); // Set filter action to 'clear'
        updateScholarFilters({
            selectedAmounts: [],
            selectedInstitutes: [],
            selectedPrograms: [],
        });
        // fetchScholarshipData(1); // Reset to page 1
    };
    const applyFilters = () => {
        setFilterAction('apply'); // Set filter action to 'apply'
        updateScholarFilters({
            selectedAmounts,
            selectedInstitutes,
            selectedPrograms,
        });
        // fetchScholarshipData(1); // Apply filters and fetch data
    };

    useEffect(() => {
        if (filterAction === 'clear') {
            fetchScholarshipData(1, true); // Apply filters and fetch data
        } else if (filterAction === 'apply') {
            fetchScholarshipData(1, false); // Reset to page 1
        }
        setFilterAction(null);
    }, [selectedAmounts, selectedInstitutes, selectedPrograms, filterAction]);

    const handleAmountChange = (amountId) => {
        setSelectedAmount((prev) =>
            prev.includes(amountId)
                ? prev.filter((id) => id !== amountId)
                : [...prev, amountId]
        );

    };

    useEffect(() => {
        if (isFlag && scholarships) {
            setSelectedAmount([]);
            setSelectedInstitutes([]);
            setSelectedPrograms([]);
            setScholarData(scholarships)
        }

    }, [isFlag, scholarships])


    const handleInstituteChange = (instituteId) => {
        setSelectedInstitutes((prev) =>
            prev.includes(instituteId)
                ? prev.filter((id) => id !== instituteId)
                : [...prev, instituteId]
        );

    };
    const handleProgramClick = (institutionId, setCountryId) => {
        const programDetailUrl = `/member/institute-details/${institutionId}?selectedCountryId=${setCountryId}`;
        window.open(programDetailUrl, '_blank');
    };

    const handleProgramChange = (ProgramId) => {
        setSelectedPrograms((prev) =>
            prev.includes(ProgramId)
                ? prev.filter((id) => id !== ProgramId)
                : [...prev, ProgramId]
        );

    };
    const limitCharacters = (text, limit = 20) => {
        if (!text) return 'N/A';
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };




    // useEffect(() => {
    //   updateScholarFilters({
    //     selectedAmounts,
    //     selectedInstitutes,
    //     selectedPrograms
    //   });
    // }, [selectedAmounts, selectedAmounts, selectedPrograms]);


    const filteredInstitutes = scholarData?.Filters?.Institution?.filter((institute) =>
        institute.InstitutionName.toLowerCase().includes(searchInput.toLowerCase())
    );

    const hideGuestModalhandler = () => {
        setGuestuserFlag(false);
    }

    return (
        <>
            {guestuserFlag && (
                <GuestUserModal onClose={hideGuestModalhandler} onShow={guestuserFlag} />
            )}
            <div>
                <div className="row">

                    {/* Main Content */}
                    <div className="col-md-11 col-lg-12">
                        <div className="d-flex flex-wrap">
                            {selectedScholarEdulevels && setCountryId && (scholarships?.Institution?.length > 0) && (
                                <button
                                    className={classes.NameBudge}
                                    onClick={() => removeEduLevel()}
                                >
                                    {eduLevels.find(item => item.EduLevelId === selectedScholarEdulevels)?.EduLevelName}
                                    <span>
                                        <img src={crossIconWhite} alt="Remove tag" />
                                    </span>
                                </button>
                            )}
                        </div>
                        {scholarships?.Institution?.length > 0 ? (
                            <div className={classes["program-cards"]}>
                                {scholarships?.Institution?.map((scholarship) => (
                                    <div className={classes["card-section"]} key={scholarship.ProgramId}>
                                        <div className={classes["section-1"]}>
                                            <div className={classes["card-header-block-1"]}>
                                                {/* <div className={classes["card-rank"]}>
                                            <span className={classes["rank-text"]}>Ranked: {scholarship.rank || 'N/A'}</span>
                                        </div> */}
                                                <div className={classes["card-header"]}>
                                                    <div className={classes["university-icon"]}>
                                                        <img src={scholarship.InstLogoPath ? bloburl + scholarship.InstLogoPath : universityLogo} alt="Profile" />
                                                    </div>
                                                    <div className={classes["university-detail"]}>
                                                        <div title={scholarship.InstitutionName} className={classes["university-name"]}>{scholarship.InstitutionName || 'N/A'}</div>
                                                        <div className={classes["university-address"]}>{scholarship.InstCountryName || 'N/A'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes["card-header-block-2"]}>
                                                <div title={stripHtmlTags(scholarship?.ProgramDescription)} className={classes["program-detail-text"]}>
                                                    <p dangerouslySetInnerHTML={{ __html: limitCharacters(stripHtmlTags(scholarship?.ProgramDescription), 600) }}></p>

                                                </div>
                                                <div title={scholarship.ProgramName} className={classes["program-name"]}>{scholarship.ProgramName || 'N/A'}</div>
                                                <div className={classes["type"]}>
                                                    <span className={classes["type-label"]}>Type:</span>&nbsp;
                                                    <span className={classes["type-value"]}>{scholarship?.AmountTypeName}</span>
                                                </div>
                                                {/* <div className={classes["program-detail-text"]}>{scholarship?.ProgramDescription || 'N/A'}</div> */}
                                            </div>
                                        </div>
                                        <div className={classes["section-2"]}>
                                            <div className={classes["program-detail-section-1"]}>
                                                {/* <div>
                                            <div className={classes["intake-header"]}>Intake</div>
                                            <div className={classes["detail"]}>{scholarship.intake?.startDate || 'N/A'}</div>
                                            <div className={classes["detail"]}>{scholarship.intake?.endDate || 'N/A'}</div>
                                        </div> */}
                                                {/* <div className={classes["detail"]}>{scholarship.DurationTime || 'N/A'}</div> */}
                                                {/* <div className={classes["detail"]}>{scholarship.degree || 'N/A'}</div> */}
                                                <div className={classes["detail"]}>
                                                    <span className={classes["scholarship-label"]}>Scholarship:</span>
                                                    <span className={classes["scholarship-amount"]}> {scholarship.ProgramCurrency} {scholarship.MinAmount} - {scholarship.MaxAmount}</span>
                                                </div>
                                                <div className={classes["know-more"]}>
                                                    <span onClick={() => handleProgramClick(scholarship?.InstitutionId, setCountryId)}>Know More...</span>
                                                </div>
                                            </div>
                                            {/* <div className={classes["program-detail-section-2"]}>
                                        <div>
                                            <div className={classes["header"]}>Application Fee</div>
                                            <div className={classes["detail"]}>{scholarship.applicationFee || 'N/A'}</div>
                                        </div>
                                        <div>
                                            <div className={classes["header"]}>People like You</div>
                                            <div className={classes["detail"]}>{scholarship.graduates || 'N/A'}</div>
                                        </div>
                                        <div>
                                            <div className={classes["header"]}>Likelihood of Graduation</div>
                                            <div className={classes["detail"]}>{scholarship.graduationLikelihood || 'N/A'}</div>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div >
                        ) : resetFlag && (
                            <div className={classes["NOdata"]}>
                                <div>
                                    <img className={classes["noimg"]} src={nodata} alt='nodata' />
                                </div>
                                <div className={classes['line']}>
                                </div>
                                <div className={classes['nospace']}>
                                    <span className={classes["no"]}>No data found</span>
                                    <span className={classes['moment']}> at the moment.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scholarship;