import React, { useState,useEffect, useRef, useCallback } from "react";
import classes from "./About.module.css";
import editIcon from '../../../../../Assets/Logo/edit-icon.svg';
import saveIcon from '../../../../../Assets/Logo/languageSave.svg';
import addIcon from '../../../../../Assets/Logo/red add icon.svg';
import coinIcon from '../../../../../Assets/Logo/coin-icon.svg';
import corssIcon from '../../../../../Assets/Logo/eclipsecrossicon.svg';
import corssIcon1 from '../../../../../Assets/Logo/red-cross-icon.svg';
import languageIcon from '../../../../../Assets/Logo/language-icon.svg';
import nameEdit from '../../../../../Assets/Logo/content-edit-icon.svg';
import locationIcon from '../../../../../Assets/Logo/location-icon.svg';
import contactIcon from '../../../../../Assets/Logo/contact-icon.svg';
import birthIcon from '../../../../../Assets/Logo/birth-icon.svg';
import statusIcon from '../../../../../Assets/Logo/status-icon.svg';
import starIcon from '../../../../../Assets/Logo/star-icon.svg';
import genderIcon from '../../../../../Assets/Logo/gender-icon.svg';
import calenderIcon from '../../../../../Assets/Logo/calender-icon.svg';
import graduationCap from '../../../../../Assets/Logo/graduation-cap-icon.svg';
import programIcon from '../../../../../Assets/Logo/program-icon.svg';
// import lineIcon from '../../../../../Assets/Logo/line-hr.svg';
import deleteICon from '../../../../../Assets/Logo/delete-icon.svg';
import jobTitleIcon from '../../../../../Assets/Logo/job-title-icon.svg';
import upperArrowICon from '../../../../../Assets/Logo/ic-top-arrow.svg';
import dropArrowICon from '../../../../../Assets/Logo/ic-down-arrow 3.svg';
import emailIcon from '../../../../../Assets/Logo/email-icon.svg';
import Modal_Box from "../../../../GlobleShared/Modal/Modal_Box";
import AboutModalBody from "./About_ModalBody/AboutModalBody";
import SearchModal from "../../Search/SearchModal/SearchModal";
import ProfileService from "../../../../../Services/ProfileService";
import ApiService from "../../../../../Services/APIService";
import Loader from "../../../../GlobleShared/Loader/Loader";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ApplicationService from "../../../../../Services/ApplicationServices";
import AddEducationModal from "../../Applications/NewApplication/EducationSection/AddEducationModal/AddEducationModal";
import { useProfile } from "../../../../Context/ProfileContext";

// import AddEducationModal from "../Education/Education";
const competencies=["Creativity and Innovation: 5", "Creativity and Innovation: 6"]
function About({ rowData, onUpdate, connectedStudentId }) {
  const [modalIsVisible, setModalIsVisible] = useState();
  const [selectedHeading, setselectedHeading] = useState("");
  const [connectionIdValue, setConnectionIdValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [editView, setEditView] = useState("");
  const [updatedData, setUpdatedData] = useState({});
  const [interests, setInterests] = useState([]);
  const [confirmationModalIsVisible, setConfirmationModalIsVisible] = useState(false);
  const [confirmationModalIsVisible1, setConfirmationModalIsVisible1] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [btnTextName, setBtnTextName] = useState('');
  const [interestsIdValue, setInterestsIdValue] = useState('');
  const [competencyIdValue, setCompetencyIdValue] = useState('');
  const [countryCode, setCountryCode] = useState([]);
  const [errors, setErrors] = useState({});
  const [userDetail] = useLocalStorage('userDetail');
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const [competencies, setCompetencies] = useState();
  const [schoolData, setSchoolData] = useState();


  const [addEducationPopup, setAddEducationPopup] = useState(false);
  const [updateEducationData, setUpdateEducationData] = useState(false);
  const [selectedSchoolData, setSelectedSchoolData] = useState({});
  const [confirmationRepostModal, setConfirmationRepostModal] = useState(false);

  const initial = useRef(null);
  const [skills, setSkills] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedSecLanguage, setSelectedSecLanguage] = useState();
  const [editPrimaryLanguage, setEditPrimaryLanguage] = useState(false);
  const [editSecLanguage, setEditSecLanguage] = useState(false);
  const [buttonText, setButtonText] = useState('Update');
  const [updatedWorkData, setUpdatedWorkData] = useState({});
  const [confirmationModalIsVisibleForWork, setConfirmationModalIsVisibleForWork] = useState(false);
  const [parentListData, setParentListData] = useState([]);
  const [editAboutSection, setEditAboutSection] = useState(false);
  const [title, setTitle] = useState(null);
  const [workHeading, setWorkHeading] = useState("")
   const {profileImage, setProfileImage } = useProfile();
  // error object for overview work and about
  const [formErrors, setFormErrors] = useState({
    city: null,
    country: null,
    dateOfBirth: null,
    firstName: null,
    gender: null,
    lastName: null,
    MailingCity: null,
    MailingCountry: null,
    MailingProvince: null,
    maritalStatus: null,
    province: null,
    tagline: null,
  });
  const [languageErrors, setLanguageErrors] = useState({
    secLanguage: null,
    language: null,
  })
  const [formWorksErrors, setFormWorksErrors] = useState({
    end: null,
    start: null,
    location: null,
    jobTitle: null,
    company: null,
  });
  const [formAboutErrors, setFormAboutErrors] = useState({
    about: null
  });

  useEffect(() => {
    setUpdatedData({
      about: rowData.About,
      city: rowData.City,
      email: rowData.Email,
      country: rowData.Country,
      dateOfBirth: rowData.DateOfBirth,
      firstName: rowData.FirstName,
      gender: rowData.Gender,
      language: rowData.Language,
      lastName: rowData.LastName,
      MailingCity: rowData.MailingCity,
      MailingCountry: rowData.MailingCountry,
      MailingProvince: rowData.MailingProvince,
      maritalStatus: rowData.MaritialStatus,
      mobileNo: rowData.MobileNo,
      mobileNoCountryCode: rowData.MobileNoCountryCode,
      province: rowData.Province,
      secLanguage: rowData.SecLanguage,
      studentId: studentId,
      tagline: rowData.TagLine
    });



    setSelectedLanguage(rowData.Language);
    setSelectedSecLanguage(rowData.SecLanguage,);
  }, [rowData]);
  const [cityList, setCityList] = useState([]);
  const [mailingProvinceList, setMailingProvinceList] = useState([]);
  const [mailingCityList, setMailingCityList] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [addMoreEducationPopup, setAddMoreEducationPopup] = useState(false);



  //******************************************* */ onchange function for overview work and about
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update form state
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate the field whenever there's a change
    const errorMsg = validateField(name, value);

    // Update the form errors if there is an error message
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
    // Set validation errors but don't reset data for 'Country' and 'Province'
    // if (name === 'country') {
    //   // updatedData.province="";
    //   // updatedData.city="";
    //   setFormErrors((prevErrors) => ({
    //       ...prevErrors,
    //       province: 'Please select State',
    //       city: 'Please select City',
    //   }));
    // }

    // if (name === 'province') {
    //   // updatedData.city="";
    //   setFormErrors((prevErrors) => ({
    //       ...prevErrors,
    //       city: 'Please select City',
    //   }));
    // }

    // // Set validation errors but don't reset data for 'MailingCountry' and 'MailingProvince'
    // if (name === 'MailingCountry') {
    //   // updatedData.MailingProvince="";
    //   // updatedData.MailingCity="";
    //   setFormErrors((prevErrors) => ({
    //       ...prevErrors,
    //       MailingProvince: 'Please select Residence State',
    //       MailingCity: 'Please select Residence City',
    //   }));
    // }

    // if (name === 'MailingProvince') {
    //   setFormErrors((prevErrors) => ({
    //       ...prevErrors,
    //       MailingCity: 'Please select Residence City',
    //   }));
    // }

  };


  const handleInputChangeAbout = (event) => {
    const { name, value } = event.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const formAboutErrors = validateFieldAbout(name, value);

    setFormAboutErrors((prevErrors) => ({
      ...prevErrors,
      [name]: formAboutErrors,
    }));

  };



  const handleWorkInputChange = (event) => {
    const { name, value } = event.target;
    // setErrors((prev) => ({ ...prev, [name]: "" }));
    setUpdatedWorkData((prevData) => ({ ...prevData, [name]: value }));

    const formWorksErrors = validateFieldWork(name, value);

    // Update the form errors if there is an error message
    setFormWorksErrors((prevErrors) => ({
      ...prevErrors,
      [name]: formWorksErrors,
    }));
  };


  const handleLanguageChange = (event) => {
    const { name, value } = event.target;

    if (name === 'language') {
      setSelectedLanguage(value);
    } else if (name === 'secLanguage') {
      setSelectedSecLanguage(value);
    }

    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const errorMsg = validateFieldLanguage(name, value);
    setLanguageErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  // ********************************************************************



  const handleConfirmDeleteWorkExp = () => {
    deleteWorkExperience(connectionIdValue);
  };

  const handleCancelDeleteWorkExp = () => {
    setConfirmationModalIsVisibleForWork(false);
    // setWorkExpToDelete(null);
  };

  // const validateFields = () => {
  //   const newErrors = {};
  //   if (editView === "Overview") {
  //   if (!updatedData.firstName) newErrors.firstName = "First Name is required.";
  //   if (!updatedData.lastName) newErrors.lastName = "Last Name is required.";
  //   if (!updatedData.mobileNo) newErrors.mobileNo = "Mobile No is required.";
  //   if (!updatedData.country) newErrors.country = "Country is required.";
  //   if (!updatedData.tagline) newErrors.tagline = "Tagline is required.";
  //   if (!updatedData.maritalStatus) newErrors.maritalStatus = "Marital Status is required.";
  //   if (!updatedData.gender) newErrors.gender = "Gender is required.";
  //   if (!updatedData.province) newErrors.province = "State is required.";
  //   if (!updatedData.city) newErrors.city = "City is required.";
  //   if (!updatedData.dateOfBirth) newErrors.dateOfBirth = "Date of Birth is required.";
  //   if (!updatedData.mobileNoCountryCode) newErrors.mobileNoCountryCode = "Country code is required.";
  //   if (!updatedData.MailingCountry) newErrors.MailingCountry = "Residence Country is required.";
  //   if (!updatedData.MailingProvince) newErrors.MailingProvince = "Residence State is required.";
  //   if (!updatedData.MailingCity) newErrors.MailingCity = "Residence City is required.";
  //   } else if (editAboutSection) {
  //      if (!updatedData.about) newErrors.about = "About is required.";
  //   } else if (editView === "Work") {
  //      if (!updatedWorkData.company) newErrors.company = "Company Name is required.";
  //      if (!updatedWorkData.jobTitle) newErrors.jobTitle = "job Title is required.";
  //      if (!updatedWorkData.location) newErrors.location = "Location is required.";
  //      if (!updatedWorkData.start) newErrors.start = "Start Date is required.";
  //      if (!updatedWorkData.end) newErrors.end = "End Date is required.";
  //   }
  //   return newErrors;
  // };
  // New function to handle work input changes
  // const newhandleWorkInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setUpdatedWorkData((prevData) => ({ ...prevData, [name]: value }));

  //   const formWorksErrors = validateFieldWork(name, value);

  //   // Update the form errors if there is an error message
  //   setFormWorksErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: formWorksErrors,
  //   }));

  //   // Additional validation for date fields
  //   if (name === "start" || name === "end") {
  //     const startDate = new Date(updatedWorkData.start);
  //     const endDate = new Date(updatedWorkData.end);

  //     if (name === "end" && !value) {
  //       // Clear the error if end date is empty
  //       setFormWorksErrors((prevErrors) => ({
  //         ...prevErrors,
  //         end: null,
  //       }));
  //     } else if  (startDate && endDate) {
  //       if (startDate.getTime() === endDate.getTime()) {
  //         setFormWorksErrors((prevErrors) => ({
  //           ...prevErrors,
  //           end: "End date should not be the same as start date.",
  //         }));
  //       } else if (endDate < startDate) {
  //         setFormWorksErrors((prevErrors) => ({
  //           ...prevErrors,
  //           end: "End date should be after start date.",
  //         }));
  //       } else {
  //         setFormWorksErrors((prevErrors) => ({
  //           ...prevErrors,
  //           end: null,
  //         }));
  //       }
  //     }
  //   }
  // };
  const newhandleWorkInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedWorkData((prevData) => ({ ...prevData, [name]: value }));

    const formWorksErrors = validateFieldWork(name, value);

    // Update the form errors if there is an error message
    setFormWorksErrors((prevErrors) => ({
      ...prevErrors,
      [name]: formWorksErrors,
    }));

    // Additional validation for date fields
    if (name === "start" || name === "end") {
      const startDate = new Date(updatedWorkData.start);
      const endDate = new Date(value);

      if (name === "end" && !value) {
        // Clear the error if end date is empty
        setFormWorksErrors((prevErrors) => ({
          ...prevErrors,
          end: null,
        }));
      } else if (name === "start" && updatedWorkData.end) {
        // Validate end date if start date is changed
        const existingEndDate = new Date(updatedWorkData.end);
        if (existingEndDate < startDate) {
          setFormWorksErrors((prevErrors) => ({
            ...prevErrors,
            end: "End date should be after start date.",
          }));
        } else {
          setFormWorksErrors((prevErrors) => ({
            ...prevErrors,
            end: null,
          }));
        }
      } else if (name === "end" && startDate && endDate < startDate) {
        setFormWorksErrors((prevErrors) => ({
          ...prevErrors,
          end: "End date should be after start date.",
        }));
      } else {
        setFormWorksErrors((prevErrors) => ({
          ...prevErrors,
          end: null,
        }));
      }
    }
  };
  const showConfirmModalhandler = (id) => {
    setSchoolId(id);
    setConfirmationRepostModal(true);
  };
  const hideConfirmModalhandler = () => {
    setConfirmationRepostModal(false);
  };
  const deleteSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        // toast.success(res?.message);
        toast.success("Education details deleted successfully.");
        getSchoolDetailList();
        setIsLoading(false)
      })
      .catch((error) => {
        toast.error("Failed to delete education details. Please try again.");
        setIsLoading(false)
        console.log(error);
      });
  };
  const deleteSchoolData = () => {
    deleteSchoolDetail(schoolId)
    hideConfirmModalhandler();
  }



  //*************************************************** */ validations for work overview and about
  const validateFieldWork = (field, value) => {
    let errorMsg = null;
    const trimmedValue = String(value); // Ensure value is a string and trim it

    switch (field) {
      case "company":
        if (!trimmedValue) errorMsg = "Company Name is required";
        else if (trimmedValue.length > 100) errorMsg = "Company Name cannot exceed 100 characters";
        // else if (/[^a-zA-Z\s]/.test(trimmedValue)) errorMsg = 'Please enter a valid Company name';
        break;
      case "jobTitle":
        if (!trimmedValue) errorMsg = "Please enter a valid Job Title";
        else if (trimmedValue.length > 50) errorMsg = "Job Title cannot exceed 50 characters";
        // else if (/[^a-zA-Z\s]/.test(trimmedValue)) errorMsg = 'Please enter a valid Job Title';
        break;
      case "location":
        if (!trimmedValue) errorMsg = "Location is required";
        break;
      case "start":
        if (!trimmedValue) errorMsg = "Start Date is required";
        break;
      case "end":
        // Allow empty "End Date"
        if (trimmedValue && new Date(trimmedValue) < new Date(updatedWorkData.start)) {
          errorMsg = "End Date should be after Start Date";
        }
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validateFieldAbout = (field, value) => {
    let errorMsg = null;
    switch (field) {
      case "about":
        if (!value) errorMsg = "About is required";
        else if (value.length > 2000) errorMsg = "About cannot exceed 2000 characters";
        break;
    }
    return errorMsg;
  };

  const validateField = (name, value) => {
    let errorMsg = null;
    // const trimmedValue = value.trim();
    switch (name) {
      // case "mobileNo":
      //   if (!value) errorMsg = "Please enter Mobile No.";
      //   else if (!/^\d{10}$/.test(value)) errorMsg = "Please enter a valid 10-digit Mobile No.";
      //   break;
      case "firstName":
        value = value.trim();

        if (!value) errorMsg = "Please enter First Name";
        else if (value.length > 50) errorMsg = "First Name cannot exceed 50 characters";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid First name';
        break;
      case "lastName":
        value = value.trim();

        if (!value) errorMsg = "Please enter Last Name";
        else if (value.length > 50) errorMsg = "Last Name cannot exceed 50 characters";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid Last name';
        break;
      case "gender":
        if (!value) errorMsg = "Please select Gender";
        break;
      case "dateOfBirth":
        if (!value) errorMsg = "Please enter Date of Birth";
        break;
      case "country":
        if (!value) errorMsg = "Please select Country";
        break;
      case "province":
        if (!value) errorMsg = "Please select State";
        break;
      case "city":
        if (!value) errorMsg = "Please select City";
        break;
      case "MailingCountry":
        if (!value) errorMsg = "Please select Residence Country";
        break;
      case "MailingProvince":
        if (!value) errorMsg = "Please select Residence State";
        break;
      case "MailingCity":
        if (!value) errorMsg = "Please select Residence City";
        break;
      case "maritalStatus":
        if (!value) errorMsg = "Please select Marital Status";
        break;
      // case "mobileNoCountryCode":
      //   if (!value) errorMsg = "Please select Country Code.";
      //   break;
      // case "language":
      //   if (!value) errorMsg = "Please select Language.";
      //   break;
      // case "secLanguage":
      //   if (!value) errorMsg = "Please select Secondary Language.";
      //   break;
      case "tagline":
        if (!value) errorMsg = "Please enter Tagline";
        else if (value.length > 255) errorMsg = "tagline cannot exceed 255 characters";
        // else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid tagline';
        break;
      default:
        break;
    }
    return errorMsg;
  };

  //*************************************** Validation for language section
  const validateFieldLanguage = (field, value) => {
    let errorMsg = null;
    switch (field) {
      case "language":
        if (!value) errorMsg = "Primary Language is required";
        break;
      case "secLanguage":
        if (!value) errorMsg = "Secondary Language is required";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  // ************************************************************************************//




  // validaitons
  // const validateForm = (form) => {
  //   const errorObj = {};
  //   Object.keys(form).forEach((key) => {
  //     const msg = validateField(key, form[key]);
  //     if (msg) errorObj[key] = msg;
  //     console.log('msg: ', msg);
  //   });
  //   return errorObj;
  // };

  const validateForm = (form, editView) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      let msg;
      // Check validation based on the current edit view
      if (editView === 'Overview') {
        msg = validateField(key, form[key]);
      } else if (editView === 'Work') {
        msg = validateFieldWork(key, form[key]);
      } else if (editView === 'About') {
        msg = validateFieldAbout(key, form[key]);
      } else if (editView === "Language") {
        msg = validateFieldLanguage(key, form[key]);
      }
      if (msg) {
        errorObj[key] = msg;
        console.log(`Validation error for ${key}:`, msg);
      }
    });
    return errorObj;
  };

  const isButtonDisabled = () => {
    if (editView === 'About') {
      return !updatedData?.about || updatedData.about.length === 0;
    }
    if (editView === 'Language') {
      return !(selectedLanguage?.length > 0 && selectedSecLanguage?.length > 0);
    }
    if (editView === 'Work') {
      const fieldsToCheck = ["company", "jobTitle", "location", "start"];
      return fieldsToCheck.some(field => !updatedWorkData[field]);
    }
    return false;
  };

  const handleUpdate = (e) => {
    // const validationErrors = validateFields();
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   return; 
    // }

    e.preventDefault();
    let payloadData = editView === 'Overview' ? updatedData : editView === 'Work' ? updatedWorkData : editView === 'About' ? updatedData.about :
      editView === "Language" ? {
        primaryLanguage: selectedLanguage,
        secondaryLanguage: selectedSecLanguage,
      } : null;

    const errors = validateForm(payloadData, editView);
    if (editView === 'Overview') {
      setFormErrors(errors); // Set errors for the overview form
    } else if (editView === 'Work') {
      setFormWorksErrors(errors); // Set errors for the work form
    } else if (editAboutSection === true) {
      setFormAboutErrors(errors); // Set errors for the about section form
    }
    else if (editView === "Language") {
      setLanguageErrors(errors);
    }
    if (Object.keys(errors).length > 0) {
      return
    }

    if (editView === 'Work') {
      if (buttonText === 'Add') {
        addWorkExperience();
      } else {
        updateWorkExperience();
      }
    } else {
      setIsLoading(true);
      const payload = {
        "about": updatedData.about,
        "city": updatedData.city,
        "country": updatedData.country,
        "dateOfBirth": updatedData.dateOfBirth,
        "firstName": updatedData.firstName,
        "gender": updatedData.gender,
        "language": selectedLanguage,
        "lastName": updatedData.lastName,
        "mailingCity": updatedData.MailingCity,
        "mailingCountry": updatedData.MailingCountry,
        "mailingProvince": updatedData.MailingProvince,
        "maritalStatus": updatedData.maritalStatus,
        "mobileNo": updatedData.mobileNo,
        "mobileNoCountryCode": updatedData.mobileNoCountryCode,
        "province": updatedData.province,
        "secLanguage": selectedSecLanguage,
        "studentId": updatedData.studentId,
        "tagline": updatedData.tagline
      };
      ProfileService.updateAboutProfileInfo(payload).then((res) => {
        toast.success('Overview section details updated successfully!');
        onUpdate(true);
        setIsLoading(false);
        setEditView('');
        setEditPrimaryLanguage(false);
        setEditSecLanguage(false);
        setEditAboutSection(false);
        setProfileImage(res)
      }).catch((error) => {
        console.log('error: ', error);
        toast.error('Failed to update the Overview section. Please try again.');
        setIsLoading(false);
      });
    }
  };
  const getInterestsInfo = (studentId) => {
    let refId;
    if (connectedStudentId) {
      refId = connectedStudentId;
    }
    else {
      refId = studentId;
    }
    setIsLoading(true)
    ProfileService.getInterestsInfo(refId).then((res) => {
      setInterests(res);
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  const deleteInterests = (id) => {
    setIsLoading(true)
    ProfileService.deleteStudentInterests(id)
      .then((res) => {
        toast.success(res.message);
        getInterestsInfo(studentId);
        setConfirmationModalIsVisible(false);
        setIsLoading(false)
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false)
      });
  };

  const deleteCompetency = (id) => {
    setIsLoading(true)
    ProfileService.deleteCompetencyInfo(id)
      .then((res) => {
        toast.success(res.message);
        getCompetencyInfo(studentId);
        setConfirmationModalIsVisible(false);
        setIsLoading(false)
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false)
      });
  };
  const getConfirmation = (type) => {
    if (type === 'Interests') {
      deleteInterests(interestsIdValue);
    } else if (type === 'Competency') {
      deleteCompetency(competencyIdValue);
    }
  };
  const handleRemoveInterests = (StudentInterestID) => {
    setConfirmationModalIsVisible(true);
    setConfirmationText('Are you sure, you want to remove the selected Interests?');
    setBtnTextName('Remove');
    setInterestsIdValue(StudentInterestID);
    setTitle("Interests")
  };
  const handleRemoveCompetency = (StudentCompetencyId) => {
    setConfirmationModalIsVisible(true);
    setConfirmationText('Are you sure, you want to remove the selected Competency?');
    setBtnTextName('Remove');
    setTitle("Competency");
    setCompetencyIdValue(StudentCompetencyId);
  };
  const hideConfirmationModalHandler = () => {
    setConfirmationModalIsVisible(false);
  };
  const getCompetencyInfo = (id) => {
    let refId;
    if (connectedStudentId) {
      refId = connectedStudentId;
    }
    else {
      refId = studentId;
    }
    setIsLoading(true)
    ProfileService.getCompetencyInfo(refId).then((res) => {
      setCompetencies(res);
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getInterestsInfo(studentId);
  }, [studentId]);
  useEffect(() => {
    getCompetencyInfo(studentId);
  }, []);
  function hideModalhandler() {
    setModalIsVisible(false);
  }

  function showModalhandler(header, data) {
    if (header === 'Interests') {
      setParentListData(data.map((data) => (
        data.Interest
      )))
    }
    if (header === 'Skills') {
      setParentListData(data.map((data) => (
        data.Skill
      )))
    }
    if (header === 'Competency') {
      setParentListData(data.map((data) => (
        data.Competency
      )))
    }
    setselectedHeading(header)
    setModalIsVisible(true);
  }
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };

  const [openDropdownForCompany, setOpenDropdownForCompany] = useState(null);

  const toggleDropdownForCompany = (id) => {
    setOpenDropdownForCompany(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };
  const handleRemoveSkill = (skillId) => {
    setConfirmationModalIsVisible1(true);
    setConfirmationText('Are you sure, you want to remove the selected skill?');
    setBtnTextName('Remove');
    setConnectionIdValue(skillId);
  };

  const getConfirmationDelete = () => {
    deleteSkill(connectionIdValue);
    // statusUpdate();
  };
  const hideConfirmationModalHandlerView = () => {
    setConfirmationModalIsVisible1(false);
  };


  //   const saveSkills = () => {
  //     ProfileService.addStudentSkill({ skills })
  //         .then(res => {
  //             toast.success(res.message);
  //             // Call getSkills() to refresh the skills in About.jsx
  //             getSkills();
  //         })
  //         .catch(error => {
  //             toast.error(error?.message);
  //         });
  // };
  const getSkills = () => {
    let refId;
    if (connectedStudentId) {
      refId = connectedStudentId;
    }
    else {
      refId = studentId;
    }
    setIsLoading(true);
    ProfileService.getStudentSkills(refId)
      .then((res) => {
        if (res.length === 0) {
          // Set dummy skills if the response is empty
          setSkills([
            // { Skill: "Design", StudentSkillID: 1 },
            // { Skill: "Graphics", StudentSkillID: 2 },
            // { Skill: "Printing", StudentSkillID: 3 }
          ]);
        } else {
          setSkills(res);
          setIsLoading(false);
        }

      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };

  const handleAddSkill = (skill) => {
    const request = {
      Skill: skill,
      StudentId: studentId,
    };
    setIsLoading(true);
    ProfileService.addStudentSkill(request)
      .then((res) => {
        toast.success(res.message);
        getSkills();
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };


  const deleteSkill = (skillId) => {
    setIsLoading(true);
    ProfileService.deleteStudentSkill(skillId)
      .then((res) => {
        toast.success(res.message);
        getSkills();
        setConfirmationModalIsVisible1(false);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  // const statusUpdate = () => {
  //   // Simulate an API call with a timeout
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     toast.success('Skill removed successfully');
  //     setConfirmationModalIsVisible(false);
  //   }, 1000);
  // };

  // const getWorkExperienceList = () => {
  //   setIsLoading(true);
  //   ProfileService.getWorkExperienceList(studentId)
  //     .then((res) => {
  //       setWorkData(res);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       toast.error(error?.message);
  //       setIsLoading(false);
  //     });
  // };

  const addWorkExperience = () => {
    setIsLoading(true);
    const payload = {
      CompanyName: updatedWorkData.company,
      CountryID: updatedWorkData.location, // Update with actual CountryID if available
      EndDate: updatedWorkData.end,
      JobTitle: updatedWorkData.jobTitle,
      StartDate: updatedWorkData.start,
      StudentWorkExpID: 0,
      StudentID: studentId,
    };
    ProfileService.addWorkExperience(payload)
      .then((res) => {
        toast.success('Work details have been added successfully!');
        getWorkExperienceList();
        setIsLoading(false);
        setEditView('');
      })
      .catch((error) => {
        toast.error('Failed to add work experience. Please try again.');
        setIsLoading(false);
      });
  };
  const areAllFieldsBlank = () => {
    const fieldsToCheck = ["company", "jobTitle", "location", "start"];
    return fieldsToCheck.every(field => !updatedWorkData[field]);
  };
  const updateWorkExperience = () => {
    setIsLoading(true);
    // Ensure StudentWorkExpID is not null
    if (!updatedWorkData.id) {
      toast.error('StudentWorkExpID is required.');
      setIsLoading(false);
      return;
    }

    const payload = {
      CompanyName: updatedWorkData.company,
      CountryID: updatedWorkData.location, // Assuming location is the CountryID
      EndDate: updatedWorkData.end ? updatedWorkData.end : null,
      JobTitle: updatedWorkData.jobTitle,
      StartDate: updatedWorkData.start,
      StudentWorkExpID: updatedWorkData.id,
    };

    console.log('Payload:', payload); // Log the payload

    ProfileService.updateWorkExperience(payload)
      .then((res) => {
        toast.success('Work details have been updated successfully!');
        getWorkExperienceList(); // Refresh the work experience list
        setIsLoading(false);
        setEditView('');
      })
      .catch((error) => {
        console.error('Error:', error.response?.data || error.message); // Log the error response
        toast.error('Failed to update work experience. Please try again.');
        setIsLoading(false);
      });
  };



  const removeWorkExperience = (workExpId) => {
    setConfirmationModalIsVisibleForWork(true);
    setConfirmationText('Are you sure, you want to remove the selected work experience?');
    setBtnTextName('Remove');
    setConnectionIdValue(workExpId);
  }


  const deleteWorkExperience = (workExpId) => {
    setIsLoading(true);
    ProfileService.deleteWorkExperience(workExpId)
      .then((res) => {
        toast.success('Work experience deleted successfully.');
        getWorkExperienceList();
        setConfirmationModalIsVisibleForWork(false);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getSchoolDetailList();
    if (!initial.current) {
      initial.current = true;
      getSkills();
      getCountryCode();
      getCountryList();
      getWorkExperienceList();
    }
  }, []);

  const getSchoolDetailList = () => {
    let data;
    if (connectedStudentId) {
      data = {
        ParentType: 6,
        ParentId: connectedStudentId,
      };
    }
    else {
      data = {
        ParentType: 6,
        ParentId: studentId,
      };
    }

    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setEducationData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getWorkExperienceList = useCallback(() => {
    let refId;
    if (connectedStudentId) {
      refId = connectedStudentId;
    }
    else {
      refId = studentId;
    }
    setIsLoading(true);
    ProfileService.getWorkExperienceList(refId)
      .then((res) => {
        const mappedWorkData = res.map((item) => ({
          id: item.StudentWorkExpID,
          company: item.CompanyName,
          jobTitle: item.JobTitle,
          start: item.StartDate,
          end: item.EndDate,
          location: item.CountryName,
          countryId: item.CountryID,
        }));
        setWorkData(mappedWorkData);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  }, [studentId]);

  // useEffect(() => {
  //   getWorkExperienceList();
  // }, [studentId]);
  const formatBirthdayDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const birthday = formatBirthdayDate(rowData.DateOfBirth);

  const age = calculateAge(rowData.DateOfBirth);
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const getCountryList = () => {
    setIsLoading(true)
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false)
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getCityList = (value) => {
    updatedData.city = "";
    setIsLoading(true)
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false)
        setCityList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getMailingCityList = (value) => {
    updatedData.MailingCity = "";
    setIsLoading(true)
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false)
        setMailingCityList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;

    // Sanitize input based on field type
    const sanitizedValue = value.replace(/[^a-zA-Z]/g, ""); // Keep only alphabetic characters for first and last name

    // Update form state
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));

    // Real-time validation for relevant fields
    if (["firstName", "lastName"].includes(name)) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: validateField(name, sanitizedValue),
      }));
    }
  };

  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([{ ProvinceId: 1, ProvinceName: 'California' },
  { ProvinceId: 2, ProvinceName: 'Texas' }]);


  const getProvinceList = (value) => {
    updatedData.province = "";
    updatedData.city = "";
    setIsLoading(true);
    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  const getMailingProvinceList = (value) => {
    updatedData.MailingProvince = "";
    updatedData.MailingCity = "";
    setIsLoading(true);
    ApplicationService.getProvince(value)
      .then((res) => {
        setMailingProvinceList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  const getCountryCode = () => {
    setIsLoading(true);
    ApiService.countryList()
      .then((res) => {
        setCountryCode(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  }

  // const viewSet = (data, value) => {
  //   setEditView(value)
  //   getProvinceList(data.Country);
  //   getCityList(data.Province);
  //   getMailingProvinceList(data.MailingCountry);
  //   getMailingCityList(data.MailingProvince);
  // }

  const viewSet = (data, value, action) => {

    setEditView(value);
    setFormErrors({});
    if (value === 'Overview') {
      getProvinceList(data.Country);
      getCityList(data.Province);
      getMailingProvinceList(data.MailingCountry);
      getMailingCityList(data.MailingProvince);
      setButtonText(action === 'add' ? 'Add' : 'Update');
    } else if (value === 'Work') {
      // debugger;
      setUpdatedWorkData({
        id: data.id,
        company: data.company,
        jobTitle: data.jobTitle,
        start: data.start,
        end: data.end,
        location: data.countryId,
      });
      setButtonText(action === 'add' ? 'Add' : 'Update');
      getCountryList();
    }
    setUpdatedData({
      about: rowData.About,
      city: rowData.City,
      email: rowData.Email,
      country: rowData.Country,
      dateOfBirth: rowData.DateOfBirth,
      firstName: rowData.FirstName,
      gender: rowData.Gender,
      language: rowData.Language,
      lastName: rowData.LastName,
      MailingCity: rowData.MailingCity,
      MailingCountry: rowData.MailingCountry,
      MailingProvince: rowData.MailingProvince,
      maritalStatus: rowData.MaritialStatus,
      mobileNo: rowData.MobileNo,
      mobileNoCountryCode: rowData.MobileNoCountryCode,
      province: rowData.Province,
      secLanguage: rowData.SecLanguage,
      studentId: studentId,
      tagline: rowData.TagLine
    });
  };

  // const handleLanguageChange = (e, type) => {
  //   const { value } = e.target;
  //   if (type === 'primary') {
  //     setSelectedLanguage(value);
  //   } else if (type === 'secondary') {
  //     setSelectedSecLanguage(value);
  //   }
  // };


  const getLanguageList = () => {
    setIsLoading(true)
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false)
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  useEffect(() => {
    getCountryCode()
    getCountryList()
    getLanguageList()
  }, []);
  const [educationData, setEducationData] = useState([]);
  const [workData, setWorkData] = useState([
    {
      id: 1,
      company: 'Company',
      jobTitle: 'Job Title',
      start: 'Start',
      end: 'End',
      location: 'Location',
    },
  ]);


  const handleClose = () => {
    setAddEducationPopup(false);
    // setRows([]);
    // setEducationDetails("")
    setSelectedSchoolData({});
    setUpdateEducationData(false);
    // setCountryInstitution("");
    // setEducationLevel("");
  };

  const handleSchoolEditClick = (school) => {
    setSelectedSchoolData(school);
    setAddEducationPopup(true);
    setUpdateEducationData(true);
  };


  const refreshSchoolList = () => {
    getSchoolDetailList();
  }

  const addEducationModalOpen = () => {
    setAddEducationPopup(true);
  }

  const closeEditModal = () => {
    setEditView('');
    setFormErrors({});
    setFormWorksErrors({});
  }


  return (
    <>
      {confirmationModalIsVisible1 && (
        <ConfirmationModal onClose={hideConfirmationModalHandlerView} confirmationText={confirmationText} btnText={btnTextName} onShow={confirmationModalIsVisible1} getConfirmation={getConfirmationDelete}>
        </ConfirmationModal>
      )}
      {modalIsVisible && (
        <Modal_Box onClose={hideModalhandler} onShow={showModalhandler} title={selectedHeading} isAboutModel={true}>
          <AboutModalBody title={selectedHeading} parentListData={parentListData}
            onClose={hideModalhandler} refreshSkills={getSkills} refreshCompetency={() => { getCompetencyInfo(studentId) }} dataLoad={getInterestsInfo} />
        </Modal_Box>
      )}
      {confirmationModalIsVisible && (
        <ConfirmationModal onClose={hideConfirmationModalHandler} confirmationText={confirmationText} btnText={btnTextName} onShow={confirmationModalIsVisible} getConfirmation={() => getConfirmation(title)}>
        </ConfirmationModal>
      )}
      {confirmationModalIsVisibleForWork && (
        <ConfirmationModal
          onClose={handleCancelDeleteWorkExp}
          confirmationText={confirmationText}
          btnText={btnTextName}
          onShow={confirmationModalIsVisibleForWork}
          getConfirmation={handleConfirmDeleteWorkExp}
        />
      )}


      {/* modal of education add */}
      {addEducationPopup && (
        <AddEducationModal addMoreEducationPopup={addEducationPopup} updateFlag={updateEducationData} handleClose={handleClose} selectedSchoolData={selectedSchoolData} refreshSchoolList={refreshSchoolList} />

      )}

      {isLoading && (
        <Loader></Loader>
      )}
      <div>

        {editView &&
          <SearchModal onShow={editView} size={'lg'}>
            <div className={classes['modal-container']}>
              <div className={` ${classes["heading"]} d-flex justify-content-between`}>
                <div className={`${classes["heading-text-modal"]} m-2`}>  {editView === "Work" ? workHeading : ""} {editView} Details</div>
                <div>
                  <div className={classes["tooltip-container"]}>
                    <img title="Remove" onClick={closeEditModal} src={corssIcon1} style={{ width: '20px', marginRight: '13px', marginTop: '14px' }} alt="" className={classes['cross-icon']} />
                    {/* <span className={classes["tooltip"]}>Remove</span> */}
                  </div>
                </div>
              </div>
              <div className={classes["modal-body"]}>

                {editView === 'Overview' && (
                  <div className="row">
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={updatedData.firstName}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                      />
                      {formErrors.firstName && <div className={classes["errorText"]}>{formErrors.firstName}</div>}
                    </div>

                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={updatedData.lastName}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                      />
                      {formErrors.lastName && <div className={classes["errorText"]}>{formErrors.lastName}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        name="dateOfBirth"
                        value={updatedData.dateOfBirth}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        disabled
                      />
                      {formErrors.dateOfBirth && <div className={classes["errorText"]}>{formErrors.dateOfBirth}</div>}
                    </div>

                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Gender</label>
                      <select
                        type="text"
                        className="form-select"
                        name="gender"
                        value={updatedData.gender}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                      {formErrors.gender && <div className={classes["errorText"]}>{formErrors.gender}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Marital Status</label>
                      <select
                        type="text"
                        className="form-select"
                        name="maritalStatus"
                        value={updatedData.maritalStatus}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                      >
                        <option value="">Select Status</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                        <option value="widowed">Widowed</option>
                        <option value="separated">Separated</option>
                        <option value="common-law">Common-law</option>
                      </select>
                      {formErrors.maritalStatus && <div className={classes["errorText"]}>{formErrors.maritalStatus}</div>}
                    </div>
                    <div className="col-md-4" ></div>

                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Country</label>
                      <select
                        aria-label="Country"
                        name="country"
                        className="form-select"
                        value={updatedData.country}
                        onChange={(e) => { getProvinceList(e.target.value); handleInputChange(e) }}
                        onBlur={handleInputChange}
                        required
                      >
                        <option className={classes["option-default"]} value="">Select a country</option>
                        {countryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}>
                            {country.CountryName}
                          </option>
                        ))}
                      </select>
                      {formErrors.country && <div className={classes["errorText"]}>{formErrors.country}</div>}

                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>State</label>
                      <select
                        name="province"
                        aria-label="province"
                        className="form-select"
                        value={updatedData.province}
                        onChange={(e) => { handleInputChange(e); getCityList(e.target.value); }}
                        onBlur={handleInputChange}
                        required
                      // disabled={!genInfo.Country}
                      >
                        <option className={classes["option-default"]} value="">Select a state</option>
                        {provinceList.map((province) => (
                          <option
                            key={province.ProvinceId}
                            value={province.ProvinceId}
                          >
                            {province.ProvinceName}
                          </option>
                        ))}
                      </select>
                      {formErrors.province && <div className={classes["errorText"]}>{formErrors.province}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>City</label>
                      <select
                        name="city"
                        aria-label="city"
                        className="form-select"
                        value={updatedData.city}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        required
                      >
                        <option className={classes["option-default"]} value="">Select a city</option>
                        {cityList.map((city) => (
                          <option key={city.CityName} value={city.CityName}>
                            {city.CityName}
                          </option>
                        ))}
                      </select>
                      {formErrors.city && <div className={classes["errorText"]}>{formErrors.city}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Residence Country</label>
                      <select
                        aria-label="MailingCountry"
                        name="MailingCountry"
                        className="form-select"
                        value={updatedData.MailingCountry}
                        onChange={(e) => { getMailingProvinceList(e.target.value); handleInputChange(e) }}
                        onBlur={handleInputChange}
                        required
                      >
                        <option className={classes["option-default"]} value="">Select a country</option>
                        {countryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}>
                            {country.CountryName}
                          </option>
                        ))}
                      </select>
                      {formErrors.MailingCountry && <div className={classes["errorText"]}>{formErrors.MailingCountry}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Residence State</label>
                      <select
                        name="MailingProvince"
                        aria-label="MailingProvince"
                        className="form-select"
                        value={updatedData.MailingProvince}
                        onChange={(e) => { getMailingCityList(e.target.value); handleInputChange(e) }}
                        onBlur={handleInputChange}
                        required
                      >
                        <option className={classes["option-default"]} value="">Select a state</option>
                        {mailingProvinceList.map((province) => (
                          <option
                            key={province.ProvinceId}
                            value={province.ProvinceId}
                          >
                            {province.ProvinceName}
                          </option>
                        ))}
                      </select>
                      {formErrors.MailingProvince && <div className={classes["errorText"]}>{formErrors.MailingProvince}</div>}
                    </div>
                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Residence City</label>
                      <select
                        name="MailingCity"
                        aria-label="City/Town"
                        className="form-select"
                        value={updatedData.MailingCity}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                        required
                      >
                        <option className={classes["option-default"]} value="">Select a city</option>
                        {mailingCityList.map((city) => (
                          <option key={city.CityName} value={city.CityName}>
                            {city.CityName}
                          </option>
                        ))}
                      </select>
                      {formErrors.MailingCity && <div className={classes["errorText"]}>{formErrors.MailingCity}</div>}
                    </div>

                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Email</label>
                      <input disabled
                        type="text"
                        className="form-control"
                        name="email"
                        value={updatedData?.email}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                      />
                      {formErrors.email && <div className={classes["errorText"]}>{formErrors.email}</div>}
                    </div>

                    <div className="form-group col-md-4  mt-4">
                      <label className={classes["forLabel"]}>Mobile No</label>
                      <div className="d-flex align-items-center">
                        <div className={classes["country-code-select"]}>
                          <select disabled
                            type="text"
                            className={classes[""]}
                            style={{paddingRight: '0px'}}
                            name="mobileNoCountryCode"
                            value={updatedData?.mobileNoCountryCode}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                          >
                            <option value="" disabled>Select Country Code</option>
                            {
                              countryCode.map((rowData, index) => (
                                <option key={index} value={rowData.CountryCode}>
                                  {`+${rowData.CountryCode}`}
                                </option>
                              ))
                            }

                          </select>
                        </div>
                        <div className={classes['divider-line']}>
                        </div>
                        <div className={classes["mobile-number-input"]}>
                          <input disabled
                            type="text"
                            className="form-control"
                            name="mobileNo"
                            value={updatedData.mobileNo}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                          />
                        </div>
                      </div>
                      {/* {formErrors.mobileNo && <div className={classes["errorText"]}>{formErrors.mobileNo}</div>}  */}
                    </div>

                    <div className="form-group col-md-12  mt-4">
                      <label className={classes["forLabel"]}>Tagline</label>
                      <input
                        type="text"
                        className="form-control"
                        name="tagline"
                        value={updatedData.tagline}
                        onChange={handleInputChange}
                        onBlur={handleInputChange}
                      />
                      {formErrors.tagline && <div className={classes["errorText"]}>{formErrors.tagline}</div>}
                    </div>
                  </div>
                )}
                {editView === 'About' && (
                  <div>
                    <div className="form-group">
                      <textarea
                        className={`${classes['forRemoveBorder']} form-control`}
                        name="about"
                        value={updatedData.about}
                        onChange={handleInputChangeAbout}
                        onBlur={handleInputChangeAbout}
                        rows="5"
                      />
                      {formAboutErrors.about && <div className={classes["errorText"]}>{formAboutErrors.about}</div>}
                    </div>
                  </div>
                )}
                {editView === "Language" && (
                  <div className="row">
                    <div className="form-group col-md-6">
                      <div>
                        <label className={`${classes["forLabel"]} m-2`}><b>Primary Language</b></label>
                        <select
                          className={`${classes["custom-form-select"]} form-select`}
                          value={selectedLanguage}
                          onChange={handleLanguageChange}
                          name='language'
                        >
                          <option value="">Select Language</option>
                          {languageList.map((lang) => (
                            <option key={lang.LanguageName} value={lang.LanguageName} disabled={lang.LanguageName === selectedSecLanguage}>
                              {lang.LanguageName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="ms-3">
                        {languageErrors.language && <span className={classes["errorText"]}>{languageErrors.language}</span>}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div>
                        <label className={`${classes["forLabel"]} m-2`}><b>Secondary Language</b></label>
                        <select
                          className={`${classes["custom-form-select"]}`}
                          value={selectedSecLanguage}
                          onChange={handleLanguageChange}
                          name='secLanguage'

                        >
                          <option value="">Select Secondary Language</option>
                          {languageList.map((lang) => (
                            <option key={lang.LanguageName} value={lang.LanguageName} disabled={lang.LanguageName === selectedLanguage}>
                              {lang.LanguageName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="ms-3">
                        {languageErrors.secLanguage && <span className={classes["errorText"]}>{languageErrors.secLanguage}</span>}
                      </div>
                    </div>
                  </div>
                )}
                {editView === 'Work' && (
                  <div className="row">
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                        value={updatedWorkData.company}
                        onChange={newhandleWorkInputChange}
                        onBlur={newhandleWorkInputChange}
                        placeholder="Company Name"
                      />
                      {formWorksErrors.company && <div className={classes["errorText"]}>{formWorksErrors.company}</div>}
                    </div>
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Job Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="jobTitle"
                        value={updatedWorkData.jobTitle}
                        onChange={newhandleWorkInputChange}
                        onBlur={newhandleWorkInputChange}
                        placeholder="Job Title"
                      />
                      {formWorksErrors.jobTitle && <div className={classes["errorText"]}>{formWorksErrors.jobTitle}</div>}
                    </div>
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Location</label>
                      <select
                        name="location"
                        value={updatedWorkData.location}
                        onChange={newhandleWorkInputChange}
                        onBlur={newhandleWorkInputChange}
                      >
                        <option value="">Select a country</option>
                        {countryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}>
                            {country.CountryName}
                          </option>
                        ))}
                      </select>
                      {formWorksErrors.location && <div className={classes["errorText"]}>{formWorksErrors.location}</div>}
                    </div>
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="start"
                        value={updatedWorkData.start}
                        onChange={newhandleWorkInputChange}
                        onBlur={newhandleWorkInputChange}
                      />
                      {formWorksErrors.start && <div className={classes["errorText"]}>{formWorksErrors.start}</div>}
                    </div>
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>End Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="end"
                        value={updatedWorkData.end}
                        onChange={newhandleWorkInputChange}
                        onBlur={newhandleWorkInputChange}
                        min={
                          updatedWorkData.start
                            ? new Date(new Date(updatedWorkData.start).getTime() + 24 * 60 * 60 * 1000)
                              .toISOString()
                              .split("T")[0]
                            : ""
                        }
                      />
                      {formWorksErrors.end && <div className={classes["errorText"]}>{formWorksErrors.end}</div>}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classes["modal-footer"]}>
              <button className="primary-button m-4" onClick={handleUpdate} disabled={isButtonDisabled()} >
                Save Details
              </button>
            </div>
          </SearchModal>
        }
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className="d-flex justify-content-between">
              <div><p className={classes["heading-text"]}>Overview</p></div>
              {!connectedStudentId && (
                <div onClick={() => viewSet(rowData, 'Overview')}><img className={classes["edit-icon"]} src={editIcon} alt=''></img></div>
              )}
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.FirstName ? rowData.FirstName : 'N/A'}</span>
              </div>
              <div>

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.LastName ? rowData.LastName : 'N/A'}</span>
              </div>
              <div>

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={emailIcon} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.Email ? rowData.Email : 'N/A'}</span>
              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div className={classes["tagline-part"]}>
                <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.TagLine ? rowData.TagLine : 'N/A'}</span>
              </div>
              <div>

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={birthIcon} alt=''></img></span>
                <span className={classes["grey-text"]}>{birthday ? birthday : 'N/A'}</span>
              </div>
              <div>
                {/* <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={calenderIcon} alt=''></img></span>
                <span className={classes["grey-text"]}>{age ? age : '0'}</span>
              </div>
              <div>
                {/* <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={genderIcon} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.Gender ? rowData.Gender : 'N/A'}</span>
              </div>
              <div>

              </div>
            </div>
            <div className={classes["icon-alignment"]}>
              <div>
                <span><img className={classes["over-view-icon"]} src={statusIcon} alt=''></img></span>
                <span className={classes["grey-text"]}>{rowData.MaritialStatus ? rowData.MaritialStatus : 'N/A'}</span>
              </div>
              <div>
              </div>
            </div>
            {!connectedStudentId && (
              <>
                <div className={classes["icon-alignment"]}>
                  <div className="col-md-6">
                    <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
                    <span className={classes["black_text"]}>Origin : </span>
                    <span className={classes["grey-text"]}>{rowData.CountryName ? rowData.CountryName : 'N/A'}</span>
                  </div>
                  <div className="col-md-3 text-center">
                    <span className={classes["grey-text"]}>{rowData.ProvinceName ? rowData.ProvinceName : 'N/A'}</span>
                  </div>
                  <div className="col-md-3 text-center">
                    <span className={classes["grey-text"]}>{rowData.City ? rowData.City : 'N/A'}</span>
                    {/* <span className={classes["coin-count"]}>5</span>
            <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  </div>
                </div>
                <div className={classes["icon-alignment"]}>
                  <div className="col-md-6">
                    <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
                    <span className={classes["black_text"]}>Residence : </span>
                    <span className={classes["grey-text"]}>{countryList.find((country) => country.CountryId === rowData.MailingCountry)?.CountryName}</span>
                  </div>
                  <div className="col-md-3 text-center">
                    <span className={classes["grey-text"]}>{rowData.MailingProvinceName ? rowData.MailingProvinceName : 'N/A'}</span>
                  </div>
                  <div className="col-md-3  text-center">
                    <span className={classes["grey-text"]}>{rowData.MailingCity ? rowData.MailingCity : 'N/A'}</span>

                    {/* <span className={classes["coin-count"]}>5</span>
            <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  </div>
                </div>
                <div className={classes["icon-alignment"]}>
                  <div>
                    <span><img className={classes["over-view-icon"]} src={contactIcon} alt=''></img></span>
                    <span className={classes["grey-text"]}>
                      {(rowData.MobileNoCountryCode && rowData.MobileNo) ? (rowData.MobileNoCountryCode + ' ' + rowData.MobileNo) : 'NA'}
                    </span>
                  </div>
                  <div>
                  </div>
                </div></>
            )}

          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className="d-flex justify-content-between">
              <div><p className={classes["heading-text"]}>About</p></div>
              {!connectedStudentId && (
                <div onClick={() => viewSet(rowData, 'About')}><img className={classes["edit-icon"]} src={editIcon} alt='' title="Edit" /></div>
              )}
            </div>
            <div className={classes["about-section"]}>
              <p className={classes["about-section-text"]}>
                {rowData.About ? rowData.About : 'N/A'}
              </p>
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className="d-flex justify-content-between">
              <div>
                <p className={classes["heading-text"]}>Language</p>
              </div>
              {!connectedStudentId && (
                <div>
                  <img
                    className={classes["edit-icon"]}
                    src={editIcon}
                    alt="Edit"
                    title="Edit"
                    onClick={() => viewSet(rowData, "Language")}
                  />
                </div>
              )}
            </div>
            <div>
              <div className={classes["icon-alignment"]}>
                <div className="d-flex">
                  <span>
                    <img className={classes["languageIcon-image-size"]} src={languageIcon} alt='' />
                  </span>

                  <span className={classes["blank-content-text"]}>
                    {rowData.Language ? rowData.Language : 'N/A'}
                  </span>
                </div>

                <div>
                </div>
              </div>
              <div className={classes["icon-alignment"]}>
                <div className="d-flex">
                  <span>
                    <img className={classes["languageIcon-image-size"]} src={languageIcon} alt='' />
                  </span>
                  <span className={classes["blank-content-text"]}>
                    {rowData.SecLanguage ? rowData.SecLanguage : 'N/A'}
                  </span>
                </div>

                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className={classes["heading-align"]}>
              <div>
                <p className={classes["heading-text"]}>Education</p>
              </div>
              {!connectedStudentId && (
                <div>
                  {/* <span className={classes["coin-count"]}>5</span>
              <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  <img onClick={addEducationModalOpen} className={classes["image-size"]} src={addIcon} alt=''></img>
                </div>
              )}
            </div>
            <div>
              {educationData.map((school, index) => (
                <div key={school.id}>
                  <div className={classes["icon-alignment1"]}>
                    <div className="d-flex">
                      <div><span><img className={classes["educationIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                      <div className={` ${classes["grey-text"]}`}>{school.NameOfInstitution}</div>
                      <div className="ms-4">
                        <span>
                          <img
                            className={classes["edit-icon"]}
                            src={openDropdown === school.SchoolId ? upperArrowICon : dropArrowICon}
                            alt=''
                            onClick={() => toggleDropdown(school.SchoolId)}
                          />
                        </span>
                      </div>
                    </div>
                    {!connectedStudentId && (
                      <div className="">
                        <span> <img className={classes["edit-icon"]} src={editIcon} alt='' onClick={() => handleSchoolEditClick(school)}></img></span>
                        <span><img className={classes["edit-icon"]} src={deleteICon} alt='' onClick={() => showConfirmModalhandler(school.SchoolId)}></img></span>
                      </div>
                    )}
                  </div>
                  {openDropdown === school.SchoolId && (
                    <div className="dropDownContainer">
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={programIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.LevelOfEducationName ? school.LevelOfEducationName : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.Marks.length > 0 ? school.Marks[0].GradeName : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.Marks.length > 0 ? school.Marks[0].ObtainMarks : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>
                            {school.AttendendFrom ? new Date(school.AttendendFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}

                          </div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>
                            {school.AttendendTo ? new Date(school.AttendendTo).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}</div>
                        </div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                          <div className={`${classes["grey-text"]}`}>{school.CountryOfInstitutionName ? school.CountryOfInstitutionName : "NA"}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {index < educationData.length - 1 && <img className={`${classes.imageLineHeight}`} src={lineIcon}></img>} */}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className={classes["heading-align"]}>
              <div>
                <p className={classes["heading-text"]}>Work</p>
              </div>
              {!connectedStudentId && (
                <div>
                  {/* <span className={classes["coin-count"]}>5</span> */}
                  {/* <span><img className={classes["workIcon-image-size"]} src={coinIcon} alt=''></img></span> */}
                  <img className={classes["image-size"]} src={addIcon} alt='' onClick={(e) => { viewSet({}, 'Work', 'add'); setWorkHeading("Add") }}></img>
                </div>
              )}
            </div>
            <div>
              {workData?.length === 0 && (
                <div>
                  <p className={classes["blank-content-text"]}>
                    Add your work experiences
                  </p>
                </div>
              )}
              {workData?.length > 0 && (
                <div>
                  {workData.map((rowData) => (
                    <div key={rowData.id}>
                      <div className={classes["icon-alignment1"]}>
                        {/* <div className="d-flex"> */}
                        <div className="d-flex" >
                          <div><span><img className={classes["workIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                          <div className={`ms-3 + ${classes["grey-text"]}`}>{rowData.company}</div>
                          <div className="ms-4">
                            <span>
                              <img
                                className={classes["edit-icon"]}
                                src={openDropdownForCompany == rowData.id ? upperArrowICon : dropArrowICon}
                                alt=''
                                onClick={() => toggleDropdownForCompany(rowData.id)}
                              />
                            </span>
                          </div>
                        </div>
                        <div>
                          {!connectedStudentId && (
                            <div className="">
                              <span><img className={classes["edit-icon"]} src={editIcon} alt='' onClick={(e) => { viewSet(rowData, 'Work', 'edit'); setWorkHeading("Edit") }}></img></span>
                              <span><img className={classes["edit-icon"]} src={deleteICon} alt='' onClick={() => removeWorkExperience(rowData.id)}></img></span>
                            </div>
                          )}
                        </div>

                        {/* </div> */}
                      </div>
                      {openDropdownForCompany === rowData.id && (
                        <div className="dropDownContainer">
                          <div className={classes["icon-alignment"]}>
                            <div className="d-flex">
                              <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={jobTitleIcon} alt=''></img></span></div>
                              <div className={` ${classes["grey-text"]}`}>{rowData.jobTitle}</div>
                            </div>
                            {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                          </div>
                          <div className={classes["icon-alignment"]}>
                            <div className="d-flex">
                              <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                              <div className={` ${classes["grey-text"]}`}>
                                {rowData.start ? new Date(rowData.start).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                              </div>
                            </div>
                            {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                          </div>
                          <div className={classes["icon-alignment"]}>
                            <div className="d-flex">
                              <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                              <div className={` ${classes["grey-text"]}`}>
                                {rowData.end ? new Date(rowData.end).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                              </div>
                            </div>
                            {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                          </div>
                          <div className={classes["icon-alignment"]}>
                            <div className="d-flex">
                              <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                              <div className={` ${classes["grey-text"]}`}>{rowData.location}</div>
                            </div>
                            {/* <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className={classes["heading-align"]}>
              <div>
                <p className={classes["heading-text"]}>Interests</p>
              </div>
              {!connectedStudentId && (
                <div>
                  {/* <span className={classes["coin-count"]}>5</span>
                      <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Interests', interests)}></img>
                </div>
              )}
            </div>
            <div className={classes["space"]}>
              {interests?.length === 0 && (
                <div>
                  <p className={classes["blank-content-text"]}>
                    Add your interests
                  </p>
                </div>
              )}
              {interests?.length > 0 && (
                <div className={classes["space"]}>
                  <div className={classes["added-content"]}>
                    {interests.map(item => (
                      <div className={classes["div_position"]}>
                        <div className={classes["added-content-text"]}>
                          {item.Interest}
                        </div>
                        <div>
                          {!connectedStudentId && (
                            <div className={classes["tooltip-container"]}>
                              <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={() => handleRemoveInterests(item.StudentInterestID)}></img>
                              {/* <span className={classes["tooltip"]}>Remove</span> */}
                            </div>
                          )}
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className={classes["heading-align"]}>
              <div>
                <p className={classes["heading-text"]}>Skills</p>
              </div>
              {!connectedStudentId && (
                <div>
                  {/* <span className={classes["coin-count"]}>5</span>
                      <span><img className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Skills', skills)}></img>
                </div>
              )}
            </div>
            <div className={classes["space"]}>
              {skills?.length === 0 && (
                <div>
                  <p className={classes["blank-content-text"]}>
                    Add your skills
                  </p>
                </div>
              )}
              {skills?.length > 0 && (
                <div className={classes["added-content"]}>
                  {skills.map(item => (
                    <div className={classes["div_position"]} key={item.StudentSkillID}>
                      <div className={classes["added-content-text"]}>
                        {item.Skill}
                      </div>
                      <div>
                        {!connectedStudentId && (
                          <div className={classes["tooltip-container"]}>
                            <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={() => handleRemoveSkill(item.StudentSkillID)}></img>
                            {/* <span className={classes["tooltip"]}>Remove</span> */}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes["common-section"]}>
          <div className={classes["common-section-css"]}>
            <div className={classes["heading-align"]}>
              <div>
                <p className={classes["heading-text"]}>Competencies</p>
              </div>
              {!connectedStudentId && (
                <div>
                  {/* <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span> */}
                  <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Competency', competencies)}></img>
                </div>
              )}
            </div>
            <div className={classes["space"]}>
              {competencies?.length === 0 && (
                <div>
                  <p className={classes["blank-content-text"]}>
                    Add your competencies
                  </p>
                </div>
              )}
              {competencies?.length > 0 && (
                <div className={classes["space"]}>
                  <div className={classes["added-content"]}>
                    {competencies.map(item => (
                      <div className={classes["div_position"]}>
                        <div className={classes["added-content-text"]}>
                          {item.Competency}
                        </div>
                        <div>
                          {!connectedStudentId && (
                            <div className={classes["tooltip-container"]}>
                              <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={() => handleRemoveCompetency(item.StudentCompetencyId)}></img>
                              {/* <span className={classes["tooltip"]}>Remove</span> */}
                            </div>
                          )}
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmationRepostModal && (
        <ConfirmationModal onShow={showConfirmModalhandler} getConfirmation={deleteSchoolData}
          onClose={hideConfirmModalhandler} btnText='Yes' confirmationText='Are you sure, you want to remove the selected education detail?'></ConfirmationModal>
      )}

    </>
  );
}
//
export default About;
 
 