import { Button, Modal } from "react-bootstrap";
import classes from "./GuestUserModal.module.css";
import corssIcon from "../../../../Assets/Logo/red-cross-icon.svg";
import { useNavigate } from "react-router-dom";
import { useBehaviorSubject } from "../../BehaviorSubject/BehaviorSubject";

function GuestUserModal({onShow, onClose }) {
    const navigate = useNavigate();
    const { isLoginUser, logout} = useBehaviorSubject();

    const goTOResgistration = () => {
        onClose();
        logout();
        navigate('/sign-up');
    }

    return (
        <Modal show={onShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={classes.header}>
                <Modal.Title className={`${classes['title']} ${classes['heading-text-modal']}`} id="contained-modal-title-vcenter">
                    Create an account
                </Modal.Title>
                <div className={classes["tooltip-container"]}>
                    <img title="" className={`${classes["corss-icon"]} ${classes["iconSizeForAboutModel"]}`} src={corssIcon} alt='' onClick={onClose}></img>
                </div>
            </Modal.Header>
            <Modal.Body className={classes.body}>
                <p className={classes.subtitle}>
                    Create an account now and you can gain full access to MSM Pursuit such as:
                </p>

                <div className={classes["features-list"]}>
                    <div className={classes["features-point"]}>
                        <div className={classes["red-dot"]}></div>
                        <div>Network with other users</div>
                    </div>
                    <div className={classes["features-point"]}>
                        <div className={classes["red-dot"]}></div>
                        <div>Apply to Institutes</div>
                    </div>
                    <div className={classes["features-point"]}>
                        <div className={classes["red-dot"]}></div>
                        <div>Create and join groups for like-minded people</div>
                    </div>
                    <div className={classes["features-point"]}>
                        <div className={classes["red-dot"]}></div>
                        <div>Take advantage of our AI Match Engine</div>
                    </div>
                </div>
                
                <div className={classes.buttonContainer}>
                    <button
                        className={`${classes[""]} primary-button`}
                        onClick={goTOResgistration}
                    >
                        Create an account
                    </button>
                    <button
                        className={`${classes[""]} secondary-button`}
                        onClick={onClose}
                    >
                        I’m still taking a look around
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default GuestUserModal;