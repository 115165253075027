
import React, { useEffect } from "react";
import "./App.css";
import Main from "./Components/Pages/Main/Main";
import "./Styles/Style.css"
import { InputProvider } from "./Components/Pages/Home/Dashboard/DashboardSearch/GlobalSearch";
import { ProfileProvider } from "./Components/Context/ProfileContext";
import { generateToken, messaging } from "./Components/PushNotification/firebase";
import { onMessage } from "firebase/messaging";

function App() {

  // useEffect(() => {
  //   onMessage(messaging, (payload) => {
  //     console.log("Notification received in foreground: ", payload);
  //   });
  // }, []);

  return (
    <div>
       <InputProvider>
       <ProfileProvider>
        <Main></Main>
       </ProfileProvider>
      </InputProvider>
    </div>
  );
}

export default App;
