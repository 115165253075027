  import React, { useEffect, useState, useRef } from "react";
  import { useNavigate } from "react-router-dom";
  import classes from "./Messages.module.css";
  import profilePic1 from "../../../../Assets/Images/profile_avatar.png";
  import nodata from "../../../../Assets/Logo/no-data1.svg";
  import crossWhite1 from "../../../../Assets/Logo/red-2-cross-icon.svg";
  import iEojiIcon from "../../../../Assets/Logo/iEojiIcon.svg";
  import deleteICon from "../../../../Assets/Logo/postDelete.svg";
import editIcon from "../../../../Assets/Logo/postEdit.svg";
import logoNewChat from "../../../../Assets/Logo/logoNewChat.svg";
import redplusIcon from "../../../../Assets/Logo/red add icon.svg";

import corssIcon from '../../../../Assets/Logo/crossred.svg';
  import Loader from "../../../GlobleShared/Loader/Loader";
  import { toast } from "react-toastify";
  import brochure from "../../../../Assets/Images/msm-brochure.jpg";
  import Picker from 'emoji-picker-react';
  import ConnectionService from "../../../../Services/ConnectionService";
  import ChatMessagesService from "../../../../Services/ChatMessages";
  import useLocalStorage from "../../../GlobleShared/CustomHooks/useLocalStorage";
  import loader from '../../../../Assets/Logo/fade-loader.svg'
  import downArrow from "../../../../Assets/Logo/down.arrow.svg";
  import moreIcon from "../../../../Assets/Logo/more-icon.svg";
  import searchIcon from "../../../../Assets/Logo/search-icon.svg";
import { useBehaviorSubject } from "../../../GlobleShared/BehaviorSubject/BehaviorSubject";


  function Messages() {
    
    const initialized = useRef(false);
      const { setMessageCount } = useBehaviorSubject();
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginated, setPaginated] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const blobUrl = process.env.REACT_APP_URL_PATH;
    const dir=process.env.REACT_APP_BLOB_DIR_USER
    const [profilesData, setProfilesData] = useState([]);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const [selectConnection, setSelectConnection] = useState('');
    const [newMessage, setNewMessage] = useState("");
    const [userDetail] = useLocalStorage('userDetail');
    const userId = userDetail.user_id;
    const userInfo= JSON.parse(localStorage.getItem('userInfo'))
    const userImage= userInfo?.ProfileImgPath;
     const divRef = useRef(null);
     const initialize = useRef(null);
     const [isLoadingChat, setIsLoadingChat] = useState(false);

     const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);
     const chatContainerRef = useRef(null);
     const refDiv = useRef(null)
     const [editingMessageId, setEditingMessageId] = useState(null); 
     const [editMessageText, setEditMessageText] = useState("");
    const [chatId, setChatId] = useState();
    const [chatMessages, setChatMessages] = useState([]);
    const [activePopup, setActivePopup] = useState(null);
    const [activeChat, setActiveChat] = useState(null);
    
    const [hasMoreData, setHasMoreData] = useState(true);

    const [pageScrolled, setPageScrolled] = useState(1);
    const scrollContainer = useRef(null);
    const [searchInput, setSearchInput]=useState('')
    const [newUserBox, setNewUserBox]=useState(false)
    const [searchConnection, setSearchConnection]=useState('')
    const [filterValue, setFilterValue]=useState(0)
    const [userConnection, setUserConnection]=useState([])
    const currentdate=new Date()
    const [tempData, setTempData]=useState([])
    const [newMessageCount, setNewMessageCount]=useState(0)

     // for messages
     const [hasMoreChatData, setHasMoreChatData] = useState(true);
     const initializeChat = useRef(null);
     const chatScrollContainer = useRef(null);
     const [chatPage, setChatPage] = useState(1);
     const [chatlimit, setLChatimit] = useState(10);
     const previousScrollHeight = useRef(0);



    const getConnectionList = (pageValue, search, getReadValue ) => {
      let data = {
        getRead : getReadValue,
        limit: limit,
        page: pageValue,
        searchTerm:search
      };
      setIsLoading(true);
      ChatMessagesService.getConnections(data)
        .then((res) => {
         if (res?.userConnection) {
          setProfilesData((prevData) => [...prevData, ...res.userConnection]);
          setTempData((prevData) => [...prevData, ...res.userConnection])
          
          if (res?.userConnection?.length < limit) {
            setHasMoreData(false);
          }
        }
        if (!initialize.current && res?.userConnection?.length > 0) {
          initialize.current = true;
          // if(getReadValue===0){
          //   res.userConnection[0]['unreadCount']=0
          // }
          // selectedConnection(res?.userConnection[0]); 
        }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    const getConnectionList1 = (pageValue, search, getReadValue) => {
      let data = {
        getRead:getReadValue,
        limit: 10,
        page: pageValue,
        searchTerm:search
      };
      setIsLoading(true);
      ChatMessagesService.getConnections(data)
        .then((res) => {
          setProfilesData(res?.userConnection);
          // selectedConnection(res?.userConnection[0]);
          setIsLoading(false);
          setHasMoreData(res?.userConnection?.length === 10); 
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };


    const getChatId = async (id) => {
      let data = {
        receiver:id
      };
      setIsLoading(true);
      ChatMessagesService.getChatId(data)
        .then((res) => {
          setChatId(res);
          updateUnreadMsg(res)
          getChatMessages(res, 1);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };


    const getChatMessages = (chatIdValue, chatPageValue) => {
      let data = {
          chatId: chatIdValue,
          limit: chatlimit,
          page: chatPageValue
      };

       setIsLoadingChat(true);
       previousScrollHeight.current = chatScrollContainer.current?.scrollHeight || 0;
       const currentScrollTop = chatScrollContainer.current?.scrollTop || 0;

          ChatMessagesService.getChatMessages(data)
          .then((res) => {
            if (res?.chatMessageResponses) {
              setChatMessages((prevData) => [ ...res?.chatMessageResponses, ...prevData]); 

              if (res?.chatMessageResponses?.length < chatlimit) {
                setHasMoreChatData(false); 
              }

              setTimeout(() => {
                if (chatScrollContainer.current) {
                  const newScrollHeight = chatScrollContainer.current.scrollHeight;
                  chatScrollContainer.current.scrollTop = 
                    newScrollHeight - previousScrollHeight.current + currentScrollTop;
                }
              }, 0);
              
          }
          setIsLoadingChat(false);
          console.log(chatMessages)
        })
        .catch((error) => {
          setIsLoadingChat(false);
        });
    };


    const chatMessageInsert = () => {  
      if(newMessage ===''){
        return;
      }
      let data = {
          chatId: chatId,
          mediaTypeId: 0,
          messageText: newMessage
        }

        if (chatScrollContainer.current) {
          chatScrollContainer.current.scrollTop = 0; 
        }

     

      setIsLoadingChat(true);
      scrollToBottom()
      ChatMessagesService.chatMessageInsert(data)
        .then((res) => {
          console.log(userInfo)
          // toast.success(res?.message);
          setChatPage(1)
          setChatMessages([])
          setHasMoreChatData(true);
          getChatMessages(chatId, 1);
          setNewMessage('');
          setIsLoadingChat(false);
          if(res){
            scrollToBottom()
          }
          getConnectionList1(1, '',0 )
          setPage(1)
          setFilterValue(0)
        })
        .catch((error) => {
          setIsLoadingChat(false);
        });
      };



    const chatMessageDelete = (chatMessageId) => {
      let data = {
        chatMessageId:chatMessageId
      };

      console.log(chatMessages?.length);
      setIsLoading(true);
      ChatMessagesService.chatMessageDelete(data)
        .then((res) => {
          toast.success("Chat message deleted successfully.");
          

          setChatMessages((prevMessages) =>
            prevMessages.filter((msg) =>
              msg.chatMessageId !== chatMessageId
            )
          );
          console.log(chatMessages?.length);
          getConnectionList1(1, '',0 )
          hidePopup();
          setIsLoading(false);

        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    const updateUnreadMsg = (id) => {
  let data = {
    chatId: id
  };

  setIsLoading(true);
  ChatMessagesService.updateUnreadChat(data)
    .then((res) => {
      if (res?.message === "Message read successfully.") {
        const updatedProfilesData = profilesData.map((item) =>
          item?.connectionUserId === id ? { ...item, unreadCount: 0 } : item
        );
        setProfilesData(updatedProfilesData);
        getChatUnreadCount();
      }
      setIsLoading(false);
    })
    .catch((error) => {
      toast.error(error?.message);
      setIsLoading(false);
    });
};



    const handleChatSaveEdit = (chatMessageId) => {
      if(newMessage===''){
        return
      }
      let data = {
        chatMessageId: chatMessageId,
        newMessageText: newMessage,
      };
      console.log(chatMessages?.length);
      setIsLoading(true);
      // scrollToBottom()

      setIsLoading(true);
      ChatMessagesService.chatMessageUpdate(data)
        .then((res) => {
          toast.success("Chat message updated successfully.");


          setChatMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.chatMessageId === chatMessageId
                ? { ...msg, messageText: newMessage }
                : msg
            )
          );

          getConnectionList1(1, '',0 )

          setEditingMessageId(null); 
          setEditMessageText(""); 
          setNewMessage("");
          setMessageEditing(false)
          // getChatMessages(chatId, 1);
          setIsLoading(false);

        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    
    const handleCancelEdit = () => {
      setEditingMessageId(null);
      setEditMessageText(""); 
      setNewMessage("")
      setMessageEditing(false)
    };
    
    

    const toggleEmojiPicker = () => {
      handleCancelEdit();
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    };
    const onEmojiClick = (event, emojiObject) => {
      setNewMessage(newMessage + ' ' + event.emoji)
      setIsEmojiPickerOpen(false);
    };


    const addConnection = ()=>{
      navigate("/member/connections");
    }




    const formatPostTime = (createdAt) => {
      const now = new Date();
      const postDate = new Date(createdAt);
      const diffInSeconds = Math.floor((now - postDate) / 1000);
      if(createdAt){
        if (diffInSeconds < 60) {
          return 'Now';
        } else if (diffInSeconds < 120) {
          return '2 minutes ago';
        } else if (diffInSeconds < 3600) {
          const minutes = Math.floor(diffInSeconds / 60);
          return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 7200) {
          return '1 hour ago';
        } else if (diffInSeconds < 86400) {
          const hours = Math.floor(diffInSeconds / 3600);
          return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 172800) {
          return 'Yesterday';
        } else if (diffInSeconds < 604800) {
          const days = Math.floor(diffInSeconds / 86400);
          return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 2419200) {
          const weeks = Math.floor(diffInSeconds / 604800);
          return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 29030400) {
          const months = Math.floor(diffInSeconds / 2419200);
          return `${months} month${months > 1 ? 's' : ''} ago`;
        } else {
          const years = Math.floor(diffInSeconds / 29030400);
          return `${years} year${years > 1 ? 's' : ''} ago`;
        }
      }else{
        return "";
      }
      

    };

    // const selectedConnection =(profile)=>{
    //   getChatId(profile?.connectionUserId);
    //   setSelectConnection(profile);
    //   setActiveChat(profile?.connectionUserId);
    //   setNewUserBox(false)

    // }

    
    const onScroll = () => {
      const element = scrollContainer.current;
      if (
        element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
        !isloading &&
        hasMoreData
      ) {
        setPage((prevPage) => prevPage + 1); // Trigger pagination
      }
    };
    


  // for messages
  const onChatScroll = () => {
    const element = chatScrollContainer.current;
  
    // Prevent scroll event during initial loading or when chatId is not ready
    if (!chatId || isLoadingChat || isloading) return;
  
    if (element.scrollTop === 0 && hasMoreChatData && !isLoadingChat) {
      setChatPage((prevPage) => prevPage + 1);
    }
  };
  
  const getChatUnreadCount = () => {
    setIsLoading(true)
  ChatMessagesService.getUnreadCount()
    .then((res) => {
      setNewMessageCount(res.totalCount);
      setMessageCount(res.totalCount);
        setIsLoading(false);
    })
    .catch((error) => {
     setIsLoading(false);
    });
};
  

   const selectedConnection = async (profile) => {     
    try {
      setChatMessages([]);
      setHasMoreChatData(true);
      setChatPage(1);
      setIsLoadingChat(true); 
  
      if (chatScrollContainer.current) {
        chatScrollContainer.current.scrollTop = 0; 
      }
  
      setSelectConnection(profile);
      setActiveChat(profile?.connectionUserId);
      setNewUserBox(false);
  
      await getChatId(profile?.connectionUserId);
    } catch (error) {
      console.error("Error selecting connection:", error);
    } finally {
      setIsLoadingChat(false);
    }
  };
  
    
  const selectUnrectCount=(profileData)=>{
    setProfilesData(prevProfilesData =>
      prevProfilesData.map(item => {
        if (item?.connectionUserId === profileData?.connectionUserId) {
          return {
            ...item, 
            unreadCount: 0, 
          };
        }
        return item; 
      })
    );
  }
    

    
    const groupMessagesByDate = (messages) => {
    
      const groupedMessages = messages?.reduce((groups, message) => {
        const date = new Date(message.createdDate).toLocaleDateString(); 
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(message);
        return groups;
      }, {});
    
      return groupedMessages;
    };
    
    
    useEffect(() => {
      if (!initialized.current) {
        getConnectionList(page, '', filterValue);
        initialized.current = true;
      }
    }, []); 


  
    useEffect(() => {
      if(page > 1) {
        getConnectionList(page, '', filterValue); 
      }
    }, [page]);



    const scrollToBottom = () => {
      if (chatScrollContainer.current) {
        chatScrollContainer.current.scrollTop = chatScrollContainer.current.scrollHeight;
      }
    };


    useEffect(() => {
      if (chatPage > 1 && chatId) { // Ensure chatId is available
        getChatMessages(chatId, chatPage);
      }
    }, [chatPage, chatId]);
    

    const handleClickOutside = (event) => {
      if (refDiv) {
        if (refDiv.current && !refDiv.current.contains(event.target)) {
          hidePopup()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    
    const handleTogglePopup = (id) => {
      setActivePopup((prevId) => (prevId === id ? null : id));
    };

    const hidePopup=()=>{
      setActivePopup()
    }
    

    const searchNewChat = ()=>{
      setNewUserBox(true)
      getConnection(1, '')
      getConnectionList1(1, '', 0)
      setPage(1);
      setFilterValue(0)
    }

    const searchUser=(value)=>{
      setPage(1)
      setLimit(10)
      setSearchInput(value)
        getConnectionList1(1, value, filterValue)

        if (scrollContainer.current) {
          scrollContainer.current.scrollTop = 0;
        }
    }
    const searchConnectionUser=(value)=>{
      setSearchConnection(value)
        getConnection(1, value)

    }


    const filterUser = (value) => {
      setSelectConnection('')
      setActiveChat('')
      setFilterValue(value);
      setSearchInput('')
      let getReadValue = value === 1 ? 1 : 0;
      getConnectionList1(1, '', getReadValue);
      setPage(1)
      setNewUserBox(false)
    };

    const getConnection=(pageValue, searchConnection)=>{
      let data = {}
        data={
          limit: limit,
          page: pageValue,
          paginated: paginated,
          searchTerm: searchConnection,
        }

      setIsLoading(true)
      ConnectionService.getConnectionList(data).then(res=>{
        setUserConnection(res?.list)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }

    const selectUser=(data)=>{
      setSelectConnection('')
      setChatMessages([])
      const datavalue={
        connectionUserId: userId===data?.receiverUserId ? data?.senderUserId : data?.receiverUserId,
        displayName: data?.displayName,
        lastChatDate: currentdate,
        lastChatMessage: "",
        profileImagePath: data?.profileImage,
      }
      setSelectConnection(datavalue)
      getChatId(userId===data?.receiverUserId ? data?.senderUserId : data?.receiverUserId);
      setActiveChat(userId===data?.receiverUserId ? data?.senderUserId : data?.receiverUserId);
      setNewUserBox(false)

      const setUserOnTop= profilesData.find(item=>item?.connectionUserId===(userId===data?.receiverUserId ? data?.senderUserId : data?.receiverUserId))
      const dataSlice= profilesData.filter(item=>item?.connectionUserId!==(userId===data?.receiverUserId ? data?.senderUserId : data?.receiverUserId))
      let filtervalue=[]
      if(setUserOnTop?.lastChatMessage){
        filtervalue= [setUserOnTop].concat(dataSlice);
      }else{
        filtervalue= [datavalue].concat(dataSlice);
      }
     
      setProfilesData(filtervalue)

    }


    const [messageEditing, setMessageEditing] = useState(false)

   const onEditMessage = (msg)=>{
      setEditingMessageId(msg?.chatMessageId);
      setNewMessage(msg?.messageText);
      setMessageEditing(true);
      hidePopup();
    }
    const onBlurChanges=(value)=>{
      setNewMessage(value.trim())
    }


    return (
      <div className="row">
        <div className={`col-md-5 col-lg-3`} >
           
        <div className={classes['left-section-div']} ref={scrollContainer} onScroll={onScroll} style={{ overflowY: 'auto', height: '80vh' }}>
              
              

              <div>
                <div className={`${classes['input-div']} form-group`}>
                  <div onClick={()=>{searchUser(searchInput)}} className={classes['search-icon']}>
                    <img src={searchIcon} alt=''></img>
                  </div>
                <input placeholder="Search Users" className="form-control" name='search' value={searchInput} onChange={(e)=>{searchUser(e.target.value)}}>
              </input>
              </div>
              </div>
              {/* {profilesData?.length>0 && ( */}

              <div className="mt-3 mb-3">
                <div className="d-flex justify-content-between px-2">
                  <div className="d-flex">
                  <div onClick={()=>{filterUser(0)}} className={filterValue===0?classes['selected-div']:classes['normal-div']}>All</div>
                  <div  onClick={()=>{filterUser(1)}} className={filterValue===1?classes['selected-div']:classes['normal-div']}>Unread</div>
                  </div>
                  <div onClick={searchNewChat}  className={classes['add-more']}>
                         <img
                              src={logoNewChat}
                              alt=''
                              className={classes['add-icon']}
                            />
              </div>
                </div>
              </div>
              {/* )} */}

                {profilesData?.length > 0 ? (
                  profilesData.map((profile, index) => (
                    <div key={index}>
                      {profile.lastChatMessage && (
                        <>
                        <div className={
                        `${activeChat === profile?.connectionUserId ? classes["active-chat"] : classes["user-msg-card"]} 
                        ${index === 0 && activeChat === profile?.connectionUserId ? classes["first-active-chat"] : ""}`} 
                        onClick={() => {selectedConnection(profile); selectUnrectCount(profile)}}>
                        <div className={classes['header-changes']}>
                          <div>
                            <img
                              src={profile?.profileImagePath ? blobUrl+(dir ? (dir +'/') :'')+ profile?.profileImagePath : profilePic1}
                              alt=''
                              className={classes['messages-image']}
                            />
                          </div>
                          <div className={classes['messages-user-content']}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p
                                  className={classes['user-name']}
                                >
                                  {profile?.displayName}
                                </p>
                              </div>
                              <div>
                                <p className={classes['timer']}>{formatPostTime(profile?.lastChatDate)}</p>
                              </div>
                            </div>
                            <div className={classes['chat-text']}>
                              {profile?.lastChatMessage}
                            </div>
                          
                          </div>
                          {selectConnection?.connectionUserId!==profile?.connectionUserId && (
                            <>
                            { profile?.unreadCount >0 && (<div className={classes['unreadChat-count']}>
                              {profile?.unreadCount}
                            </div>)}
                            </>
                          )}

                        </div>
                      </div>
                      <div className={classes['divided-line']}></div>
                        </>
                      )}
                      
                    </div>
                  ))
                ) : (
                  <div className={classes['no-data']}>
                    <img src={nodata} alt='' />
                    <p className="mt-2">
                      <span className={classes['red-span']}>No Messages </span>
                      <span className={classes['backSpan']}>found at the moment.</span>
                    </p>
                  </div>
                )}
              </div>


        </div>
        {!newUserBox && (
        <div className={`col-md-7 col-lg-6`}>
      <div className={(profilesData?.length > 0 && selectConnection) ? classes["messages-container"] :classes["messages-container-no-data"]} >

      { (profilesData?.length > 0 && selectConnection )? ( <>
       <div className={classes["chat-messages"]} style={{ height: '64vh' }}>


         <div className={classes["messages-section1"]}>
             <div className={classes["messages-section1-content"]}>
               <div className={classes["messages-image-part"]}>
                 <div>
                   <img
                     src={ selectConnection?.profileImagePath ?blobUrl+(dir ? (dir +'/') :'')+ selectConnection?.profileImagePath: profilePic1 }
                     alt=""
                     className={classes["messages-image"]}
                   />
                 </div>
                 <div>
                   <p className={classes["user-name-chat"]}>{selectConnection?.displayName}</p>
                   <p className={classes["user-chat"]}>{selectConnection?.aboutUs}</p>
                 </div>
               </div>
             </div>
           </div>

         <div>

           <div   ref={chatScrollContainer} onScroll={onChatScroll} style={{ overflowY: 'auto', height: '50vh'}}>
         {Object.entries(groupMessagesByDate(chatMessages)).map(([date, messages]) => (

         
          <div key={date}  >
            {date && (
               <div className={classes["message-date"]}>
               {date === new Date().toLocaleDateString() ? "Today" : formatPostTime(date)}
             </div>
            )}


               {messages.map((msg) => (
                 <div
                   key={msg.chatMessageId}
                   className={`${classes["message"]} ${
                     msg.senderUserId === userId ? classes["sent"] : classes["received"]
                   }`}
                 >
                    <div>
                      <img
                        src= {msg?.senderUserId === userId
                          ?( userImage?  blobUrl +(dir ? (dir +'/') :'') + userImage :profilePic1)
                          :(  selectConnection?.profileImagePath ?blobUrl +(dir ? (dir +'/') :'')+ selectConnection?.profileImagePath :profilePic1)}
                        alt=""
                        className={classes["message-image-icon"]}
                      />
                    </div>

                   
                                            
                      <div className={classes["message-content"]}>
                        {/* <div className={classes["message-user"]}>
                          {msg?.senderUserId === userId
                            ? userDetail.first_name
                            : selectConnection?.displayName}
                        </div> */}

    
                          <div className="d-flex gap-2">
                            <div className={classes["message-text"]}>{msg?.messageText}</div>
                            {msg?.senderUserId === userId && (

                              <div className={classes["message-actions"]}>
                              <span>
                                <img
                                  className={classes["edit-icon"]}
                                  src={moreIcon}
                                  onClick={()=>handleTogglePopup(msg?.chatMessageId)}
                                  alt=""
                                ></img>
                              </span>


                              </div>
                              )}
                              </div>

                            {activePopup === msg?.chatMessageId && (
                                <>
                                  <div ref={refDiv} className={classes.popup}>
                                    <div className={classes.popupOption} onClick={() => {
                                        onEditMessage(msg)
                                        }}>
                                      <img   className={classes["edit-icon"]} src={editIcon} alt="" />
                                    </div>
                                    <div className={classes.popupOption}  onClick={() => chatMessageDelete(msg?.chatMessageId)}>
                                      <img   className={classes["edit-icon"]}  src={deleteICon} alt="Report" />
                                    </div>
                                  </div>
                                </>
                              )}



                          <div className={classes["chatUpdateTime"]}>
                              
                                {new Date(msg?.createdDate).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true, 
                                })}
                          </div>
                           
                        </div>
               </div>
            ))}

            
            </div>
          
        ))}
         {isLoadingChat && (
                       <div className={classes['message']}> <img
                       src= {userImage ?blobUrl +(dir ? (dir +'/') :'') + userImage: profilePic1 }
                       alt=""
                       className={classes["message-image-icon"]}
                       /><img className={classes['loader']} src={loader} alt=''></img></div>
                       )}
        </div>
          
        </div>
                    
       </div>

       <div className={classes['hidden']}></div>

       <div className={`${classes["description-section"]}`}>
         <textarea
           placeholder="Write a message...."
           value={newMessage}
           onChange={(e) => setNewMessage(e.target.value)}
           onBlur={(e)=>{onBlurChanges(e.target.value)}}
          //  onKeyDown={(e) => e.key === "Enter" && chatMessageInsert()}
           onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              messageEditing
                ? handleChatSaveEdit(editingMessageId)
                : chatMessageInsert();
            }
          }}
           required
           maxLength={1000}
         />
         <div className="d-flex justify-content-between m-2 mb-0">
           <div>
           <img src={iEojiIcon} alt="Emoji Icon" className={classes["icon"]} onClick={toggleEmojiPicker} title="attach emojis"/>

         {isEmojiPickerOpen && (
           <div className={classes["emoji_picker_wrapper"]}>
             <img
                 src={crossWhite1}
               alt="Close"
               className={classes["close_icon_emojis"]}
               onClick={toggleEmojiPicker}
               />
             <Picker onEmojiClick={onEmojiClick} />
           </div>
         )}
           </div>
           <div>
            { !messageEditing ?  (
             <div  className={classes["send-button"]} onClick={chatMessageInsert} style={{ cursor: "pointer" }}>
               Send
             </div>
            ) :(

            <div className={classes["edit-actions"]}>
                              <div
                                onClick={() => handleChatSaveEdit(editingMessageId)}
                                className={classes["save-button"]}
                              >
                                Save
                              </div>
                              <div
                                onClick={handleCancelEdit}
                                className={classes["cancel-button"]}
                              >
                                Cancel
                              </div>
             </div>
             )}
           </div>
         </div>
       </div>
       </>

     ) : (
       <div className={classes['no-data']}>
         <img src={nodata} alt='' />
         {(!selectConnection && profilesData?.length >0) && (
                <>
                <p className="mt-2">
                          <span className={classes['red-span']}> Please select a chat </span>
                          
                        </p>
                </>
         )}
                  {(profilesData?.length ===0 && !selectConnection) && (
                <>
                <p className="mt-2">
                          <span className={classes['red-span']}> No Messages </span>
                          <span className={classes['backSpan']}>found at the moment.</span>
                        </p>
                </>
         )}

         <div className="text-center mt-2 mb-2">
           <button onClick={searchNewChat} className={classes['view-more']}>Start a new Chat</button>
          </div>
       </div>
     )}
     
     </div>
     
        </div>
        )}

       {newUserBox && (
        <div className={`col-md-7 col-lg-6`}>
          <div className={classes["user-container"]}>
           <div>
              <p className={classes['header-text-new']}>New Message</p>
              <div className={`${classes['input-div']} form-group`}>
                  <div onClick={()=>{searchConnectionUser(searchConnection)}} className={classes['search-icon']}>
                    <img src={searchIcon} alt=''></img>
                  </div>
                <input placeholder="Search Users" className="form-control" name='user' 
                value={searchConnection} onChange={(e)=>{searchConnectionUser(e.target.value)}}>
              </input>
              </div>
            </div>
            {userConnection.length>0 && (
            <div>
            {userConnection.map(item=>(
              <div>
                <div className={classes['header-changes1']}>
                  <img onClick={()=>{selectUser(item)}} className={classes['messages-image']} alt='' src={item?.profileImage? blobUrl +(dir ? (dir +'/') :'') +item?.profileImage : profilePic1}></img>
                  <div>
                    <p onClick={()=>{selectUser(item)}} className={classes['user-name']}>{item?.displayName}</p>
                    <p className={classes['chat-text']}>{item?.aboutUs}</p>
                    </div>
                </div>


              </div>
            ))}
           </div>
            )}
              {userConnection.length===0 && (
                    <div className={classes['no-data']}>
                    <img src={nodata} alt='' />
                    <p className="mt-2">
                      <span className={classes['red-span']}>This user is not available in your connection list.</span>
                      {/* <span className={classes['backSpan']}>found at the moment.</span> */}
                    </p>
                  </div>
            )}

         </div>
        </div>
        )}
   

        <div
          className={`dashboard-section col-md-12 col-lg-3`}
        >
          <div
            className={`${classes.rightContainer} ${classes["sticy-header1"]}`}
          >
            <img
              src={brochure}
              className={classes["marketing-brochure"]}
              alt="brochure"
            />
          </div>
        </div>

        {isloading && <Loader />}

      </div>
    );
  }

  export default Messages;
