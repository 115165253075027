import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import CommonServices from "../../Services/CommonServices";

const firebaseConfig = {
  apiKey: "AIzaSyCZ_q9jqT5ifGVt9fZ5Np3g7ytD1rWWLqM",
  authDomain: "pursuit-notification.firebaseapp.com",
  projectId: "pursuit-notification",
  storageBucket: "pursuit-notification.firebasestorage.app",
  messagingSenderId: "1005565907411",
  appId: "1:1005565907411:web:25daeb02296c3215dbdf26",
  measurementId: "G-BM4ZVBJBR2",
};

const vapidSecretKey = process.env.REACT_APP_VAPID_KEY;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log(permission);

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: vapidSecretKey,
            });

            console.log(token);
            saveTokenToBackend(token);
        }

        //   if (currentToken) {
        //     console.log("FCM Token:", currentToken);
        //     return currentToken;
        //   } else {
        //     console.log("No registration token available.");
        //   }
    } catch (err) {
        console.error("An error occurred while retrieving token:", err);
    }
};

export const saveTokenToBackend = async (token) => {
    try {
        if (!token) {
            console.error("Token is null, cannot save to backend.");
            return;
        }

        let data={
            token: String(token),
          }

        const response = await CommonServices.sendDeviceToken(data);
        console.log(response.data.message);
    } catch (error) {
        console.error("Error saving token:", error);
    }
};

export const listenToNotifications = () => {
    onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
    });
};