import React, { useEffect, useRef, useState } from 'react';
import Posts from '../../../../Common/Posts/Posts';


import styles from './DashboardMiddleSection.module.css';
import ComposePost from '../../Groups/ComposePost/ComposePost';
import Notifications from '../Notifications/Notifications';
import PostServices from '../../../../../Services/PostServices';
import { toast } from 'react-toastify';
import Loader from '../../../../GlobleShared/Loader/Loader';
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';

function DashboardMiddleSection() {

  
  const { showNotification, scrollToTop } = useBehaviorSubject();

  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10);
  const [postList, setPostList]=useState('');
  const [isLoading, setIsLoading]=useState(false);
  const initial=useRef(null)

  const getPost =(pageValue, limitValue)=>{
    let data={
      limit: limitValue,
      page: pageValue,
      paginated: true,
      searchTerm: ""
    }
    setIsLoading(true)
   PostServices.getPostList(data).then(res=>{
    setPostList(res?.postData);
    setIsLoading(false)
   }).catch(error=>{
    toast.error(error?.message)
    setIsLoading(false)
   })
  }

  // useEffect(()=>{
  //   if(!initial.current){
  //     initial.current=true;
  //     getPost(page, limit)
  //   }
  // },[])


  const midddleContainerRef = useRef(null);

  useEffect(() => {
    if(midddleContainerRef.current){
    midddleContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

}, [scrollToTop]);

  return (
    <>
    <div>
        {showNotification && (
          <Notifications/>
        )}
    </div>
      { !showNotification && (<>
        <div className={`${styles.middleContainer}`}>
        <ComposePost />
      </div>
      <div ref={midddleContainerRef}  className={styles.postsContainer}>
        <Posts postData={postList} isDashboard={true} />
      </div>
      </>
      )}

      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
}

export default DashboardMiddleSection;
