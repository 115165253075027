import axiosInstance from "./Interceptor";

const FavoritesProgramServices = {
  favoriteList: async (programTypeId) => {
    try {
      const response = await axiosInstance.get('Program/SearchShortlistingProgram?ProgramTypeID='+programTypeId, programTypeId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addRevove: async (data) => {
    try {
      const response = await axiosInstance.post('Student/FavoriteProgramAddRemove', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getProgramType: async () => {
    try {
      const response = await axiosInstance.get('Program/Type');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },



}
export default FavoritesProgramServices;