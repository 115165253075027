import { useEffect, useRef, useState } from "react";
import ApplicationService from "../../../../Services/ApplicationServices";
import classes from './InstitutionDocument.module.css'
import { Table } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import documentView from "../../../../Assets/Logo/documetnView.svg";
import StudentServices from "../../../../Services/StudentServices";


const InstitutionDocuments = ({applicationId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const[parentType, setparentType]=useState(4)
    const [documentList, setDocumentList]=useState([])
    const blobUrl= process.env.REACT_APP_URL_PATH
    const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
    const initial=useRef()

    const getDocuments=()=>{
        setIsLoading(true)
        StudentServices.studentDocumentList(applicationId, parentType).then(res=>{
            setIsLoading(false)
            setDocumentList(res)
        }).catch(error=>{
            setIsLoading(false)
            
        })

    }
    useEffect(()=>{
        if(!initial.current){
            initial.current=true
            getDocuments()
        }

    },[])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        if (isNaN(date)) {
          return 'Invalid Date'; 
        }
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
      };

  

    return (
        <>

       
            <div className={classes["upload-document-modal-block"]}>
            <div className={classes["upload-document-modal-body"]}>
                <div className={classes["upload-document-table"]}>
                    <Table responsive className={classes.customTable}>
                        <thead>
                            <tr>
                                <th>Attachment</th>
                                <th>Status</th>
                                <th>Remark</th>
                                <th>Date Uploaded</th>
                                <th>Description</th>
                                <th>Expiry Date</th>
                                <th>Upload By</th>
                                <th>Received Stamp</th>
                                <th>Intake</th>
                                <th>View File</th>

                            </tr>
                        </thead>
                        <tbody>
                            { documentList.length > 0 ? (
                                documentList.map((document, index) => (
                                    <tr key={document?.DocumentTypeId || index}>
                                        <td>{document?.DocumentName}</td>
                                        <td>{document?.DocumentStatus}</td>
                                        <td>{document?.ReviewRemark}</td>
                                        <td>{formatDate(document?.AddStamp)}</td>
                                        <td>{document?.DocDescription}</td>
                                        <td>{formatDate(document?.ExpiryDate)}</td>
                                        <td>{document?.UploadBy}</td>
                                        <td>{formatDate(document?.ReceiveStamp)}</td>
                                        <td>{document?.IntakeName}</td>
                                        <td>
                                        <a href={blobUrl+(dir ? (dir +'/') :'') + document?.DocPath} target="_blank"><img className={classes['upload-icon']}  src={documentView} alt="Upload" /></a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" >
                                       <div className={classes.noData}>No Data Available</div> 
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
            {/* }  */}
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default InstitutionDocuments;