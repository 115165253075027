import React, { useRef,useEffect, useState } from "react";
import classes from "./UploadDocument.module.css";
import CommonServices from "../../../Services/CommonServices";
import crossIcon from "../../../Assets/Logo/red-2-cross-icon.svg";
import { toast } from "react-toastify";
import uploadicon from "../../../Assets/Logo/file-upload.svg";

const UploadDocument = ({
  uploadFile,
  btnText,
  filePathUrl,
  acceptedFileType,
  acceptFileSize,
  fileFormatText,
}) => {
  const inputFileRef = useRef();
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const dir=process.env.REACT_APP_BLOB_DIR_STUDENT
  const fileExtention = acceptedFileType
    ? acceptedFileType
    : ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  const fileSize = acceptFileSize ? acceptFileSize : 5;
  const fileFormat = fileFormatText
    ? fileFormatText
    : "JPG, JPEG, PNG, and PDF.";
  const [startTime, setStartTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const removeFile = (e) => {
    e.stopPropagation();
    setFilePath("");
    setFileName("");
    setIsLoading(false); // Ensure loading state is reset
    setProgress(0); // Reset progress
    uploadFile(null);
  };
  const fileUpload = (file) => {
    if (fileExtention.includes(file.type)) {
      if (file.size < fileSize * 1024 * 1024) {
        setFileName(file?.name);
        const formData = new FormData();
        formData.append('dir', dir);
        formData.append("file", file);
        inputFileRef.current.value = null;
        setIsLoading(true);
        setStartTime(Date.now()); // Set the start time

        CommonServices.uploadFile(formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);

            // Calculate the estimated time remaining
            const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
            if (percentCompleted > 0) {
              const estimatedTotalTime = (elapsedTime / percentCompleted) * 100; // in seconds
              const remainingTime = Math.max(
                0,
                estimatedTotalTime - elapsedTime
              ); // /Ensure remaining time is not negative
              setEstimatedTime(Math.min(remainingTime, 3600)); // Cap the remaining time to 1 hour (3600 seconds)
            }
          },
        })
          .then((res) => {
            setFilePath(res?.fileLocation);
            uploadFile(res?.fileLocation);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error?.message);
          });
      } else {
        inputFileRef.current.value = null;
        toast.error("File size must be less than " + fileSize + " MB.");
      }
    } else {
      inputFileRef.current.value = null;
      toast.error("Supported file format is :" + fileFormat);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    fileUpload(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    fileUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onBrowseFilesClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins} minutes ${secs} seconds`;
  };


  useEffect(() => {
      setFilePath(filePathUrl); 
      setFileName(filePathUrl)
  }, [filePathUrl]);
  

  return (
    <>
      <div className={classes["width"]}>
        <div className={classes["upload-container"]}>
          <input
            type="file"
            ref={inputFileRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            className={classes["file-upload-area"]}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {!filePath && (
              <div className={classes["upload-box"]}>
                <img
                  src={uploadicon}
                  alt="Upload Icon"
                  className={classes["upload-icon"]}
                />
                <p>Drag your file(s) to start uploading</p>
                <p>OR</p>
                <button
                  className={classes["browse-btn"]}
                  onClick={onBrowseFilesClick}
                >
                  Browse files
                </button>
              </div>
            )}

            {filePath && (
              <div className={classes["file-name"]}>
                <a
                  href={blobUrl +(dir ? (dir +'/') :'')+ filePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fileName}
                </a>
                <img
                title="Remove"
                  className={classes["cross-icon"]}
                  onClick={removeFile}
                  src={crossIcon}
                  alt="Remove"
                />
              </div>
            )}
          </div>

          <div className={classes["file-info"]}>
            <p>Only support .jpg, .png, .jpeg, and pdf files.</p>
            {/* <p>Max file size: 5 MB</p> */}
          </div>

          {isLoading && (
            <div className={classes["progress-container"]}>
              <div className={classes["progress-text"]}>Uploading...</div>
              <div className={classes["progress-details"]}>
                {progress}%{" "}
                {/* {estimatedTime && `(${formatTime(estimatedTime)} remaining)`} */}
              </div>
              <div className={classes["progress-bar-bg"]}>
                <div className={classes["progress-bar"]}>
                  <div
                    style={{
                      width: `${progress}%`,
                      backgroundColor: "#E4151B", // This is your desired color
                      height: "100%",
                      transition: "width 0.5s",
                    }}
                    className={classes["progress"]}
                  />
                </div>
                <img
                title="Remove"
                  className={classes["cross-icon"]}
                  onClick={removeFile}
                  src={crossIcon}
                  alt="Remove"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadDocument;
