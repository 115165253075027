import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ChatWindow.module.css";
import croseWhite from "../../../Assets/Logo/red-2-cross-icon.svg";
import profilePic1 from "../../../Assets/Images/profile_avatar.png";
import minimizeClose from "../../../Assets/Logo/minimize-close.svg";
import minimizeOpen from "../../../Assets/Logo/minimize-open.svg";
import smileIcon from "../../../Assets/Logo/smile-icon.svg";
import attachmentIcon from "../../../Assets/Logo/attachment-icon.svg";
import gifIcon from "../../../Assets/Logo/gif-icon.svg";
import profileIcon from "../../../Assets/Logo/profile-icon-white.svg";
import SendICon from "../../../Assets/Logo/send-arrow.svg";
import crossWhite1 from "../../../Assets/Logo/red-2-cross-icon.svg";
import Loader from "../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";
import ChatMessagesService from "../../../Services/ChatMessages";
import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";
import Picker from 'emoji-picker-react';
import loader from '../../../Assets/Logo/fade-loader.svg'
import deleteICon from "../../../Assets/Logo/postDelete.svg";
import editIcon from "../../../Assets/Logo/postEdit.svg";
import moreIcon from "../../../Assets/Logo/more-icon.svg";

const ChatWindow = ({ profileData, name , profile, onClose, onMinimize, isMinimized }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);

  const initialized = useRef(false);
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const dir=process.env.REACT_APP_BLOB_DIR_USER
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [selectConnection, setSelectConnection] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [userDetail] = useLocalStorage('userDetail');
  const userId = userDetail.user_id;
  const userInfo= JSON.parse(localStorage.getItem('userInfo'))
  const userImage= userInfo?.ProfileImgPath;
   const divRef = useRef(null);
   const [isLoadingChat, setIsLoadingChat] = useState(false);
   const [activePopup, setActivePopup] = useState(null);

   const chatContainerRef = useRef(null);
   const chatWindowRef = useRef(null);
   const [editingMessageId, setEditingMessageId] = useState(null); 
   const [editMessageText, setEditMessageText] = useState("");
  const [chatId, setChatId] = useState();
  const [isSending, setIsSending] = useState(false);
   const [messageEditing, setMessageEditing] = useState(false) 
   const refDiv = useRef(null)

  

  const getChatId = (id) => {
    let data = {
      receiver:id
    };
    setIsLoading(true);
    ChatMessagesService.getChatId(data)
      .then((res) => {
        setChatId(res);
        getChatMessages(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  const getChatMessages = (chatIdValue) => {
    let data = {
        chatId: chatIdValue,
        limit: 1000,
        page: 1
    };
    scrollToBottom()
    setIsSending(true);
    ChatMessagesService.getChatMessages(data)
      .then((res) => {
        setChatMessages(res?.chatMessageResponses);
        setIsSending(false);
        scrollToBottom()
      })
      .catch((error) => {
        setIsSending(false);
      });
  };


  const chatMessageInsert = () => {
    if(newMessage){
      if(messageEditing){
        handleChatSaveEdit(editingMessageId)
  
      }else{
        let data = {
          chatId: chatId,
          mediaTypeId: 0,
          messageText: newMessage
        }
      setIsSending(true);
      scrollToBottom()
      ChatMessagesService.chatMessageInsert(data)
        .then((res) => {
          console.log(userInfo)
          // toast.success(res?.message);
          getChatMessages(chatId);
          setNewMessage('');
          setIsSending(false);
          scrollToBottom()
        })
        .catch((error) => {
          setIsSending(false);
        });
      }
    }


    };


  const chatMessageDelete = (chatMessageId) => {
    let data = {
      chatMessageId:chatMessageId
    };
    setIsLoading(true);
    ChatMessagesService.chatMessageDelete(data)
      .then((res) => {
        toast.success("Chat message deleted successfully.");
        getChatMessages(chatId);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const onBlurChanges=(value)=>{
    setNewMessage(value.trim())

  }



  const handleChatSaveEdit = (chatMessageId) => {
    let data = {
      chatMessageId: chatMessageId,
      newMessageText: newMessage,
    };
    setIsLoading(true);
    setMessageEditing(false)
    setNewMessage('')
    setEditMessageText(""); 
    ChatMessagesService.chatMessageUpdate(data)
      .then((res) => {
        toast.success("Chat message updated successfully.");
        setEditingMessageId(null); 
        getChatMessages(chatId);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  
  const handleCancelEdit = () => {
    // setEditingMessageId(null);
    // setEditMessageText(""); 
    // setNewMessage('')
  };
  
  

  const toggleEmojiPicker = () => {
    handleCancelEdit();
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };
  const onEmojiClick = (event, emojiObject) => {
    setNewMessage(newMessage + ' ' + event.emoji)
    setIsEmojiPickerOpen(false);
  };


  const addConnection = ()=>{
    navigate("/member/connections");
  }


  

  const formatPostTime = (createdAt) => {
    if(createdAt){
      const now = new Date();
      const postDate = new Date(createdAt);
      const diffInSeconds = Math.floor((now - postDate) / 1000);
      
      if (diffInSeconds < 60) {
        return 'Now';
      } else if (diffInSeconds < 120) {
        return '2 minutes ago';
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 7200) {
        return '1 hour ago';
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 172800) {
        return 'Yesterday';
      } else if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 2419200) {
        const weeks = Math.floor(diffInSeconds / 604800);
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 29030400) {
        const months = Math.floor(diffInSeconds / 2419200);
        return `${months} month${months > 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(diffInSeconds / 29030400);
        return `${years} year${years > 1 ? 's' : ''} ago`;
      }
    }else{
      return ''
    }

  };

  const selectedConnection =(profile)=>{
    if(profile?.isGroup){
      getChatId(profile?.userId);
    }else{
      getChatId(profile?.receiverUserId===userId? profile?.senderUserId:profile?.receiverUserId);
    }
    setSelectConnection(profile);
  }


  const groupMessagesByDate = (messages) => {
    
    const groupedMessages = messages.reduce((groups, message) => {
      const date = new Date(message.createdDate).toLocaleDateString(); 
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  
    return groupedMessages;
  };
  

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      selectedConnection(profileData);
    }
  }, []);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatMessages, isSending]);
  

  
  const handleClickOutside = (event) => {
    if (chatWindowRef) {
      if (chatWindowRef.current && !chatWindowRef.current.contains(event.target)) {
        onMinimize()
      }
    }
    if (refDiv) {
      if (refDiv.current && !refDiv.current.contains(event.target)) {
        hidePopup()
      }
    }
  }
  document.addEventListener('mousedown', handleClickOutside);


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }

  const onEditMessage = (msg)=>{
    setEditingMessageId(msg?.chatMessageId);
    setNewMessage(msg?.messageText);
    setMessageEditing(true);
    hidePopup();
  }
  const hidePopup=()=>{
    setActivePopup()
  }
  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };


  



  return (
    <div>
      <div ref={chatWindowRef} className={`${classes.chatWindow} ${isMinimized ? classes.minimized : classes.maximized}`}>
        <div className={classes.chatHeader}>
           
                    <div className={classes["chatHeader-part"]}>
                      <div>
                        <img
                          src={ selectConnection?.profileImage ?(blobUrl+(dir ? (dir +'/') :'')+ selectConnection?.profileImage): profilePic1 }
                          alt=""
                          className={classes["chatHeader-image"]}
                        />
                      </div>
                      <div>
                        <p className={classes["chatHeader-name"]}>{selectConnection?.displayName}</p>
                        {/* <p className={classes["chatHeader-name"]}>{selectConnection?.aboutUs}</p> */}
                      </div>
                    </div>
               
          <div>
            <span
              className={classes.minimizedBtn}
              onClick={onMinimize}>
              <img title="Minimize"
                src={!isMinimized ? minimizeClose : minimizeOpen}
                alt=""
              />
            </span>
            <span className={classes['pointer']} onClick={onClose}>
              <img title="Close"
                src={croseWhite}
                alt=""
              />
            </span>
          </div>
        </div>
        {!isMinimized && (
          <>

       <div className={classes.chatBody} ref={chatBodyRef}>
              {isSending && !chatMessages?.length && (
              <div className={``}>
                 <img src= {userImage ?blobUrl +(dir ? (dir +'/') :'') + userImage: profilePic1 }
                            alt=""
                            className={classes["message-image-icon"]}
                 />
                <img className={classes.loader} src={loader} alt="Loading..." />
              </div>
            )}
        {Object.entries(groupMessagesByDate(chatMessages)).map(([date, messages]) => (
  
               <div key={date}>
                {date && (
                    <div className={classes["message-date"]}>
                    {date === new Date().toLocaleDateString() ? "Today" : formatPostTime(date)}
                  </div>
                )}

          
            {messages.map((msg) => (
              <div
                key={msg.chatMessageId}
                className={`${classes.message} ${
                  msg.senderUserId === userId ? classes.sent : classes.received
                }`}
               >
                <img
                  src={
                    msg?.senderUserId === userId
                    ?( userImage?  blobUrl +(dir ? (dir +'/') :'') + userImage :profilePic1)
                    :(  selectConnection?.profileImage ?blobUrl +(dir ? (dir +'/') :'')+ selectConnection?.profileImage :profilePic1)}
                  alt=""
                  className={classes["message-image-icon"]}
                />
                <div className={`${classes["message-content"]}`}>
                  <div className="d-flex gap-2">
                  <div className={classes["message-text"]}>{msg?.messageText}</div>
                                            {msg?.senderUserId === userId && (

                          <div className={classes["message-actions"]}>
                          <span>
                            <img
                              className={classes["edit-icon"]}
                              src={moreIcon}
                              onClick={()=>handleTogglePopup(msg?.chatMessageId)}
                              alt=""
                            ></img>
                          </span>


                          </div>
                          )}
                          {activePopup === msg?.chatMessageId && (
                                <>
                                  <div ref={refDiv} className={classes.popup}>
                                    <div className={classes.popupOption} onClick={() => {
                                        onEditMessage(msg)
                                        }}>
                                      <img   className={classes["edit-icon"]} src={editIcon} alt="" />
                                    </div>
                                    <div className={classes.popupOption}  onClick={() => chatMessageDelete(msg?.chatMessageId)}>
                                      <img   className={classes["edit-icon"]}  src={deleteICon} alt="Report" />
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                  <span className={classes.chatUpdateTime}> {new Date(msg?.createdDate).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true, 
                              })}</span>
                </div>
              </div>
              ))}
            

              </div>
              
              
          ))}
           {isSending && chatMessages?.length >0 && (
              <div className={`${classes.message} ${classes.sent}`}>
                <img
                  src={userImage ? `${blobUrl}${dir ? `${dir}/` : ''}${userImage}` : profilePic1}
                  alt="User"
                  className={classes["message-image-icon"]}
                />
                <img className={classes.loader} src={loader} alt="Sending..." />
              </div>
            )}
                 
          
              </div>
             


            <div className={classes.chatInput}>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onFocus={handleCancelEdit}
                onBlur={(e)=>{onBlurChanges(e.target.value)}}
                placeholder="Type here..."
                onKeyDown={(e) => e.key === "Enter" && chatMessageInsert()}
              />
              <div className={`d-flex justify-content-between `}>
                <div className={classes.icons}>
                  <img
                    src={smileIcon}
                    alt=""
                    onClick={toggleEmojiPicker}
                  />
              </div>
              
                <div
                  className={classes.icons}
                  onClick={chatMessageInsert}>
                  <img
                    src={SendICon}
                    alt=""
                  />
                </div>
              </div>
            
            </div>
            
            
            {isEmojiPickerOpen && (
                <div className={classes["emoji_picker_wrapper"]}>
                  <img
                      src={crossWhite1}
                    alt="Close"
                    className={classes["close_icon_emojis"]}
                    onClick={toggleEmojiPicker}
                    />
                  <Picker onEmojiClick={onEmojiClick} />
                </div>
              )}
          </>
        )}
      </div>
      {isloading && <Loader />}
    </div>
  );
};

export default ChatWindow;
