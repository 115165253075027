import classes from './Programs.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 
import nodata from '../../../../../Assets/Logo/no-data1.svg';
import calendar from '../../../../../Assets/Logo/calender-icon.svg';
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import sideClose from "../../../../../Assets/Logo/sideclose-2.svg";
import { useState,useEffect,useRef } from 'react';
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import downarrow from "../../../../../Assets/Logo/ic-down-arrow 3.svg";
import uparrow from '../../../../../Assets/Logo/ic-top-arrow.svg';
import SearchModal from '../SearchModal/SearchModal';
import dropdownpart3 from '../../../../../Assets/Logo/dropdownpart3.svg';
import ProgramsModalBody from './ProgramsModalBody/ProgramsModalBody';
import ComparePrograms from '../../../../Common/ComparePrograms/ComparePrograms';
import favoritesIcon from '../../../../../Assets/Logo/favorites.svg'
import unFavoritesIcon from '../../../../../Assets/Logo/red-fav-icon.svg'
import unFavoritesRedIcon from '../../../../../Assets/Logo/nav-red-icon.svg'
import FavoritesProgramServices from '../../../../../Services/FavoritesProgramServices';
import { toast } from 'react-toastify';
import Loader from '../../../../GlobleShared/Loader/Loader';
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';
import GuestUserModal from '../../../../GlobleShared/Modal/GuestUserModal/GuestUserModal';

const Programs = ({ programs = [], filters = [], fees = [],courseTypes = [], onTuitionFeeChange, onProgramTypeChange, onDurationChange, selectedProgramTypeIds, selectedDurationIdss, selectedCountryId, resetFlag,fetchProgramInfo,isFlagProgram }) => {
    const [modalIsVisible, setModalIsVisible] = useState();
    const [programData, setProgramData] = useState({});
    const [modalSize, setModalSize] = useState('');
    const [activeKey, setActiveKey] = useState(['0', '1', '2', '3']);
    const [userDetail] = useLocalStorage('userDetail');
    const studentId=userDetail?.refId
    const navigate = useNavigate();
    const [selectedDurationIds, setSelectedDurationIds] = useState([]);
    const [selectedProgramTypeId, setSelectedProgramTypeId] = useState('');
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
    const [applyFilterColor, setApplyFilterColor] = useState('#363636');
    const [filterAction, setFilterAction] = useState(null);
    const [compareProgramFlag, setCompareProgramFlag] = useState(false);
    const [programIdList, setprogramIdList] = useState([]);
    const isDisabled = programIdList.length >= 10;
    const [selectedPrograms, setselectedPrograms] = useState([]);
    const prevSelectedCountryIdRef = useRef();
    const prevSelectedProgramTypeIdsRef = useRef();
    const [compareProgramsCount, setCompareProgramsCount] = useState(0);
    const [programList, setProgramList]=useState([])
    const [isLoading, setIsLoading]=useState(false);
    const { isLoginUser } = useBehaviorSubject();
    const [guestuserFlag, setGuestuserFlag] = useState(false);

    useEffect(()=>{
        if(programs)
        setProgramList(programs)
    }, [programs])

    const handleAccordionSelect = (eventKey) => {
        setActiveKey(prevActiveKey => {
            if (prevActiveKey.includes(eventKey)) {
                return prevActiveKey.filter(key => key !== eventKey);
            } else {
                return [...prevActiveKey, eventKey];
            }
        });
    };
    const handleApplyClick = (data) => {
        if (isLoginUser.isGuest) {
            setGuestuserFlag(true);
            return;
        }
        const programTypeId = selectedProgramTypeIds === "1" ? 1 : 2;
        navigate("/member/application/add/" + programTypeId + "?studentId=" + studentId + '&programId=' + data?.ProgramId);
    };
    function hideModalHandler() {
        setModalIsVisible(false);
    }
    useEffect(() => {
        // Compare previous and current values
        if (
          prevSelectedCountryIdRef.current !== undefined &&
          prevSelectedProgramTypeIdsRef.current !== undefined
        ) {
          if (
            prevSelectedCountryIdRef.current !== selectedCountryId ||
            prevSelectedProgramTypeIdsRef.current !== selectedProgramTypeIds
          ) {
            setCompareProgramFlag(false);
            setprogramIdList([]);
            setselectedPrograms([]);
          }
        }
    
        // Update refs with current values
        prevSelectedCountryIdRef.current = selectedCountryId;
        prevSelectedProgramTypeIdsRef.current = selectedProgramTypeIds;
      }, [selectedCountryId, selectedProgramTypeIds]);
    
    const clearFilters = () => {
        setSelectedTuitionFees([]);
        // setSelectedProgramTypeId('');
        setSelectedDurationIds([]);
        onTuitionFeeChange([]);
        // onProgramTypeChange('');
        onDurationChange([]);
        setFilterAction('clear');
        setApplyFilterColor('#363636'); // Change color to black when cleared
    };
    const applyFilters = () => {
        setFilterAction('apply');
        setApplyFilterColor('#E4151B'); // Change color to red when applied
    };
    useEffect(() => {
        if (filterAction === 'apply') {
            fetchProgramInfo(1, false); // Apply filters and fetch data
        } else if (filterAction === 'clear') {
            fetchProgramInfo(1, true); // Reset to page 1
        }
        setFilterAction(null); // Reset filter action
    }, [selectedTuitionFees, selectedProgramTypeId, selectedDurationIds, filterAction]);



    // useEffect(()=>{
    
    //     if (resetFlag) {
    //         setCompareProgramFlag(false);
    //         setprogramIdList([]);
    //     }

    // },[resetFlag])

    function stripHtmlTags(str) {
        if (!str) return '';
        return str.replace(/<\/?[^>]+(>|$)/g, "");
      }
    
    const truncateText = (text, maxLength) => {
        if (!text) return 'N/A';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      };

    // function showModalHandler(size) {
    //     setModalIsVisible(true);
    //     setProgramData(value);
    //     setModalSize(size);
    // }
    const handleProgramTypeSelect = (programTypeId) => {
        const updatedSelectedProgramTypeId = selectedProgramTypeIds === programTypeId ? '' : programTypeId;
        setSelectedProgramTypeId(updatedSelectedProgramTypeId);
        onProgramTypeChange(updatedSelectedProgramTypeId);
    };

    const handleDurationChange = (durationId) => {
        const updatedSelectedDurationIds = selectedDurationIds.includes(durationId)
            ? selectedDurationIds.filter(id => id !== durationId)
            : [...selectedDurationIds, durationId];
        setSelectedDurationIds(updatedSelectedDurationIds);
        onDurationChange(updatedSelectedDurationIds); // Updated to pass array
    };
    const limitCharacters = (text, limit = 20) => {
        if (!text) return 'N/A';
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };
    const handleTuitionFeeChange = (feeValue) => {
        const updatedSelectedTuitionFees = selectedTuitionFees.includes(feeValue)
            ? selectedTuitionFees.filter(value => value !== feeValue)
            : [...selectedTuitionFees, feeValue];
        setSelectedTuitionFees(updatedSelectedTuitionFees);
        onTuitionFeeChange(updatedSelectedTuitionFees); // Pass the selected fees
    };
    const institutionId = programList.length > 0 ? programList[0].InstitutionId : null;
    const handleProgramClick = (programId) => {
        const programDetailUrl = `/member/programs/detailPage/${programId}`;
        window.open(programDetailUrl, '_blank');
      };

    if (!selectedCountryId) return null;

    const onComparePrograms = (event) => {
        const isChecked = event.target.checked;
        setCompareProgramFlag(isChecked);
    
        if (!isChecked) {
          setprogramIdList([]);
          setselectedPrograms([]); // Clear selections when compare mode is turned off
        }
      };
    

     function getCheckBoxHandler(event, program) {
    const { checked } = event.target;

    setprogramIdList(prevState => {
      if (checked) {
        return [...prevState, program.ProgramId];
      } else {
        return prevState.filter(item => item !== program.ProgramId);
      }
    });

    setselectedPrograms(prevPrograms => {
      if (checked) {
        return [...prevPrograms, program];
      } else {
        return prevPrograms.filter(p => p.ProgramId !== program.ProgramId);
      }
    });
  }

    const handleClearSelection = () => {
        setprogramIdList([]);
    };
    
    const handleComparePrograms = (size) => {
        setModalIsVisible(true);
        setModalSize(size);
        // const selectedPrograms = programList.filter(program => programIdList.includes(program.ProgramId));
        // setselectedPrograms(selectedPrograms);
        setCompareProgramsCount(selectedPrograms.length);
    };


    const addFevData=(data)=>{

        if (isLoginUser.isGuest) {
            setGuestuserFlag(true);
            return;
        }

        let formdata={
            ProgramId:data?.ProgramId,
            SourceType: 2,
            StudentId: studentId
    }
    setIsLoading(true)
    FavoritesProgramServices.addRevove(formdata).then(res=>{
      
        setProgramList(programList.map(item => {
            if (item.ProgramId === data?.ProgramId) {
              return {...item, Is_ShortList: item.Is_ShortList === 0 ? 1 : 0 };
            }
            return item;
          }));
          setIsLoading(false)
          toast.success(res?.response);

    }).catch(error=>{
        toast.error(error)
        setIsLoading(false)
    })

    }

    const hideGuestModalhandler = () => {
        setGuestuserFlag(false);
    }

    return (
        <>
            {/* {modalIsVisible && (
                <SearchModal onShow={showModalHandler} size={modalSize}>
                    <ProgramsModalBody onClose={hideModalHandler} program={programData} />
                </SearchModal>
            )} */}

            {guestuserFlag && (
                <GuestUserModal onClose={hideGuestModalhandler} onShow={guestuserFlag} />
            )}

            {modalIsVisible && (
                <SearchModal onShow={handleComparePrograms} size={modalSize}>
                    <ComparePrograms selectedProgramTypeId={selectedProgramTypeIds} onClose={hideModalHandler} arrayLength={compareProgramsCount} compareProgramsList={selectedPrograms} />
                </SearchModal>
            )}

            <>

            {resetFlag && programList.length > 0 && (
                <div className={classes["compare-program-section"]}>
                    <div className={classes["cp-checkbox"]}>
                        <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className={classes["checkbox-toggle"]}
                                label="Compare Program"
                                checked={compareProgramFlag}
                                onChange={onComparePrograms}
                            />
                        </Form>
                    </div>
                    {compareProgramFlag && (
                        <div className={classes["cp-selected-text"]}>{programIdList.length} of 10 Selected (select at least 2 programs to compare)</div>
                    )}
                    {programIdList.length > 0 && (
                        <div className={classes["cp-buttons"]}>
                            <button className={`secondary-button ${classes["clear-btn"]}`} onClick={handleClearSelection}>
                                Clear
                            </button>
                            <button className={`primary-button ${classes["compare-btn"]}`} disabled={programIdList.length <= 1} onClick={() => handleComparePrograms('xl')}>
                                Compare Program
                            </button>
                        </div>
                    )}
                </div>
            )}
                <div className="row">
                    {/* Main Content */}
                    <div className="col-md-12 col-lg-12">
                    {programList.length > 0 ? (
                        <div className={classes["program-cards"]}>
                            {programList.map((program) => (
                                // <div className={classes["card-section"]} onClick={() => showModalHandler(program, 'md')} key={program?.ProgramId}>
                                <div className={classes["card-section"]} key={program?.ProgramId}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                        <div className={classes['fav-icon-position']}><img className={program?.Is_ShortList===1? classes['red-icon']:''} onClick={()=>{addFevData(program)}} alt='' src={program?.Is_ShortList===1?unFavoritesRedIcon:unFavoritesIcon}></img></div>
                                            {/* <div className={classes["card-rank"]}> <span className={classes["rank-text"]}>Ranked: {program?.rank || 'N/A'}</span></div> */}
                                            <div className={classes["card-header"]}>
                                                <div className={classes[""]}>
                                                    <img
                                                        src={program?.InstLogoPath ? bloburl + program.InstLogoPath : universityLogo}
                                                        alt=""
                                                        className={classes["university-icon"]}
                                                    />
                                                </div>
                                                <div className={classes["university-detail"]}>
                                                    <div title={program?.Instname} className={classes["university-name"]}>{program?.Instname || 'N/A'}</div>
                                                    <div className={classes["university-address"]}>{program?.InstCountryName || 'N/A'}</div>
                                                </div>
                                            </div>
                                            {compareProgramFlag && (
                                                <Form.Check
                                                    reverse
                                                    type="checkbox"
                                                    id={program.ProgramId}
                                                    label="Compare"
                                                    className={classes["compare-checkbox"]}
                                                    checked={programIdList.includes(program.ProgramId)}
                                                    disabled={!programIdList.includes(program.ProgramId) && isDisabled}
                                                    onChange={event => getCheckBoxHandler(event, program)}
                                                />
                                            )}
                                        </div>

                                        <div className={classes["card-header-block-2"]}>
                                        <div className={classes["detail_UI"]}>{program?.EduLevelName || 'N/A'}{' '}{program?.DurationTime || 'N/A'}</div>
                                        <div className={classes["bracket"]}>
                                        <div className={`row  ${classes["detail"]}`}>
                                            <div title={program?.ProgramName} onClick={() => handleProgramClick(program?.ProgramId)} className={` col-8 ${classes["program-name"]} col-8-to-7`}>{program?.ProgramName || 'N/A'}</div>
                                        <div className={` col-4 ${classes["calender-data"]}`} ><img src={calendar} alt="calendar" style={{marginRight: '7px',height: '20px', width: '20px'}}/>{program?.DurationTime || 'N/A'}
                                        </div>
                                            </div>
                                            <div className={`row mt-3  ${classes["detail"]}`}>

                                            <div className={`col-8 ${classes["program-detail-text"]} col-8-to-7`}  title={stripHtmlTags(program?.Description) || 'N/A'}>
                                                <p dangerouslySetInnerHTML={{ __html:   limitCharacters(stripHtmlTags(program?.Description), 200) }}></p>

                                                {/* {program?.Description || 'N/A'} */}
                                                
                                            </div>
                                            <div className={`col-4  ${classes["Intake"]}`} >
                                                <div className={classes["intake-header"]}>Intake</div>
                                                {program.Intakes.length === 0 ? (
                                                    <div className={classes["intake-detail"]}>N/A</div>
                                                ) : (
                                                    [...new Set(program.Intakes.map(intake => intake.IntakeName))].map((intakeName, index) => (
                                                        <div key={index} className={classes["intake-detail"]}>{intakeName || 'N/A'}</div>
                                                    ))
                                                )}
                                            </div>
                                            </div>
                                            
                                        </div>
                                          
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            {/* <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                {program.Intakes.length === 0 ? (
                                                    <div className={classes["detail"]}>N/A</div>
                                                ) : (
                                                    [...new Set(program.Intakes.map(intake => intake.IntakeName))].map((intakeName, index) => (
                                                        <div key={index} className={classes["detail"]}>{intakeName || 'N/A'}</div>
                                                    ))
                                                )}
                                            </div> */}
                                            <div className={` row ${classes["fee-detail"]}`}>
                                            {/* <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail_UI"]}>{program?.ApplicationFee || 'N/A'}</div> 
                                            </div> */}
                                             <div  className={` col-4 ${classes["fee-detail1"]}`}>
                                            <div className={classes["header"]}>Application Fee</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.ApplicationFee !== undefined ? program?.ApplicationFee : 'N/A'}
                                                </div>
                                            </div>
                                            <div className={` col-4 ${classes["fee-detail"]}`}>

                                            <div className={classes["header"]}>Tuition Fees</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.FeeDetail[0]?.FeeAmount || 'N/A'}
                                                </div>
                                                            <div className={classes["new-fee"]}>
                                                                <span className={classes['other-fee']} data-tooltip="Other Fee">Other Fee</span>{'  '}
                                                                <img src={sideClose} alt='sideclose'></img>
                                                            </div>
                                            </div>
                                            <div className={` col-4 ${classes["fee-detail"]}`}>

                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.InstCostofLiving_Year || 'N/A'}
                                                </div>
                                            </div>
                                            {/* <div className={classes["vertical-control"]}> */}
                                            {/* </div> */}
                                            {/* <div className={classes["vertical-control"]}>
                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}>{program?.Costofliving || 'N/A'}</div>
                                            </div> */}
                                            </div>
                                            <div className={`d-flex mt-2 bd-highlight  ${classes["subInst"]}`}>
                                            <div className={` pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={`text-56 ${classes["InstSubTitle"]}`}> {program?.ScholarShipAmtType !== null ? 'Scholarship' :'No Scholarship'}</p>
                                            {program?.ScholarShipAmtType === "0" && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ScholarShipMinAmt} to {program?.ScholarShipMaxAmt}% Off
                                                </p>
                                            )} 
                                            {program?.ScholarShipAmtType === "1" && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ProgramCurrency} {program?.ScholarShipMinAmt} to {program?.ProgramCurrency} {program?.ScholarShipMaxAmt}
                                                </p>
                                            )}
                                            </div>
                                        </div>
                                    {program?.WaiverPer && (
                                        <div className={`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        <div className={` pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={classes["InstSubTitle"]}>
                                            Application Fee Waivers
                                            </p>
                                            <span className={`${classes["strike-center"]} ${classes["text-gray"]}  ${classes["font-weight-bold"]} mx-1`} style={{ textDecoration: 'line-through' }}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFee}`}
                                            </span>
                                            <span className={`${classes["text-green"]}  ${classes["font-weight-bold"]} mx-1`}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFeeAfterWaiver}`}
                                            </span>
                                            <span className={` font-weight-bold mx-1 ${classes["waiverPercentage"]}`}>
                                            {`${program?.WaiverPer}% Off`}
                                            </span>
                                        </div>
                                        </div>
                                    )}
                                            <div className={classes["parent-button"]}>
                                                <div> 
                                                <button className={` mr-1 ${classes["know-btn"]}`}  onClick={() => handleProgramClick(program?.ProgramId)}>Know More</button>
                                                </div>
                                                <div>
                                                <button className={` ml-1 ${classes["create-btn"]}`} onClick={() => handleApplyClick(program)}>Create Application</button>
                                                </div>
                                            </div>
                                            </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            {/* <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>{program?.ApplicationFee || 'N/A'}</div>
                                            </div> */}
                                            {/* <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>{program?.PeopleLikeYou || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>{program?.LikelihoodOfGraduation ? `${program.LikelihoodOfGraduation}%` : 'N/A'}</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) :  resetFlag && (
                        <div className={classes["NOdata"]}>
                            <div>
                            <img className={classes["noimg"]}src={nodata} alt='nodata' />
                            </div>
                            <div className={classes['line']}>
                            </div>
                            <div className={classes['nospace']}>
                            <span className={classes["no"]}>No data found</span> 
                            <span className={classes['moment']}> at the moment.</span>
                            </div>
                            </div>
                    )}
                    </div>
                </div>
            </>

            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default Programs;