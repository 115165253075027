import React, { useEffect, useRef, useState } from "react";
import classes from "./MemberCard.module.css";
import blockIcon from "../../../../../Assets/Logo/block-icon.svg";
import kingIcon from "../../../../../Assets/Logo/king.svg";
// import secureIcon from "../../.../../../../../../Assets/Logo/secure.svg";
import turnModeIcon from "../../../../../Assets/Logo/turn_into_mode-icon.svg";
import disconnectIcon from "../../../../../Assets/Logo/disconnect-icon.svg";
import reportIcon from "../../../../../Assets/Logo/report-icon.svg";
import messageIcon from "../../../../../Assets/Logo/message-icon.svg";
import moreIcon from "../../../../../Assets/Logo/more-icon.svg";
import userPic from "../../../../../Assets/Images/profile_avatar.png";
import Loader from "../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChatWindow from "../../../../GlobleShared/Chat/ChatWindow";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import GroupService from "../../../../../Services/GroupService";
import PostServices from "../../../../../Services/PostServices";
import crownIcon from "../../../../../Assets/Logo/crown.svg";
import moderatorIcon from "../../../../../Assets/Logo/moderator-icon.svg";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import ModelForMore from "../../../../Common/Posts/ModelForMore";

const ProfileCardInner = ({
  hidePopupBox,
  connectionId,
  imgSrc,
  name,
  description,
  isPopupVisible,
  onTogglePopup,
  groupMembersFlag,
  onOpenChat,
  connectionStatus,
  UserGroupRole,
  fetchMembers,
  userId,
  groupData,
  UserGroupStatusId,
  memberId,
  reportName, 
  studentRefId
}) => {


  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [connectionIdvalue, setconnectionIdvalue] = useState();
  const [useId, setuserId] = useState();
  const [statusId, setStatusId] = useState();
  const [btnTextName, setbtnTextName] = useState();
  const [confirmationText, setConfirmationText] = useState('')
  const [isLoading, setLsLoading] = useState(false);
  const refDiv = useRef(null)
  const navigate = useNavigate()
  const [userDetail] = useLocalStorage('userDetail');
  const studentId=userDetail?.user_id
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const dir=process.env.REACT_APP_BLOB_DIR_USER
  const [profilePhoto, setProfilePhoto] = useState();
  const initialized = useRef(false);
  const {groupType, setGroupType, pauseFlag}=useBehaviorSubject();
  const [isBlocked, setIsBlocked] = useState(UserGroupStatusId === 3); 
  const [modelOpenForMore, setModelOpenForMore] = useState('');
  const [optType, setOptType]=useState(1)
  const [sourceId, setSourceId]=useState('')
  const [actionType, setActionType]=useState('')

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  const getConfirme = () => {
    if(statusId!=='' && statusId>=0){
      statusUpdateMember()
    }
    else if(statusId===''){
      getConfirm()
    }
  }

  const showModalhandlerMember = (value, status, name,userID) => {
    setModalIsVisible(true);
    setconnectionIdvalue(value);
    setStatusId(status)
    setuserId(userID)
    setbtnTextName(name)
    if (status === 2) {
      setConfirmationText('Are you sure, you want to report the content of the selected Member?')
    }

    if (status === 3) {
      setConfirmationText('Are you sure, you want to block the content of the selected Member?');
    } else if (status === 0) {
      setConfirmationText('Are you sure, you want to unblock the content of the selected Member?');
    }

    if (status === 8) {
      setConfirmationText('Are you sure, you want to turn the selected Member into Moderator?')
    }

    if (status === 7) {
      setConfirmationText('Are you sure, you want to remove the selected Member?')
    }

  }


// report implementation
  const handleShowModal = (actionName) => {
    setModelOpenForMore(actionName); 
  };

  const actionConfirm=(id)=>{
    reportPost(id)
  }
  const handleCloseModal = () => {
    setModelOpenForMore(''); 
  };

  const reportPost=(id)=>{
    setModelOpenForMore(''); 
    let data={
      optType:4,
      sourceId:memberId,
      reportReasonId:id
    }
    setLsLoading(true)
    PostServices.reportPost(data).then(res=>{
      setLsLoading(false)
      toast.success(res?.message)
      setModelOpenForMore(''); 
      fetchMembers()
      // setPostUpdated(true)

    }).catch(error=>{
      setLsLoading(false)
      toast.error(error?.message)
    })
  }


  const openModal=(data, actType, type)=>{
    setActionType(actType)
    setSourceId(data)
    setModalIsVisible(true)
    setOptType(type)
    setbtnTextName('yes')
    setStatusId('')
    if(actType===1){
      setConfirmationText('Are you sure, you want to ignore this post?')
    
    }
    if(actType===2){
      setConfirmationText('Are you sure, you want to delete this post?')
    }
    

  }
  const getConfirm=()=>{
    let data={
      sourceId:sourceId,
      optType:optType,
      action:actionType
    
    }
    setLsLoading(true)
    GroupService.reportAction(data).then(res=>{
      toast.success(res?.message)
      setLsLoading(false)
      setModalIsVisible(false)
      fetchMembers()
    }).catch(error=>{
      toast.error(error?.message)
      setLsLoading(false)
      setModalIsVisible(false)
    })


  }
  // *************************************



  const hideModalhandler = (value) => {
    setModalIsVisible(false);
    setStatusId('')
  }

  const statusUpdateMember = () => {
    let successMsg;
    let data={
      groupId:userId,
      groupStatusId: statusId,
      userId:connectionId
    }
    setLsLoading(true)
    GroupService.updateGroups(data).then(res => {
      setModalIsVisible(false);
      setLsLoading(false);
      setStatusId('')
      if(res?.message.includes("[User]")){
        successMsg = res?.message.replace("[User]", name);
      }
      toast.success(successMsg);
      fetchMembers()

       if (statusId === 3) {
        setIsBlocked(true); 
      } else if (statusId === 0) {
        setIsBlocked(false);
      }

    }).catch(error => {
      setLsLoading(false)
      toast.error(error?.message)
    })

  }

//   const viewProfileSection = () => {
//     // window.open('/member/profileView', '_blank');
//     const userId = userDetail.user_id;
//     let profileUrl = ``;
//     if (userId === senderUserId) {
//       // profileUrl = `/member/profileView/${receiverUserId}`;
//       profileUrl = `/member/profileView?userId=${receiverUserId}&studentId=${refId}`;
//     }
//     else {
//       // profileUrl = `/member/profileView/${senderUserId}`;
//       profileUrl = `/member/profileView?userId=${senderUserId}&studentId=${refId}`;
//     }
//     window.open(profileUrl, '_blank');
//   }

const viewProfileSection = () => {
  let profileUrl = ``;
  if (studentId !== connectionId) {
    profileUrl = `/member/profileView?userId=${connectionId}&studentId=${studentRefId}`;
    window.open(profileUrl, '_blank');
  }else{
    navigate('/member/profile')
  }
}

  const handleClickOutside = (event) => {
    if (refDiv) {
      if (refDiv.current && !refDiv.current.contains(event.target)) {
        hidePopupBox()
      }
    }
  }
  document.addEventListener('mousedown', handleClickOutside);
  return (
    <>
    <div >

    {(UserGroupStatusId ===2 && (groupData?.UserGroupRole==='1' || groupData?.UserGroupRole==='2')) && (
  <div>
    <div className={classes['red-header']}>
      <div className={classes['red-content']}>
        <span className={classes['text-font']}>{reportName}</span>
        <div>
        <button onClick={() => openModal(memberId, 2, 4)} className={classes['delete-btn']}>Delete</button>
        <button onClick={() => openModal(memberId, 1, 4)} className={classes['ignor-btn']}>Ignore</button>
     </div>
      </div>
    </div>
  </div>
)}



      <div className={classes.card}>
      <div className={classes.card_content}>
        <div className={(connectionStatus === "Block Contact") ? classes.card_coverImage : classes[""]}>
          <img 
            src={imgSrc ? blobUrl+(dir ? (dir +'/') :'') +imgSrc : userPic}
            alt={`${name}'s profile`}
            className={ classes.profileImage}
          />
        </div>
        <div className={classes.card_info}>
            <div onClick={()=>{viewProfileSection()}}  className={classes["connectionUser-name"]}>{name}
            {UserGroupRole === "1" && <img className = {classes["imgCrown"]} src={crownIcon}/>}
            {UserGroupRole === "2" && <img className = {classes["imgCrown"]} src={moderatorIcon}/>} 
            {  (isBlocked && groupData?.UserGroupRole==='1') && (
            <span className={classes.textBlock}>
                Blocked
            </span>
        )}
            </div>
          {/* {!connectionUserId && (
           <span onClick={viewProfileSection} className={classes.name}>{name}
              {groupMembersFlag && (<span><img src={status} alt="" /></span>)}
            </span>
          )} */}
          <div className={classes.description} title={description}>{description}</div>
        </div>

       
        
      </div>
       { !groupType && !pauseFlag && (
        <div className={classes.buttons}>
          {((groupData?.UserGroupRole==='1' ||  groupData?.UserGroupRole==='2') && connectionId!==studentId)  && (
        <div className={classes['menu-icon']} onClick={onTogglePopup}>
        <img
          className={classes.moreBtn}
          src={moreIcon}
          alt="More options"
        />
         {isPopupVisible && groupMembersFlag && (
          <>
            <div ref={refDiv} className={classes.popup}>
            {(((groupData?.GroupType==='1' || groupData?.GroupType==='2') && (groupData?.UserGroupRole==='1' || groupData?.UserGroupRole==='2')) || ((groupData?.GroupType==='3') && (groupData?.UserGroupRole==='1'))) && (
              <div className={classes.popupOption} onClick={() => { showModalhandlerMember(connectionId, 7, 'Remove',useId) }}>
                <img src={disconnectIcon} alt="Disconnect" />
                <>Remove</>
              </div>
              )}
               
               {UserGroupStatusId !== 3 && (
              <div className={classes.popupOption} onClick={() => { handleShowModal('Report') }}>
              <img src={reportIcon} alt="Report" />Report
              </div>
               )}

              
              <div className={classes.popupOption} onClick={() => { showModalhandlerMember(connectionId, isBlocked ? 0 : 3, isBlocked ? 'Unblock' : 'Block', useId) }}>
              <img src={blockIcon} alt={isBlocked ? 'Unblock' : 'Block'} />
              {isBlocked ? 'Unblock' : 'Block'}
                  </div>
              {groupData?.UserGroupRole==='1' && (
                <div className={classes.popupOption} onClick={() => { showModalhandlerMember(connectionId, 8, 'Moderator',useId)}}>
                  <img src={turnModeIcon} alt="Turn into Mod" />Turn into Mod
                </div>
              )}
            </div>
          </>
        )}
      </div>
          ) }

      {studentId!==connectionId && (
        <div>
        <img onClick={onOpenChat}
          className={classes.messageBtn}
          src={messageIcon}
          alt="Send message"
        />
      </div>
      )}
      </div>

      
      
       )}


      {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible}
          getConfirmation={getConfirme}>
        </ConfirmationModal>
      )}
      {isLoading && (
        <Loader></Loader>
      )}


    </div>
    <div>
    {modelOpenForMore && (
          <ModelForMore  cssFlag={true}
          title={modelOpenForMore}
          onClose={handleCloseModal}
          confirm={actionConfirm}
          ></ModelForMore>
        )}
    </div>
    </div>
    </>
  );
};

const SubMembers = ({groupMembersFlag,membersId, groupData}) => {
  const [activePopup, setActivePopup] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)
  const [chatWindows, setChatWindows] = useState([]);
  const [members, setMembers] = useState([]);
  const initial = useRef(null);
  const {updateMember, setUpdateMember, setGroupType, pauseFlag, setMemberCount, setMemberCountTrue, memberCountTrue}=useBehaviorSubject()
  const [profilrData, setProfilrData] = useState();
  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };

  useEffect(()=>{ 
    if(groupMembersFlag){
     setGroupType(false)
    }
    if (!initial.current) {
    initial.current = true;
    fetchMembers()}},
    [membersId])

  const fetchMembers = () => {
    setLsLoading(true);
   GroupService.getUserGroupMember(membersId)
     .then((res) => {
       setMembers(res)
       setLsLoading(false);
        toast.success(res?.message);
        setMemberCount(res?.length)
        setMemberCountTrue(true)
     })
     .catch((error) => {
        setLsLoading(false);
       toast.error('Failed to get Member. Please try again.');
     });
  };
  
useEffect(()=>{
  if(updateMember){
    setUpdateMember(false)
    fetchMembers()
  }
},[updateMember])

  const hidePopup=()=>{
    setActivePopup()
  }

  const handleOpenChat = (profile) => {
    setChatWindows([{ ...profile, isMinimized: false }]);
    setProfilrData({
    profileImage: profile?.ProfileImage,
    displayName:profile?.DisplayName, 
    userId:profile?.UserID,
    isGroup:true
}) 
  }
  // const handleOpenChat = (profile) => {
  //   setChatWindows((prevChats) => {
  //     if (!prevChats.some((chat) => chat.userConnectionId === profile.UserID)) {
  //       return [...prevChats, { ...profile, isMinimized: false }];
  //     }
  //     return prevChats;
  //   });
  // };
  const handleCloseChat = (id) => {
    setChatWindows((prevChats) => prevChats.filter((chat) => chat.UserID !== id));
  };

  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) => prevChats.map((chat) => (chat?.UserID === id ? { ...chat, isMinimized: !chat.isMinimized } : chat)));
  };

  return (
    <div >
      {members.length>0 && (
        <div className={ classes.groupMembersContainer}>
            {members.map((profile, index) => (
              <ProfileCardInner
              key={profile?.UserID}
              connectionId={profile?.UserID}
              userId={profile?.GroupID}
              memberId={profile?.MemberID}
              backgroundImage={profile?.profileBGImage}
              imgSrc={profile?.ProfileImage}
              name={profile?.DisplayName}
              description={profile?.Tagline}
              isPopupVisible={activePopup === profile?.UserID}
              onTogglePopup={() => handleTogglePopup(profile?.UserID)}
              onOpenChat={() => handleOpenChat(profile)}
              index={index}
              UserGroupRole={profile?.UserGroupRole}
              groupMembersFlag= {groupMembersFlag}
              hidePopupBox={hidePopup}
              refId={profile?.UserID}
              fetchMembers={fetchMembers}
              groupData={groupData}
              UserGroupStatusId={profile?.userGroupStatusId}
              reportName={profile?.reportName}
              studentRefId={profile?.studentId}
              />
            ))}
              <div className={classes.chatContainer}>
          <div className={`${classes.forChatScreen} row`}>
            <div className="col-md-3"></div>
            {chatWindows.slice(0, 2).map((chat, index) => (
              <div
                key={chat?.userConnectionId}
                className={`col-md-4`}>
                <ChatWindow
                  profileData={profilrData}
                  id={chat?.UserID}
                  name={chat?.DisplayName}
                  profile={chat?.ProfileImage ||userPic}
                  onClose={() => handleCloseChat(chat?.UserID)}
                  onMinimize={() => handleMinimizeChat(chat?.UserID)}
                  isMinimized={chat.isMinimized}
                />
              </div>
            ))}
          </div>
        </div>
           </div>
      )}
            {/* <div>
                 {(totalCount!==members?.length && members.length>0) && (
                <div className="text-center mt-2 mb-2">
                <button onClick={viewMore} className={classes['view-more']}>View more</button>
                  </div>
              )}
            </div> */}
      {members.length===0 && (
        <div className={classes['no-data']}>
          <p> No Members Found</p>
          </div>
      ) }

        {isLoading && (
        <Loader></Loader>
      )}
     
    </div>
  );
};
export default SubMembers;
