import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import classes from "./SharePostModal.module.css";
import corssIcon from "../../../../Assets/Logo/red-cross-icon.svg";
import nameInfoImage from "../../../../Assets/Images/profile_avatar.png";
import searchIcon from "../../../../Assets/Logo/search-icon3.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import ConnectionService from "../../../../Services/ConnectionService";
import PostService from "../../../../Services/PostServices";
import { toast } from "react-toastify";
import { useBehaviorSubject } from "../../BehaviorSubject/BehaviorSubject";
import GroupService from "../../../../Services/GroupService";
import useLocalStorage from "../../CustomHooks/useLocalStorage";
import { Form } from "react-bootstrap";

function SharePostModal({ onShow, onClose, postIdData }) {
  const [groupData, setGroupData] = useState([]); // New state for group data
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const dir=process.env.REACT_APP_BLOB_DIR_USER
  const studentDir=process.env.REACT_APP_BLOB_DIR_STUDENT
  const [tempData, setTempData] = useState([]); 
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialized = useRef(false);
  const [profilesData, setProfilesData] = useState([]);

  const [postId, setPostId] = useState(postIdData);
  const [postShare, setPostShare] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInput1, setSearchInput1] = useState("");
  const { postUpdated, setPostUpdated } = useBehaviorSubject();
  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail.user_id;

  const handleChange = (e) => {
    setSearchInput(e.target.value);
     getConnectionList(page, e.target.value);
  };

  const handleGroupChange = (e) => {
    setSearchInput1(e.target.value);
    if(e.target.value.trim()){
      const filtered = tempData.filter(item => item?.GroupName.toLowerCase().includes(e.target.value.toLowerCase()));
      setGroupData(filtered);
    }else{
      setGroupData(tempData);
    }
 
    
  };

  const handleSearch = (e) => {
    getConnectionList(page, searchInput);
  };

  // call an api to share post
  const createPostShare = async () => {
    const SharePostData = {
      postId: postId,
      postShare: postShare,
      shareCount: postShare?.length,
    };

    if (postShare.length === 0) {
      toast.error("Please select atleast on user.");
      return;
    }
    setIsLoading(true);

    PostService.sharePost(SharePostData)
      .then((res) => {
        setIsLoading(false);
        setPostUpdated(true);
        onClose();

        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.success(error?.message);
      });
  };

  const getUserGroupList = () => {
    setIsLoading(true);
    GroupService.getUserGroupList()
      .then((res) => {
        setGroupData(res || []); // Use setGroupData instead of setProfilesData
        setTempData(res || [])
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  const getConnectionList = (pageValue, searchTrem) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: searchTrem,
    };
    setIsLoading(true);
    ConnectionService.getConnectionList(data)
      .then((res) => {
        setProfilesData([...res?.list]);
        setTotalCount(res?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // const handleCheckboxChange = (userId, type) => {
  //   setPostShare((prevPostShare) => {
  //     const existingEntry = prevPostShare.find(
  //       (item) => item.entityId === userId && item.entityType === type
  //     );

  //     if (existingEntry) {
  //       return prevPostShare.filter(
  //         (item) => item.entityId !== userId || item.entityType !== type
  //       );
  //     } else {
  //       return [...prevPostShare, { entityId: userId, entityType: type }];
  //     }
  //   });
  // };
  const handleCheckboxChange = (id, type) => {
    setPostShare((prevPostShare) => {
      const existingEntry = prevPostShare.find(
        (item) => item.entityId === id && item.entityType === type
      );

      if (existingEntry) {
        return prevPostShare.filter(
          (item) => item.entityId !== id || item.entityType !== type
        );
      } else {
        return [...prevPostShare, { entityId: id, entityType: type }];
      }
    });
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getConnectionList(page, '');
      getUserGroupList();
    }
  }, [searchInput]);

  return (
    <div>
      <Modal
        show={onShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={classes["modal-custom"]} // Apply the custom class here
      >
        <Modal.Body>
          <div className={classes["cross-icon-position"]}>
            <div className={classes["heading"]}>Share a Post</div>
            <img
              title="Close"
              className={classes["corss-icon"]}
              src={corssIcon}
              alt=""
              onClick={onClose}
            ></img>
          </div>

          {/* main-container */}

          <div className={classes["modal-body-data"]}>
            <div className={classes.main_container}>
            {/*   <div className={classes.card}>
                <div className={`${classes["form-section"]}`}>
                  <div className={classes["invite-section"]}>
                    <div className={classes["heading"]}>Users</div>

                    <div className={`${classes["search_input_content"]} form-group`}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className={`${classes["search-input"]} form-control`}
                        value={searchInput}
                        onChange={handleChange}
                      />
                      <img
                        src={searchIcon}
                        alt="Search"
                        className={`${classes["search-icon"]}`}
                        onClick={handleSearch}
                      />
                    </div>

                    <div className={`${classes["list-content"]}`}>
                      <div className={`${classes["invite-list"]}`}>
                        {profilesData.map((profile, index) => (
                          <div
                            key={profile?.senderUserId + "_" + index}
                            className={classes["name-item"]}
                          >
                            <div className={classes["name-info"]}>
                              <img
                                src={
                                  profile?.profileImage
                                    ? blobUrl + profile?.profileImage
                                    : nameInfoImage
                                }
                                alt={profile?.displayName}
                                className={classes["name-image"]}
                              />
                              <span className={classes["name-text"]}>
                                {profile?.displayName}
                              </span>
                            </div>
                            <div className={classes["checkbox-container"]}>
                              <Form.Check
                                className={classes["checkbox-input"]}
                                type="checkbox"
                                checked={postShare.some(
                                  (item) =>
                                    item.entityId === (profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId) &&
                                    item.entityType === 1
                                )}
                                onChange={() =>
                                  handleCheckboxChange(
                                    profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId,
                                    1
                                  )
                                }
                                // onChange={() => handleCheckboxChange(profile?.userConnectionId, 2)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* for groups */}
              <div className={classes.card}>
                <div className={`${classes["form-section"]}`}>
                  <div className={classes["invite-section"]}>
                    <div className={classes["heading"]}>Groups</div>

                    <div className={`${classes["search_input_content"]} form-group`}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className={`${classes["search-input"]} form-control`}
                        value={searchInput1}
                        onChange={handleGroupChange}
                      />
                      <img
                        src={searchIcon}
                        alt="Search"
                        className={`${classes["search-icon"]}`}
                        onClick={handleSearch}
                      />
                    </div>

                    <div className={`${classes["list-content"]}`}>
                      <div className={`${classes["invite-list"]}`}>
                        {groupData.map((group, index) => (
                          <div
                            key={group?.GroupID}
                            className={classes["name-item"]}
                          >
                            <div className={classes["name-info"]}>
                              <img
                                src={
                                  group?.GroupImage
                                    ? `${blobUrl + (studentDir ? (studentDir +'/') :'')}${group?.GroupImage}`
                                    : nameInfoImage
                                }
                                alt={group?.GroupName}
                                className={classes["name-image"]}
                              />
                              <span className={classes["name-text"]}>
                                {group?.GroupName}
                              </span>
                            </div>
                            <div className={classes["checkbox-container"]}>
                              <Form.Check
                                className={classes["checkbox-input"]}
                                type="checkbox"
                                checked={postShare.some(
                                  (item) =>
                                    item.entityId === group?.GroupID &&
                                    item.entityType === 2
                                )}
                                onChange={() =>
                                  handleCheckboxChange(group?.GroupID, 2)
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes["share-section"]}>
                <button
                  onClick={createPostShare}
                  className={`${classes["share-btn"]}`}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isLoading && <Loader></Loader>}
    </div>
  );
}

export default SharePostModal;
